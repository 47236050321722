import React, { useState } from "react";
import styled from "styled-components";
import Input from "components/input";
import { Multiselect } from "multiselect-react-dropdown";
import {
  useFindAllPrescriptionsLazyQuery,
  useFindAllActivitiesLazyQuery,
  useFindAllUsersLazyQuery,
  useFindClinicPractitionerLazyQuery,
  useCreateInteractionMutation,
} from "generated/graphql";
import { debounce } from "lodash";
import { useDisclosure } from "@chakra-ui/react";
import StatusModal from "components/StatusModal";
import userVar from "vars/user";
import loggedInClinicVar from "vars/loggedInClinic";
import { useReactiveVar } from "@apollo/client";
import loggedInAsVar from "../../../../vars/loggedInAs";

const Div = styled.section`
  button {
    border-radius: 24px;
  }

  .searchBox {
    border: none;
  }
`;

const NewActivity = () => {
  const loggedInUser = useReactiveVar(loggedInAsVar);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [findAllPrescriptions] = useFindAllPrescriptionsLazyQuery();
  const [findAllActivities] = useFindAllActivitiesLazyQuery();
  const [findAllUsers] = useFindAllUsersLazyQuery();
  const [findClinicPractitioner] = useFindClinicPractitionerLazyQuery();

  const [create, { error, loading }] = useCreateInteractionMutation();

  const [err, setErr] = useState(false);

  const [prescriptionsList, setprescriptionsList] = useState([]);
  const [prescriptionsListLoading, setprescriptionsListLoading] =
    useState(false);
  const [activitiesList, setactivitiesList] = useState([]);
  const [activitiesListLoading, setactivitiesListLoading] = useState(false);

  const [practitionersList, setpractitionersList] = useState([]);
  const [practitionersListLoading, setpractitionersListLoading] =
    useState(false);

  const [patientsList, setpatientsList] = useState([]);
  const [patientsListLoading, setpatientsListLoading] = useState(false);

  const [patient, setpatient] = useState(null);
  const [practitioner, setpractitioner] = useState(null);
  const [prescriptionNumber, setprescriptionNumber] = useState(null);
  const [additionalNote, setadditionalNote] = useState("");
  const [activityIds, setactivityIds] = useState([]);
  const [prescriptionIds, setprescriptionIds] = useState([]);

  const [patientIdError, setpatientIdError] = useState(null);
  const [practitionerIdError, setpractitionerIdError] = useState(null);
  const [prescriptionNumberError, setprescriptionNumberError] = useState(null);
  const [additionalNoteError, setadditionalNoteError] = useState(null);
  const [activityIdsError, setactivityIdsError] = useState(null);
  const [prescriptionIdsError, setprescriptionIdsError] = useState(null);

  function handlePrescriptions(selectedList) {
    const prescriptions = [];
    selectedList.map((x) => prescriptions.push(x.id));
    setprescriptionIds(prescriptions);
  }

  function handleRemovePrescription(selectedList) {
    const prescription = [];
    selectedList.map((x) => prescription.push(x.id));
    setprescriptionIds(prescription);
  }

  function handleActivites(selectedList) {
    const activities = [];
    selectedList.map((x) => activities.push(x.id));
    setactivityIds(activities);
  }

  function handleRemoveActivity(selectedList) {
    const activities = [];
    selectedList.map((x) => activities.push(x.id));
    setactivityIds(activities);
  }

  function handlePatients(selectedList) {
    const users = [];
    selectedList.map((x) => users.push(x.id));
    setpatient(users[0]);
  }

  function handleRemovePatient(selectedList) {
    const users = [];
    selectedList.map((x) => users.push(x.id));
    setpatient(users[0]);
  }

  function handlePractitioners(selectedList) {
    const users = [];
    selectedList.map((x) => users.push(x.id));
    setpractitioner(users[0]);
  }

  function handleRemovePractitioner(selectedList) {
    const users = [];
    selectedList.map((x) => users.push(x.id));
    setpractitioner(users[0]);
  }

  const handlePrescriptionSearchDebounce = React.useCallback((query) => {
    searchPrescription(query);
  }, []);

  const handleActivitiesSearchDebounce = React.useCallback((query) => {
    searchActivity(query);
  }, []);

  const handlePatientsSearchDebounce = React.useCallback((query) => {
    searchPatient(query);
  }, []);

  const handlePractitionersSearchDebounce = React.useCallback((query) => {
    searchPractitioner(query);
  }, []);

  const searchPrescription = debounce((query) => {
    setprescriptionsListLoading(true);
    findAllPrescriptions({
      variables: {
        input: {
          search: query,
        },
      },
    })
      .then(({ data }) => {
        const newPres = [];
        if (
          data.findAllPrescriptions.data &&
          data.findAllPrescriptions.data?.length > 0
        ) {
          data.findAllPrescriptions.data.map((x) =>
            newPres.push({
              id: x.id,
              name: `${x.title} (${x.manufacturer})`,
            })
          );
          setprescriptionsList(newPres);
        } else {
          setprescriptionsList([{ name: "No Item Found" }]);
        }
      })
      .catch(() => {
        setprescriptionsList([{ name: "Error Occurred" }]);
      })
      .finally(() => {
        setprescriptionsListLoading(false);
      });
  }, 1000);

  const searchActivity = debounce((query) => {
    setactivitiesListLoading(true);
    findAllActivities({
      variables: {
        input: {
          search: query,
        },
      },
    })
      .then(({ data }) => {
        const newActivities = [];
        if (
          data.findAllActivities.data &&
          data.findAllActivities.data?.length > 0
        ) {
          data.findAllActivities.data.map((x) =>
            newActivities.push({
              id: x.id,
              name: `${x.title} (A${parseFloat(x.reward).toFixed(2)})`,
            })
          );
          setactivitiesList(newActivities);
        } else {
          setactivitiesList([{ name: "No Item Found" }]);
        }
      })
      .catch(() => {
        setactivitiesList([{ name: "Error Occurred" }]);
      })
      .finally(() => {
        setactivitiesListLoading(false);
      });
  }, 1000);

  const searchPatient = debounce((query) => {
    setpatientsListLoading(true);
    findAllUsers({
      variables: {
        input: {
          search: query,
        },
      },
    })
      .then(({ data }) => {
        const newUser = [];
        if (data.findAllUsers.data && data.findAllUsers.data?.length > 0) {
          data.findAllUsers.data.map((x) =>
            newUser.push({
              id: x.id,
              name: `${x.profile.firstName} ${x.profile.lastName} (${x.phoneNumber})`,
            })
          );
          setpatientsList(newUser);
        } else {
          setpatientsList([{ name: "No Item Found" }]);
        }
      })
      .catch(() => {
        setpatientsList([{ name: "Error Occurred" }]);
      })
      .finally(() => {
        setpatientsListLoading(false);
      });
  }, 1000);

  const searchPractitioner = debounce((query) => {
    setpractitionersListLoading(true);
    findClinicPractitioner({
      variables: {
        clinicId: loggedInClinicVar().id,
      },
    })
      .then(({ data }) => {
        const newUser = [];
        if (
          data.findClinicPractitioner &&
          data.findClinicPractitioner?.length > 0
        ) {
          data.findClinicPractitioner.map((x) =>
            newUser.push({
              id: x.id,
              name: `${x.profile.firstName} ${x.profile.lastName} (${x.phoneNumber})`,
              // name: x.id,
            })
          );
          setpractitionersList(newUser);
        } else {
          setpractitionersList([{ name: "No Item Found" }]);
        }
      })
      .catch(() => {
        setpatientsList([{ name: "Error Occurred" }]);
      })
      .finally(() => {
        setpractitionersListLoading(false);
      });
  }, 1000);

  function validate() {
    setpatientIdError(null);
    setpractitionerIdError(null);
    setprescriptionNumberError(null);
    setactivityIdsError(null);
    setprescriptionIdsError(null);
    setadditionalNoteError(null);

    const error_ = [];

    if (!patient) {
      setpatientIdError("Required");
      error_.push("require patient");
    }

    if (!practitioner) {
      setpractitionerIdError("Required");
      error_.push("require PR");
    }

    if (!prescriptionNumber) {
      setprescriptionNumberError("Required");
      error_.push("require pres Num");
    }

    if (activityIds.length < 1) {
      setactivityIdsError("Required");
      error_.push("require act Id");
    }

    if (prescriptionIds.length < 1) {
      setprescriptionIdsError("Required");
      error_.push("require pres Id");
    }
    console.log("valid", error_);

    if (error_.length < 1) {
      return true;
    } else {
      return false;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      create({
        variables: {
          input: {
            patientId: patient,
            activityId: activityIds,
            prescriptionId: prescriptionIds,
            prescriptionNumber,
            additionalNotes: additionalNote,
            practitionerId: practitioner,
            chwId: userVar().id,
            clinicId: loggedInClinicVar().id,
            role: loggedInUser,
          },
        },
      })
        .then(() => {
          onOpen();
        })
        .catch(() => {
          setErr(true);
          onOpen();
        });
    }
  }

  return (
    <Div>
      <h1>Record New Activity</h1>
      <div className="mt-5">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="bg-[#FBFCFE] dark:bg-[#17202F] p-2">
              <Multiselect
                isObject={true}
                options={
                  patientsList.length > 0 ? patientsList : [{ name: "No Item" }]
                }
                // singleSelect={true}
                placeholder="Patient"
                displayValue="name"
                emptyRecordMsg={"No Patient"}
                onSelect={handlePatients}
                onSearch={handlePatientsSearchDebounce}
                onRemove={handleRemovePatient}
                loading={patientsListLoading}
                id="patient"
                style={{
                  searchBox: { border: "none", background: "none" },
                  optionContainer: {
                    background: "rgba(0,150,251,.7)",
                    color: "#fff",
                  },
                }}
                selectionLimit={1}
              />
            </div>
            <small className={"text-red-500"}>{patientIdError}</small>
          </div>
          <div className=" mb-4">
            <div className="bg-[#FBFCFE] dark:bg-[#17202F] p-2">
              <Multiselect
                isObject={true}
                options={
                  practitionersList.length > 0
                    ? practitionersList
                    : [{ name: "No Item" }]
                }
                // singleSelect={true}
                placeholder="Practitioner"
                displayValue="name"
                emptyRecordMsg={"No Practitioner"}
                onSelect={handlePractitioners}
                onSearch={handlePractitionersSearchDebounce}
                onRemove={handleRemovePractitioner}
                loading={practitionersListLoading}
                id="practitioner"
                style={{
                  searchBox: { border: "none", background: "none" },
                  optionContainer: {
                    background: "rgba(0,150,251,.7)",
                    color: "#fff",
                  },
                }}
                selectionLimit={1}
              />
            </div>
            <small className="text-red-500">{practitionerIdError}</small>
          </div>

          <div className="mb-4 ">
            <div className="p-2 bg-[#FBFCFE] dark:bg-[#17202F]">
              <Multiselect
                isObject={true}
                options={
                  activitiesList.length > 0
                    ? activitiesList
                    : [{ name: "No Item" }]
                }
                // options={activityOptions}
                placeholder="Activity"
                // displayValue="activity"
                displayValue="name"
                emptyRecordMsg={"No Activity"}
                onSelect={handleActivites}
                onSearch={handleActivitiesSearchDebounce}
                onRemove={handleRemoveActivity}
                loading={activitiesListLoading}
                id="activity"
                style={{
                  searchBox: { border: "none", background: "none" },
                  optionContainer: {
                    background: "rgba(0,150,251,.7)",
                    color: "#fff",
                  },
                }}
              />
            </div>
            <small className="text-red-500">{activityIdsError}</small>
          </div>
          <div className="mb-4">
            <div className=" p-2 bg-[#FBFCFE] dark:bg-[#17202F]">
              <Multiselect
                isObject={true}
                options={
                  prescriptionsList.length > 0
                    ? prescriptionsList
                    : [{ name: "No Item" }]
                }
                // options={prescriptionOptions}
                placeholder="Prescription"
                // displayValue="prescription"
                displayValue="name"
                onSelect={handlePrescriptions}
                onSearch={handlePrescriptionSearchDebounce}
                onRemove={handleRemovePrescription}
                loading={prescriptionsListLoading}
                id="prescription"
                style={{
                  searchBox: { border: "none", background: "none" },
                  optionContainer: {
                    background: "rgba(0,150,251,.7)",
                    color: "#fff",
                  },
                }}
              />
            </div>
            <small className="text-red-500">{prescriptionIdsError}</small>
          </div>

          <Input
            hasError={Boolean(prescriptionNumberError)}
            message={prescriptionNumberError}
            value={prescriptionNumber}
            onChange={(e) => setprescriptionNumber(e.target.value)}
            // required
            className="mb-4"
            label="Prescription number"
            id="pnumber"
          />

          <Input
            hasError={Boolean(additionalNoteError)}
            message={additionalNoteError}
            value={additionalNote}
            onChange={(e) => setadditionalNote(e.target.value)}
            type="text"
            label="Additional Notes"
            id="anote"
          />

          <div className="mt-20 flex justify-end">
            <button
              disabled={loading}
              className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5"
            >
              {loading ? "Submitting" : "Submit"}
            </button>
          </div>
        </form>
      </div>

      {err ? (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text={error?.message}
          status="Failed"
          samePageNav={true}
        />
      ) : (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          text="New Interaction Recorded. Go to activities to see your full interactions."
          navigateTo="/app/chw/home"
        />
      )}
    </Div>
  );
};

export default NewActivity;
