import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "components/input";
import { ImSpinner8 } from "react-icons/im";
import { useResetPinOtpMutation } from "generated/graphql";
import classNames from "classnames";
import { useDisclosure } from "@chakra-ui/react";
import StatusModal from "components/StatusModal";

import styled from "styled-components";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function EmailReset() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [requestOtp, { loading, error }] = useResetPinOtpMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!location.state) navigate("/auth");
  }, [location.state, navigate]);

  function handleSubmit(e) {
    e.preventDefault();
    const restData = location.state;
    requestOtp({
      variables: {
        input: {
          email,
          phoneNumber: restData?.phoneNumber,
        },
      },
    }).then(() => {
      onOpen();
    });
  }

  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Almost there,</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Enter your email address.
      </p>

      <form onSubmit={handleSubmit}>
        <Input
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          autoFocus
          required
          type="email"
          className="mt-5"
          label="Email address"
          id="email"
        />

        <p className="text-red-500">{error && error.message}</p>  

        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-14 text-lg rounded-full text-white block",
            { "opacity-20": loading }
          )}
        >
          Next
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center bg-opacity-20 items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </form>
      <StatusModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        text="Kindly check your email for otp"
        navigateTo="/auth/reset/otp"
      />
    </Div>
  );
}

export default EmailReset;
