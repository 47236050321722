import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import userVar from "vars/user";
import { useReactiveVar } from "@apollo/client";
import styled from "styled-components";
import loggedInAsVar from "../../vars/loggedInAs";

const Div = styled.div`
  font-family: "Airbnb Cereal App", sans-serif;

  .arrow {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

function Auth() {
  const user = useReactiveVar(userVar);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (user) {
      if (loggedInAsVar() === "PATIENT") {
        navigate(location.state?.uri || "/app/patient/home", { replace: true });
      } else if (loggedInAsVar() === "CHW") {
        navigate(location.state?.uri || "/app/chw/home", { replace: true });
      }
    }
  }, [user, navigate, location.state?.uri]);
  return (
    <Div className="p-4 mx-auto max-w-[500px]">
      <div>
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="arrow flex items-center rounded-full mb-4 text-blue-500 py-2  outline-none ring-0 focus-visible:ring-1 ring-blue-500 "
        >
          <BsArrowLeft className="mr-2" /> Go Back
        </button>
      </div>
      <Outlet />
    </Div>
  );
}

export default Auth;
