import React, { useState } from "react";
import styled from "styled-components";
import ItemsTab from "components/itemstab";
import ChangePhoneModal from "./changePhoneNumber/index";
import ChangePinModal from "./changePin/index";
import GoBack from "components/GoBack";
import {
  MdNotifications,
  MdOutlineContactPage,
  MdOutlinePhoneLocked,
  MdOutlineAccountBalance,
} from "react-icons/md";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  h6 {
    font-size: 0.875rem;
  }

  p {
    font-size: 1rem;
  }

  h1 {
    color: #4285f4;
  }
`;

const Settings = () => {
  const [changePhone, setChangePhone] = useState(false);
  const [changePin, setChangePin] = useState(false);

  const handleChangePhone = () => {
    setChangePhone((prevState) => !prevState);
  };

  const handleChangePin = () => {
    setChangePin((prevState) => !prevState);
  };

  return (
    <Div className="px-4 relative">
      <div className="flex items-center">
        <GoBack />
        <button className="ml-auto">
          <MdNotifications className="text-xl" />
        </button>
      </div>

      <div className="my-6 grid gap-2">
        <ItemsTab
          className="ring-1 ring-gray-300 !py-4"
          leading={<MdOutlineContactPage aria-hidden className="text-2xl" />}
          onClick={handleChangePhone}
        >
          <p className="text-left">Change phone number</p>
        </ItemsTab>

        <ItemsTab
          className="ring-1 ring-gray-300 !py-4"
          leading={<MdOutlinePhoneLocked aria-hidden className="text-2xl" />}
          onClick={handleChangePin}
        >
          <p className="text-left">Change Pin</p>
        </ItemsTab>

        <ItemsTab
          className="ring-1 ring-gray-300 !py-4"
          leading={<MdOutlineAccountBalance aria-hidden className="text-2xl" />}
        >
          <p className="text-left">Link bank account</p>
        </ItemsTab>
      </div>

      <ChangePhoneModal
        changePhone={changePhone}
        setChangePhone={setChangePhone}
      />
      <ChangePinModal changePin={changePin} setChangePin={setChangePin} />
    </Div>
  );
};

export default Settings;
