import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFindAllClinicsByIdQuery } from "generated/graphql";
import Empty from "components/Empty";
import userVar from "vars/user";
import loggedInAsVar from "vars/loggedInAs";
import loggedInClinicVar from "vars/loggedInClinic";

import styled from "styled-components";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function ChooseHospital() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, loading, error } = useFindAllClinicsByIdQuery({
    variables: { input: location.state.user.clinicId },
  });

  function handleChoice(i) {
    localStorage.setItem(
      "cus-rib-loggedInClinic",
      JSON.stringify(location.state.clinics[i])
    );
    localStorage.setItem("cus-rib-user", JSON.stringify(location.state.user));
    localStorage.setItem("cus-rib-loggedInRole", location.state.selectedRole);
    userVar(location.state.user);
    loggedInAsVar(location.state.selectedRole);
    loggedInClinicVar(location.state.clinics[i]);
    navigate(`/app/${location.state.selectedRole.toLowerCase()}/home`, {
      replace: true,
    });
  }

  if (loading) <div>Loading...</div>;
  if (error) <Empty text={error.message} />;
  if (data?.findAllUsersById?.length <= 0) <Empty text={"No clinic Found"} />;

  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Select clinic,</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Kindly choose from the options, which clinic you want to log in to.
      </p>

      <section className="mt-3">
        {data?.useFindAllClinicsById?.map((clinic, i) => (
          <button
            onClick={() => handleChoice(i)}
            key={i}
            className="flex w-full justify-between p-4 mb-3 bg-[#B7D0FB]"
          >
            <p>{clinic.institutionName}</p>
            <p>select</p>
          </button>
        ))}
      </section>
    </Div>
  );
}

export default ChooseHospital;
