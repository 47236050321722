import React from "react";
import { Link } from "react-router-dom";
import { useGetUnCompletedTasksQuery } from "generated/graphql";
import userVar from "vars/user";
import loggedInAsVar from "vars/loggedInAs";
import { Progress, Stack } from "@chakra-ui/react";
import Empty from "components/Empty";
import styled from "styled-components";

const Div = styled.div`
  .todo {
    moz-box-shadow: 0 2px 5px -2px #ccc;
    -webkit-box-shadow: 0 2px 5px -2px #ccc;
    box-shadow: 0 2px 5px -2px #ccc;
    margin-bottom: 0.5rem;
  }
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 400px);
  justify-content: center;
`;

function UnCompletedTasksList() {
  const { data, loading, error } = useGetUnCompletedTasksQuery({
    variables: { input: { userId: userVar()?.id, userRole: loggedInAsVar() } },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <Empty text={error.message} />;
  }

  if (loading) {
    return (
      <div>
        <Loading>
          <Stack>
            <Progress size="xs" isIndeterminate />
          </Stack>
        </Loading>
      </div>
    );
  }

  return (
    <>
      {data?.getUnCompletedTasks?.length < 1 ? (
        <div className="mt-8 todo dark:text-[#FFFBE5]">
          <Empty text="No Uncompleted Tasks" />
        </div>
      ) : (
        <Div className="mt-8 dark:text-[#FFFBE5]">
          {data?.getUnCompletedTasks.map((task) => (
            <Link to={"form/" + task.id} key={task.id}>
              <div className="flex justify-between p-4 todo">
                <p>{task.name}</p>
                <p>A{parseFloat(task.reward).toFixed(2)}</p>
              </div>
            </Link>
          ))}
        </Div>
      )}
    </>
  );
}
export default UnCompletedTasksList;
