import React from "react";
import { IoHomeSharp } from "react-icons/io5";
import { IoMdPerson } from "react-icons/io";
import { MdAccountBalanceWallet, MdStore } from "react-icons/md";
import { FaGift } from "react-icons/fa";
import ButtomLink from "./buttomlink";
import styled from "styled-components";

const Nav = styled.nav`
  z-index: 1000000000;
`;

const links = [
  {
    text: "Home",
    icon: <IoHomeSharp size={24} />,
    to: "/app/patient/home",
  },
  {
    text: "Wallet",
    icon: <MdAccountBalanceWallet size={24} />,
    to: "/app/patient/wallet",
  },
  {
    text: "Market",
    icon: <MdStore size={24} />,
    to: "/app/patient/market",
  },
  {
    text: "Earn",
    icon: <FaGift size={24} />,
    to: "/app/earn",
  },
  {
    text: "Account",
    icon: <IoMdPerson size={24} />,
    to: "/app/account",
  },
];

function BottomNav() {
  return (
    <Nav className="sticky bottom-0 w-full !bg-white dark:!bg-gray-900">
      <ul className="flex items-center justify-around">
        {links.map((link, i) => (
          <li key={i}>
            <ButtomLink {...link} />
          </li>
        ))}
      </ul>
    </Nav>
  );
}

export default BottomNav;
