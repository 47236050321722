import React, { useState } from "react";
import { Outlet } from "react-router";

const Index = () => {
  const [state, setState] = useState({
    patient: true,
    chw: false,
  });

  const handlePatient = () => {
    setState({
      patient: true,
      chw: false,
    });
  };

  const handleChw = () => {
    setState({
      patient: false,
      chw: true,
    });
  };

  return (
    <div>
      <Outlet
        context={{
          state,
          handlePatient,
          handleChw,
        }}
      />
    </div>
  );
};

export default Index;
