import React from "react";
import styled from "styled-components";
import GoBack from "./GoBack";
import afya from "assets/images/afya.png";
import eth from "assets/images/ETH.png";

const Div = styled.div`
  .time {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid;
    line-height: 0.1em;
    margin: 10px 0 20px;

    span {
      /* background:#fff;  */
      padding:5px 10px; 
      border-radius: 12px;
    }
  }
`;

const Notification = () => {
  return (
    <Div className="mt-6">
      <div className="px-4">
        <GoBack />
      </div>

      <section className="mt-6 px-4">
        <p className="time text-sm border-[#000] dark:border-[#fff]">
          <span className="bg-[#C4C4C4] text-[#4285F4]">Today</span>
        </p>

        <div className="flex items-center" style={{ height: "100%" }}>
          <div className="mt-5 mr-5">
            <img src={afya} alt="img" />
          </div>
          <div>
            <p>
              To <span className="font-medium">John Adamu</span> - You sent
              25AFYA
            </p>
            <span className="text-[#B3B6BA] text-sm">20 mins ago</span> -{" "}
            <span className="text-[#FD0404] text-sm">You sent</span>
          </div>
        </div>

        <hr className="my-5" />

        <div className="flex items-center" style={{ height: "100%" }}>
          <div className="mt-5 mr-5">
            <img src={afya} alt="img" />
          </div>
          <div>
            <p>
              To <span className="font-medium">Faith Randon</span> - Sent you
              25AFYA
            </p>
            <span className="text-[#B3B6BA] text-sm">38 mins ago</span> -{" "}
            <span className="text-[#34A853] text-sm">You received</span>
            <p className="flex justify-between text-sm">
              <span>Micheal Olubaje Adams</span>{" "}
              <span className="font-medium">Minor</span>
            </p>
          </div>
        </div>

        <hr className="my-5" />

        <div
          className="flex items-center"
          style={{ height: "100%", alignItems: "center" }}
        >
          <div className="mt-5 mr-5">
            <img src={eth} alt="img" />
          </div>
          <div>
            <p>
              <span className="font-medium">Clara Williams</span> Sent you 15ETH
            </p>
            <span className="text-[#B3B6BA] text-sm">42 mins ago</span> -{" "}
            <span className="text-[#34A853] text-sm">You received</span>
          </div>
        </div>

        <hr className="my-5" />

        <p className="time text-sm border-[#000] dark:border-[#fff]">
          <span className="text-[#4285F4] bg-[#C4C4C4]">Today</span>
        </p>


        <div
          className="flex items-center"
          style={{ height: "100%", alignItems: "center" }}
        >
          <div className="mt-5 mr-5">
            <img src={afya} alt="img" />
          </div>
          <div>
            <p>
              To <span className="font-medium">John Adamu</span> - You sent
              15AFYA
            </p>
            <span className="text-[#B3B6BA] text-sm">11:56pm</span> -{" "}
            <span className="text-[#FD0404] text-sm">You sent</span>
          </div>
        </div>

        <hr className="my-5" />

        <div
          className="flex items-center"
          style={{ height: "100%", alignItems: "center" }}
        >
          <div className="mt-5 mr-5">
            <img src={eth} alt="img" />
          </div>
          <div>
            <p>
              <span className="font-medium">Faith Rondon</span> Sent you 15ETH
            </p>
            <span className="text-[#B3B6BA] text-sm">4:14pm</span> -{" "}
            <span className="text-[#34A853] text-sm">You received</span>
          </div>
        </div>
      </section>
    </Div>
  );
};

export default Notification;
