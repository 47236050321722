import React, { useState } from "react";
import styled from "styled-components";
import Input from "components/input";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import Summary from "./AddSummary";

const Div = styled.section`
  button {
    border-radius: 24px;
  }
`;

export default function Patient() {
  const [summary, setSummary] = useState(false);

  const [firstName, setfirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [address, setaddress] = useState(null);
  const [dob, setdob] = useState(null);
  const [gender, setgender] = useState(null);
  const [email, setemail] = useState(null);
  const [nationalIdNumber, setnationalIdNumber] = useState(null);
  const [healthRecordNumber, sethealthRecordNumber] = useState(null);
  const [phoneNumber, setphoneNumber] = useState(null);

  const [firstNameError, setfirstNameError] = useState(null);
  const [lastNameError, setlastNameError] = useState(null);
  const [addressError, setaddressError] = useState(null);
  const [dobError, setdobError] = useState(null);
  const [genderError, setgenderError] = useState(null);
  const [emailError, setemailError] = useState(null);
  const [nationalIdNumberError, setnationalIdNumberError] = useState(null);
  const [healthRecordNumberError, sethealthRecordNumberError] = useState(null);
  const [phoneNumberError, setphoneNumberError] = useState(null);

  const [patientData, setpatientData] = useState(null);

  function validate() {
    const error = [];
    setfirstNameError(null);
    setlastNameError(null);
    setaddressError(null);
    setdobError(null);
    setgenderError(null);
    setemailError(null);
    setnationalIdNumberError(null);
    sethealthRecordNumberError(null);
    setphoneNumberError(null);

    if (!firstName) {
      setfirstNameError("Required");
      error.push("required");
    } else if (firstName.length < 2) {
      setfirstNameError("Must contain atleast 2 characters");
      error.push("required");
    }

    if (!lastName) {
      setlastNameError("Required");
      error.push("required");
    } else if (lastName.length < 2) {
      setlastNameError("Must contain atleast 2 characters");
      error.push("required");
    }

    if (!address) {
      setaddressError("Required");
      error.push("required");
    } else if (address.length < 9) {
      setaddressError("Must contain atleast 10 characters");
      error.push("required");
    }

    if (!dob) {
      setdobError("Required");
      error.push("required");
    }

    if (!gender) {
      setgenderError("Required");
      error.push("required");
    } else if (!["MALE", "FEMALE", "OTHER"].includes(gender)) {
      setgenderError("Not a valid gender selection");
      error.push("required");
    }

    if (!email) {
      setemailError("Required");
      error.push("required");
    } else if (email.length < 4) {
      setemailError("Not a valid email address");
      error.push("required");
    }

    if (!nationalIdNumber) {
      setnationalIdNumberError("Required");
      error.push("required");
    } else if (nationalIdNumber.length < 2) {
      setnationalIdNumberError("Must contain atleast 3 characters");
      error.push("required");
    }

    if (!healthRecordNumber) {
      sethealthRecordNumberError("Required");
      error.push("required");
    } else if (healthRecordNumber.length < 2) {
      sethealthRecordNumberError("Must contain atleast 3 characters");
      error.push("required");
    }

    if (!phoneNumber) {
      setphoneNumberError("Required");
      error.push("required");
    } else if (!isValidPhoneNumber(phoneNumber)) {
      setphoneNumberError("Not a valid Phone Number");
      error.push("required");
    }

    if (error.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const handleGenderChange = (event) => {
    setgender(event.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      setpatientData({
        firstName,
        lastName,
        address,
        dob,
        gender,
        email,
        nationalIdNumber,
        healthRecordNumber,
        phoneNumber: parsePhoneNumber(phoneNumber).number,
      });
      setSummary(true);
    }
  }

  return (
    <Div>
      <div className="text-center">
        <p className="text-xl font-medium">Create Patient</p>
      </div>

      <div className="mt-5">
        <form onSubmit={handleSubmit}>
          <Input
            hasError={Boolean(firstNameError)}
            message={firstNameError}
            value={firstName}
            onChange={(e) => {
              setfirstName(e.target.value);
            }}
            required
            type="text"
            className="mb-4"
            label="First Name"
            id="firstname"
          />
          <Input
            hasError={Boolean(lastNameError)}
            message={lastNameError}
            value={lastName}
            onChange={(e) => {
              setlastName(e.target.value);
            }}
            required
            type="text"
            className="mb-4"
            label="Last Name"
            id="lastname"
          />
          <Input
            hasError={Boolean(dobError)}
            message={dobError}
            value={dob}
            onChange={(e) => {
              setdob(e.target.value);
            }}
            required
            type="date"
            className="mb-4"
            label="Date of Birth"
            id="dob"
          />
          <div className="mb-4">
            <div className="flex justify-between items-center bg-[#FBFCFE] dark:bg-[#17202F] p-4">
              <div>
                <input
                  type="radio"
                  id="male"
                  value="MALE" //must be in capital
                  className="mr-2"
                  checked={gender === "MALE"}
                  onChange={handleGenderChange}
                />
                <label htmlFor="male">Male</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="female"
                  value="FEMALE" //must be in capital
                  className="mr-2"
                  checked={gender === "FEMALE"}
                  onChange={handleGenderChange}
                />
                <label htmlFor="female">Female</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="other"
                  value="OTHER" //must be in capital
                  className="mr-2"
                  checked={gender === "OTHER"}
                  onChange={handleGenderChange}
                />
                <label htmlFor="other">Other</label>
              </div>
            </div>
            <div className="text-red-500">{genderError}</div>
          </div>

          <Input
            hasError={Boolean(addressError)}
            message={addressError}
            value={address}
            onChange={(e) => {
              setaddress(e.target.value);
            }}
            required
            type="text"
            className="mb-4"
            label="Address"
            id="address"
          />
          <Input
            hasError={Boolean(phoneNumberError)}
            message={phoneNumberError}
            value={phoneNumber}
            onChange={(e) => {
              setphoneNumber(e.target.value);
            }}
            required
            type="tel"
            className="mb-4"
            placeholder="+2348000000001"
            label="Phone Number"
            id="phone"
          />
          <Input
            hasError={Boolean(emailError)}
            message={emailError}
            value={email}
            onChange={(e) => {
              setemail(e.target.value);
            }}
            required
            type="email"
            className="mb-4"
            placeholder="johndoe@gmail.com"
            label="Email Address"
            id="email"
          />
          <Input
            hasError={Boolean(nationalIdNumberError)}
            message={nationalIdNumberError}
            value={nationalIdNumber}
            onChange={(e) => {
              setnationalIdNumber(e.target.value);
            }}
            required
            type="text"
            className="mb-4"
            label="National ID Number"
            id="nin"
          />
          <Input
            hasError={Boolean(healthRecordNumberError)}
            message={healthRecordNumberError}
            value={healthRecordNumber}
            onChange={(e) => {
              sethealthRecordNumber(e.target.value);
            }}
            required
            type="text"
            label="Health Record Number"
            id="health"
          />

          <div className="mt-20 flex justify-end">
            <button className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5">
              Submit
            </button>
          </div>
        </form>
      </div>
      <Summary summary={summary} setSummary={setSummary} data={patientData} />
    </Div>
  );
}
