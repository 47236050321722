import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";
import styled from "styled-components";

const Div = styled.div`
  .arrow {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

function AccountChooseIndexPage() {
  const navigate = useNavigate();
  return (
    <Div className="p-4 mx-auto max-w-[500px]">
      <div>
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="arrow flex items-center rounded-full mb-4 text-blue-500 py-2  outline-none ring-0 focus-visible:ring-1 ring-blue-500 "
        >
          <BsArrowLeft className="mr-2" /> Go Back
        </button>
      </div>
      <Outlet />
    </Div>
  );
}

export default AccountChooseIndexPage;
