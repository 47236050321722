import React, { useState, useRef, useCallback } from "react";
import { countries } from "countries-list";
import Input from "components/input";
import { localeIncludes } from "locale-includes";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import SelectSearch from "react-select-search";
import { useNavigate } from "react-router";
import classNames from "classnames";
// import { ImSpinner8, ImArrowDown } from "react-icons/im";
import styled from "styled-components";

const codes = Object.keys(countries);
const countryObjects = Object.values(countries);
const newObject = countryObjects.map((country, i) => ({
  ...country,
  value: codes[i],
}));

const Div = styled.div`
  .text {
    font-family: 'Airbnb Cereal App', sans-serif;
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-family: 'Airbnb Cereal App', sans-serif;
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

const Title = styled.div`
  .t p {
    font-size: 1rem;
    line-height: 1.37rem;
    font-weight: normal;
  }
`;

function PhoneNumberReset() {
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const navigate = useNavigate();

  const search = useCallback((options) => {
    return (query) => {
      return options.filter((option) =>
        localeIncludes(option.name, query, {
          usage: "search",
          sensitivity: "base",
        })
      );
    };
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    if (!country) {
      document.querySelector(".input-search")?.focus();
    } else if (isValidPhoneNumber(phoneNumber, country)) {
      setPhoneError("");
      setPhoneNumber(parsePhoneNumber(phoneNumber, country).number);
      navigate("/auth/reset/email", {
        state: { phoneNumber: parsePhoneNumber(phoneNumber, country).number },
      });
    } else {
      setPhoneError("Invalid phone number");
      inputRef.current?.focus();
    }
  }

  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Forgot your PIN?</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        No worries. Enter your phone number to reset it.
      </p>
      <form className="" onSubmit={handleSubmit}>
        <div className="my-6">
          <SelectSearch
            ref={buttonRef}
            className={(options) => {
              if (options === "container") {
                return "max-h-60 overflow-y-hidden grid grid-rows-[auto,1fr]";
              }
              if (options === "select") {
                return "overflow-y-auto";
              }
              if (options === "option") {
                return "text-left block w-full";
              }
              if (options === "row") {
                return "block px-4 py-2";
              }
              if (options === "input") {
                return classNames(
                  "p-4 placeholder-gray-300 w-full outline-none bg-blue bg-opacity-10 text-gray-800 dark:text-white input-search",
                  {
                    "bg-opacity-20": country,
                  }
                );
              }
              if (options === "has-focus") {
                return "ring ring-blue-500 rounded-sm";
              }
              if (options === "is-highlighted" || options === "is-selected") {
                return "bg-blue block";
              }
              return "";
            }}
            options={newObject}
            value={country}
            onChange={(value) => setCountry(value)}
            search
            filterOptions={search}
            placeholder="Select your country"
          />
        </div>

        <Input
          hasError={Boolean(phoneError)}
          message={phoneError}
          ref={inputRef}
          required
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          type="tel"
          id="phoneNumber"
          label="Phone Number"
        />

        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-14 text-lg rounded-full text-white block"
            // { "opacity-20": loading }
          )}
        >
          Next
          {/* {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )} */}
        </button>
      </form>
    </Div>
  );
}

export default PhoneNumberReset;
