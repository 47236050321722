// import OnBoarding from "components/onboarding";
import OnBoarding from "./components/Onboarding/index";
import Splash from "components/splash";
import Auth from "pages/auth";
import Email from "pages/auth/email";
import InputNumber from "pages/auth/number";
import OTP from "pages/auth/otp";
import Pin from "pages/auth/pin";
import SignUp from "pages/auth/signup";
import Role from "pages/auth/role";
import Reset from "pages/auth/reset/reset";
import EmailReset from "pages/auth/reset/email-reset";
import OtpReset from "pages/auth/reset/otp-reset";
import PhoneNumberReset from "pages/auth/reset/phone-reset";
import ChooseMinor from "pages/auth/ChooseMinor";
import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";

import Notification from "components/Notification";

import { useWhoAmIQuery } from "generated/graphql";

import userVar from "vars/user";
import who_am_i_refetch from "vars/who_am_i_refetch";

import Dashboard from "pages/app";
import PatientLayout from "pages/app/patient";
import PatientHome from "pages/app/patient/home";
import PatientWallet from "pages/app/patient/wallet";
import PatientMarket from "pages/app/patient/market";
import Login from "pages/auth/login";

/* CHW Starts here */

import CHWLayout from "pages/app/chw";
import CHWHome from "pages/app/chw/home";
import CHWAddUser from "components/CHW/Home/Add/addUser";
import CHWAdd from "components/CHW/Home/Add/addUserContainer";
import CHWRecord from "./components/CHW/Home/Record/recordActivityContainer";
import CHWRecordActivity from "./components/CHW/Home/Record/recordActivity";
import Minor from "./components/CHW/Home/Add/Minor";
import Patient from "./components/CHW/Home/Add/Patient";
import Activity from "./pages/app/chw/Activity";
import ActivityIndex from "./pages/app/chw/Activity/activity";
import PatientDetails from "./components/CHW/Activity/PatientDetails";
import PractitionerDetails from "./components/CHW/Activity/PractitionerDetails";

import Reason from "./components/CHW/Home/Record/Reason";
import NewActivity from "./components/CHW/Home/Record/NewActivity";
import EditActivity from "./components/CHW/Home/Record/EditActivity";

/* CHW Ends Here */

import ChangePhone from "./pages/app/account/changePhoneNumber";
import ChangePin from "./pages/app/account/changePin";

import QuestionsList from "./components/Earn/QuestionsList";

import AccountContainer from "./pages/app/account";
import MyAccount from "./pages/app/account/account";
import Profile from "./pages/app/account/profile";
import Settings from "./pages/app/account/settings";
import Help from "./pages/app/account/help";
import AccountChooseIndexPage from "./pages/app/account/choose";
import ChooseRole from "./pages/app/account/choose/role";
import ChooseClinic from "./pages/app/account/choose/ChooseHospital";
import SelectMinor from "./pages/app/account/choose/ChooseMinor";

/* Practitioner Starts here */

import PractitionerLayout from "./pages/app/practitioner";
import PractitionerHome from "./pages/app/practitioner/home";
import EarnContainer from "./pages/app/earn";
import EarnIndexPage from "./pages/app/earn/earn";
import EarnFormPage from "./pages/app/earn/form";
import PractitionerActivity from "./pages/app/practitioner/Activity";
import PractitionerActivityIndex from "./pages/app/practitioner/Activity/activity";
import PractitionerActivityPaDetails from "./components/Practitioner/Activity/PatientDetails";
import CHWDetails from "./components/Practitioner/Activity/CHWDetails";

/* Practitioner ends here */

import MinorLayout from "./pages/app/minor";
import MinorHome from "./pages/app/minor/home";
import ChooseHospital from "./pages/auth/ChooseHospital";
import MinorAccountContainer from "./pages/app/minoraccount";
import MinorSettings from "./pages/app/minoraccount/settings";
import MinorProfile from "./pages/app/minoraccount/profile";
import MyMinorAccount from "./pages/app/minoraccount/account";

function App() {
  const { data, loading, refetch } = useWhoAmIQuery();

  who_am_i_refetch({ refetch });

  useEffect(() => {
    if (data && data.whoAmI) {
      localStorage.setItem("cus-rib-user", JSON.stringify(data.whoAmI));
      userVar(data.whoAmI);
    }
  }, [data]);

  if (loading) return <Splash />;
  return (
    <div className="max-w-[500px] mx-auto">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<OnBoarding />} />
          <Route path="/auth" element={<Auth />}>
            <Route index element={<InputNumber />} />
            <Route path="signup" element={<SignUp />} />
            <Route path="email" element={<Email />} />
            <Route path="otp" element={<OTP />} />
            <Route path="pin" element={<Pin />} />
            <Route path="login" element={<Login />} />
            <Route path="role" element={<Role />} />
            <Route path="select-minor" element={<ChooseMinor />} />
            <Route path="select-clinic" element={<ChooseHospital />} />
            <Route path="reset" element={<Reset />}>
              <Route path="phone" element={<PhoneNumberReset />} />
              <Route path="email" element={<EmailReset />} />
              <Route path="otp" element={<OtpReset />} />
            </Route>
          </Route>
          <Route path="/app" element={<Dashboard />}>
            <Route path="account" element={<AccountContainer />}>
              <Route index element={<MyAccount />} />
              <Route path="profile" element={<Profile />} />
              <Route path="settings" element={<Settings />} />
              <Route path="help" element={<Help />} />
              <Route path="changephone" element={<ChangePhone />} />
              <Route path="changepin" element={<ChangePin />} />
              <Route path="choose" element={<AccountChooseIndexPage />}>
                <Route path="role" element={<ChooseRole />} />
                <Route path="minor" element={<SelectMinor />} />
                <Route path="clinic" element={<ChooseClinic />} />
              </Route>
            </Route>

            <Route path="earn" element={<EarnContainer />}>
              <Route index element={<EarnIndexPage />} />
              <Route path="form" element={<EarnFormPage />}>
                <Route path=":id" element={<QuestionsList />} />
              </Route>
            </Route>

            <Route path="notification" element={<Notification />}></Route>

            <Route path="patient" element={<PatientLayout />}>
              <Route path="home" element={<PatientHome />} />
              <Route path="wallet" element={<PatientWallet />} />
              <Route path="market" element={<PatientMarket />} />
            </Route>

            <Route path="chw" element={<CHWLayout />}>
              <Route path="home" element={<CHWHome />} />
              <Route path="add" element={<CHWAdd />}>
                <Route index element={<CHWAddUser />}></Route>
                <Route path="patient" element={<Patient />} />
                <Route path="minor" element={<Minor />} />
              </Route>
              <Route path="record" element={<CHWRecord />}>
                <Route index element={<CHWRecordActivity />} />
                <Route path="new" element={<NewActivity />} />
                <Route path="edit" element={<EditActivity />} />
                <Route path="why" element={<Reason />} />
              </Route>
              <Route path="activity" element={<Activity />}>
                <Route index element={<ActivityIndex />} />
                <Route path={"patient"} element={<PatientDetails />} />
                <Route
                  path={"practitioner"}
                  element={<PractitionerDetails />}
                />
              </Route>
            </Route>

            <Route path="practitioner" element={<PractitionerLayout />}>
              <Route path="home" element={<PractitionerHome />} />
              <Route path="activity" element={<PractitionerActivity />}>
                <Route index element={<PractitionerActivityIndex />} />
                <Route
                  path={"patient"}
                  element={<PractitionerActivityPaDetails />}
                />
                <Route path={"practitioner"} element={<CHWDetails />} />
              </Route>
            </Route>

            <Route path="minor" element={<MinorLayout />}>
              <Route path="home" element={<MinorHome />} />
              <Route path="account" element={<MinorAccountContainer />}>
                <Route index element={<MyMinorAccount />} />
                <Route path="settings" element={<MinorSettings />} />
                <Route path="help" element={<Help />} />
                <Route path="profile" element={<MinorProfile />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
