import React, { useState } from "react";
import Input from "components/input";
import { useChangePhoneNumberMutation } from "generated/graphql";
import who_am_i_refetch from "vars/who_am_i_refetch";
import StatusModal from "components/StatusModal";
import { useDisclosure, Portal } from "@chakra-ui/react";
import { ImSpinner8 } from "react-icons/im";
import classNames from "classnames";

function ConfirmPin({
  currentPhone,
  newPhoneNumber,
  pin,
  setpin,
  otp,
  userId,
}) {
  const [pinError, setPinError] = useState("");
  const [Err, setErr] = useState(null);
  const [changeNumber, { loading }] = useChangePhoneNumberMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const verify = () => {
    setPinError("");
    if (pin.length !== 4) {
      setPinError("Pin must be 4 characters long");
    } else {
      changeNumber({
        variables: {
          input: {
            userId,
            otp,
            pin,
            newPhoneNumber,
          },
        },
      })
        .then(() => {
          who_am_i_refetch().refetch();
          onOpen();
        })
        .catch((error) => {
          setErr(error.message);
          onOpen();
        });
    }
  };

  return (
    <>
      <div className="font-bold">
        <div className="flex justify-between">
          <p>Old Phone Number</p>
          <p>{currentPhone}</p>
        </div>
        <div className="flex justify-between">
          <p>New Phone Number</p>
          <p>{newPhoneNumber}</p>
        </div>
      </div>
      <Input
        value={pin}
        onChange={(e) => {
          setpin(e.target.value);
        }}
        hasError={Boolean(pinError)}
        message={pinError}
        type="password"
        className="mt-5"
        label="Enter pin"
        id="pin"
        minLength={4}
        maxLength={4}
        pattern="[0-9]{4}"
        autoFocus
        required
      />
      <div className="mt-12 flex justify-end">
        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 text-lg rounded-full text-white block",
            { "opacity-20": loading }
          )}
          onClick={verify}
          disabled={loading}
        >
          Confirm
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center bg-opacity-20 items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </div>
      {/* NOTE: fix this modal that doesn't show above the whole change pin modal  and better still add an error modal as well*/}

      {Err ? (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text={Err}
          status="Failed"
          // status={false}
          samePageNav
        />
      ) : (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          text="Your phone number has been changed successfully"
          navigateTo="/app/account"
        />
      )}
    </>
  );
}

export default ConfirmPin;
