import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";
import { MdOutlineArrowBack } from "react-icons/md";
import { AiFillCopy } from "react-icons/ai";
import styled from "styled-components";
import BarCode from "assets/images/barcode.png";

const Background = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
  z-index: 10000000000000;
`;

const ModalContent = styled.div`
  height: 500px;
  border-radius: 24px 24px 0px 0px;

  button {
    border-radius: 24px;
  }

  .line {
    height: 5px;
    width: 30px;
    background: #b3b6ba;
  }

  .title {
    color: #4285f4;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1.8rem;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
`;

// Receive and setReceive are coming from navs

const ReceiveAFYA = ({ receive, setReceive }) => {
  const setModal = useRef();

  const closeModal = (e) => {
    if (setModal.current === e.target) {
      setReceive(false);
    }
  };

  const animate = useSpring({
    config: {
      duration: 250,
    },
    opacity: receive ? 1 : 0,
    transform: receive ? `translateY(0%)` : `translateY(100%)`,
  });

  return (
    <Background ref={setModal} onClick={closeModal}>
      <ModalWrapper>
        <animated.div style={animate}>
          <ModalContent className="p-4 bg-white dark:bg-[#343B46]">
            <div className="flex mt-2 justify-center mb-2.5">
              <div className="line"></div>
            </div>

            <div className="flex items-center justify-between px-2 mb-8">
              <div
                className="items-center"
                onClick={(prevState) => setReceive(!prevState)}
              >
                <MdOutlineArrowBack size={36} color="#4285F4" />
              </div>
              <div className="title mr-4">Receive</div>
              <div></div>
            </div>

            <div className="h-full text-center mt-24">
              <div className="flex justify-center mb-4">
                <img src={BarCode} alt="barcode" />
              </div>
              <b>uvfcgbge978@#ghw5&5eljel</b>

              <hr className="mt-12" />

              <div className="flex items-center justify-center mt-6">
                <p>Copy Address</p> <AiFillCopy />
              </div>
            </div>
          </ModalContent>
        </animated.div>
      </ModalWrapper>

      {/* <Confirm confirm={confirm} /> */}
    </Background>
  );
};

export default ReceiveAFYA;
