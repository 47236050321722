import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "components/input";
import { useValidateAuthOtpMutation } from "generated/graphql";

import styled from "styled-components";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function OTP() {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [validateSignup, { loading, error: validateError }] =
    useValidateAuthOtpMutation();

  useEffect(() => {
    if (!location.state) navigate("/auth");
  }, [location.state, navigate]);

  function handleSubmit(e) {
    e.preventDefault();
    if (loading) return;
    if (otp.length === 6 && /^\d{6}$/.test(otp)) {
      setError("");
      validateSignup({
        variables: {
          input: { otp, phoneNumber: location.state.phoneNumber },
        },
      }).then(() => {
        navigate("../pin", { state: location.state });
      });
    } else setError("OTP must be six (6) digits");
  }
  return (
    <Div>
      <h1 className="text-gray-300 mb-2 text">Create your account.</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Kindly enter the OTP sent to your email to proceed.
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          hasError={Boolean(error)}
          message={error}
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          required
          type="number"
          className="mt-5"
          label="Input OTP"
          id="otp"
          autoFocus
        />
        <p>{!!validateError && validateError.message}</p>
        <p className="mt-14">
          Didn’t get the code?{" "}
          <button className="text-blue-500 ">Resend OTP</button>
        </p>
        <button className="bg-blue-500 font-bold outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-4 text-lg rounded-full text-white block">
          Verify
        </button>
      </form>
    </Div>
  );
}

export default OTP;
