import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Div = styled.div`
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  input[type="tel"] {
    width: 100%;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    background: transparent;
    display: block;
  }

  input:focus {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
  }
`;

function Question({
  index,
  id,
  text,
  type,
  options,
  required,
  qanda,
  setqanda,
  background,
}) {
  const [bg, setBg] = useState("");
  const handleChange = (e) => {
    const p = [...qanda];
    const index = p.findIndex((x) => x.questionId === id);
    if (type === "MULTISELECT") {
      if (p[index].answer.includes(e.target.value)) {
        const ans_copy = [...p[index].answer];
        const new_ans = ans_copy.filter((x) => x !== e.target.value);
        p[index] = { ...p[index], answer: new_ans };
      } else {
        p[index].answer.push(e.target.value);
      }
    } else {
      p[index] = { ...p[index], answer: e.target.value };
    }
    setqanda(p);
  };

  useEffect(() => {
    setBg(background);
  }, []);

  return (
    <Div className={`${bg} px-3 py-3 mb-4 dark:text-black`}>
      {/* <h1>Question {index + 1}</h1> */}
      <p className="text-xl mb-2">{text}</p>
      <div className="flex">
        {type === "EMAIL" ||
        type === "NUMBER" ||
        type === "DATE" ||
        type === "TEL" ? (
          <input
            className="text-black"
            type={type.toLowerCase()}
            onChange={handleChange}
            value={qanda[index].answer}
            required={required}
          />
        ) : type === "SHORT_ANSWER" ? (
          <>
            <input
              className="text-black"
              onChange={handleChange}
              value={qanda[index].answer}
              type="text"
              required={required}
            />
          </>
        ) : type === "BOOLEAN" ? (
          <>
            {options.map((op, i) => (
              <div key={i} className="mr-4 flex items-center">
                <input
                  type="radio"
                  id={op + i}
                  name={text}
                  value={op}
                  onChange={handleChange}
                  checked={qanda[index]?.answer === op}
                  className="mr-1"
                  required={required}
                />
                <label htmlFor={op + i} className="inline-block">
                  {op}
                </label>
              </div>
            ))}
          </>
        ) : type === "MULTICHOICE" ? (
          <div className="flex flex-col">
            {options.map((op, i) => (
              <div key={i} className="mr-4">
                <label htmlFor={op + i}>
                  <input
                    type="radio"
                    id={op + i}
                    name={text}
                    value={op}
                    onChange={handleChange}
                    checked={qanda[index]?.answer === op}
                    className="mr-1"
                  />{" "}
                  {op}
                </label>
              </div>
            ))}
          </div>
        ) : type === "MULTISELECT" ? (
          <div className="flex flex-col">
            {options.map((op, i) => (
              <div key={i} className="flex">
                <label htmlFor={op}>
                  <input
                    type="checkbox"
                    id={op}
                    name={text}
                    value={op}
                    onChange={handleChange}
                    checked={qanda[index]?.answer?.includes(op)}
                  />{" "}
                  {op}
                </label>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </Div>
  );
}

export default Question;
