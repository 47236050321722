import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFindAllUsersByIdQuery } from "generated/graphql";
import Empty from "components/Empty";

import styled from "styled-components";

const minors = [
  {
    name: "Michael Olubaje Adams",
  },
  {
    name: "John Adebayo Adams",
  },
  {
    name: "Mercy Fisayo Adams",
  },
];

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function ChooseMinor() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, loading, error } = useFindAllUsersByIdQuery({
    variables: { input: location.state.user.linkedMinorAccount },
  });

  function handleChoice(i) {
    localStorage.setItem(
      "cus-rib-user",
      JSON.stringify(data.findAllUsersById[i])
    );
    localStorage.setItem("cus-rib-loggedInRole", "MINOR");
    navigate("/app/minor/home", { replace: true });
  }

  if (loading) <div>Loading...</div>;
  if (error) <Empty text={error.message} />;
  if (data?.findAllUsersById?.length <= 0) <Empty text={"No Minor Found"} />;

  useEffect(() => {
    if (!location.state) navigate("/auth", { replace: true });
  }, [location.state, navigate]);

  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Select Minor,</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Kindly choose from the options, which account you want to log in to.
      </p>

      <section className="mt-3">
        {data?.findAllUsersById?.map((minor, i) => (
          <div
            onClick={() => handleChoice(i)}
            key={i}
            className="flex justify-between p-4 mb-3 bg-[#B7D0FB]"
          >
            <p>
              {minor.profile.firstName} {minor.profile.lastName}
            </p>
            <p>select</p>
          </div>
        ))}
      </section>
    </Div>
  );
}

export default ChooseMinor;
