import React, { useRef, useState } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdOutlineArrowBack } from "react-icons/md";
import { useCreateChwPatientMutation } from "generated/graphql";
import { Portal } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import StatusModal from "components/StatusModal";
import shapes from "assets/images/shapes.png";
import shapeDark from "assets/images/shapedark.png";

const Background = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
  z-index: 10000000000000;
`;

const ModalContent = styled.div`
  border-radius: 24px 24px 0px 0px;
  height: 609px;
  overflow-y: scroll;

  button {
    border-radius: 24px;
  }

  table,
  th,
  td {
    border-collapse: collapse;
  }

  table {
    border: 1px solid #1a222f;
    border-radius: 24px 24px 0px 0px;
    border-collapse: separate;
  }

  .hii {
    border-left: 1px solid #1a222f;
    width: 60%;
  }

  tr {
    width: 100%;
  }

  td {
    border: none;
    /* width: %; */
  }

  td {
    padding: 0.6rem;
  }

  .line {
    height: 5px;
    width: 30px;
    background: #b3b6ba;
  }

  .title {
    color: #4285f4;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1.8rem;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Summary = ({ summary, setSummary, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [createPatient, { error }] = useCreateChwPatientMutation();
  const [err, setErr] = useState(false);

  const setModal = useRef();

  const animate = useSpring({
    config: {
      duration: 250,
    },
    opacity: summary ? 1 : 0,
    transform: summary ? `translateY(0%)` : `translateY(100%)`,
  });

  async function handleSubmit() {
    createPatient({
      variables: {
        input: {
          firstName: data?.firstName,
          lastName: data?.lastName,
          address: data?.address,
          dob: data?.dob,
          gender: data?.gender,
          email: data?.email,
          nationalIdNumber: data?.nationalIdNumber,
          healthRecordNumber: data?.healthRecordNumber,
          phoneNumber: data?.phoneNumber,
        },
      },
    })
      .then(() => {
        setErr(false)
        setSummary(false);
        onOpen();
      })
      .catch((err) => {
        setSummary(false);
        setErr(true);
        onOpen();
      });
  }

  const closeModal = (e) => {
    if (setModal.current === e.target) {
      setSummary(false);
    }
  };

  return (
    <div>
      {summary && (
        <div className="fixed bottom-0 left-0 z-50">
          <Portal>
            <Background
              ref={setModal}
              onClick={closeModal}
              className="relative"
            >
              <ModalWrapper className="z-50">
                <animated.div style={animate}>
                  <ModalContent
                    className="p-4 bg-white dark:bg-[#343B46] relative"
                    style={{ zIndex: "10" }}
                  >
                    <div
                      className="absolute bottom-0 right-0 left-0 w-full"
                      style={{ zIndex: "-1" }}
                    >
                      <img
                        src={shapes}
                        alt="shape"
                        className="w-full block dark:hidden"
                      />
                      <img
                        src={shapeDark}
                        alt="shape"
                        className="w-full hidden dark:block"
                      />
                    </div>
                    <div className="flex mt-2 justify-center mb-2.5">
                      <div className="line"></div>
                    </div>

                    <div className="flex items-center justify-between px-2 mb-4 main-content">
                      <div className="items-center cursor-pointer">
                        <MdOutlineArrowBack
                          size={36}
                          color="#4285F4"
                          onClick={() => {
                            setSummary(false);
                          }}
                        />
                      </div>
                      <div className="title mr-4">Verify</div>
                      <div></div>
                    </div>

                    <div>
                      <p className="text-base text-[#1A222F] dark:text-white mb-4">
                        Please confirm the following details before submitting:
                      </p>

                      <table className="border-collapse w-full">
                        <tbody>
                          <tr className="mb-3">
                            <td className="font-medium text-sm">Name</td>
                            <td className="hii text-base font-normal">
                              {data?.firstName} {data?.lastName}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-medium text-sm">
                              Date of Birth
                            </td>
                            <td className="hii text-base font-normal">
                              {data?.dob}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-medium text-sm">Gender</td>
                            <td className="hii text-base font-normal">
                              {data?.gender}
                            </td>
                          </tr>
                          <tr>
                            <td className="font-medium text-sm">Address</td>
                            <td className="hii text-base font-normal">
                              {data?.address}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-medium text-sm">
                              Email Address
                            </td>
                            <td className="hii text-base font-normal">
                              {data?.email}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-medium text-sm">
                              National ID Number
                            </td>
                            <td className="hii text-base font-normal">
                              {data?.nationalIdNumber}
                            </td>
                          </tr>

                          <tr>
                            <td className="font-medium text-sm">
                              Health Record Number
                            </td>
                            <td className="hii text-base font-normal">
                              {data?.healthRecordNumber}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <section>
                        {/* <div className="mb-2">
                          <p className="font-medium text-sm mb-1">Name</p>
                          <p className="text-base font-normal">
                            {data?.firstName} {data?.lastName}
                          </p>
                        </div>
                        <div className="mb-2">
                          <p className="font-medium text-sm mb-1">
                            Date of Birth
                          </p>
                          <p className="text-base font-normal">{data?.dob}</p>
                        </div>
                        <div className="mb-2">
                          <p className="font-medium text-sm mb-1">Gender</p>
                          <p className="text-base font-normal">
                            {data?.gender}
                          </p>
                        </div>
                        <div className="mb-2">
                          <p className="font-medium text-sm mb-1">Address</p>
                          <p className="text-base font-normal">
                            {data?.address}
                          </p>
                        </div>
                        <div className="mb-2">
                          <p className="font-medium text-sm mb-1">
                            Phone number
                          </p>
                          <p className="text-base font-normal">
                            {data?.phoneNumber}
                          </p>
                        </div>
                        <div className="mb-2">
                          <p className="font-medium text-sm mb-1">
                            Email address
                          </p>
                          <p className="text-base font-normal">{data?.email}</p>
                        </div>
                        <div className="mb-2">
                          <p className="font-medium text-sm mb-1">
                            National ID Number
                          </p>
                          <p className="text-base font-normal">
                            {data?.nationalIdNumber}
                          </p>
                        </div>
                        <div className="mb-2">
                          <p className="font-medium text-sm mb-1">
                            Health Record Number
                          </p>
                          <p className="text-base font-normal">
                            {data?.healthRecordNumber}
                          </p>
                        </div> */}
                      </section>

                      <div className="mt-16">
                        <div className="mt-12 flex justify-end">
                          <button
                            className="text-[#4285F4] font-bold"
                            onClick={() => setSummary(false)}
                            type="button"
                          >
                            Edit
                          </button>
                          <button
                            className="bg-[#4285F4] text-base ml-8 text-white font-bold px-6 py-2.5"
                            onClick={handleSubmit}
                            type="button"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                  </ModalContent>
                </animated.div>
              </ModalWrapper>
            </Background>
          </Portal>
        </div>
      )}
      {err ? (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text={error?.message}
          status="Failed"
          samePageNav={true}
        />
      ) : (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text="Your account has been created. Go to activities to see your full interactions."
          navigateTo="/app/chw/home"
          status="Account created"
        />
      )}
    </div>
  );
};

export default Summary;
