import React from "react";
// import logo from "assets/images/logofull.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Light1 from "assets/images/light1.svg";
import Dark1 from "assets/images/dark1.svg";

const Div = styled.div`
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: flex-end; */
  /* height: 100vh; */

  img {
    width: 225px;
    height: 250px;
  }

  .title {
    font-family: "Airbnb Cereal App", sans-serif;
    font-size: 1.75rem;
    line-height: 2.43rem;
    font-weight: 700;
  }

  .sub-title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.37rem;
    max-width: 18.25rem;
    color: #1A222F;
    letter-spacing: .25px;
  }

  a {
    font-size: 1rem;
    font-weight: 700;
  }
`;

export default function One() {
  return (
    <Div className="p-5 max-w-[500px]">
      <div className="mb-20 mt-20 block dark:hidden">
        <img src={Light1} alt="" className="mx-auto w-full" />
      </div>
      
      <div className="mb-20 mt-20 hidden dark:block">
        <img src={Dark1} alt="" className="mx-auto w-full" />
      </div>
      
      {/* <div className="my-11 h-[250px]"></div> */}
      <div className="text-left">
        <h1 className="title mb-4">Welcome to Ribbon</h1>
        <p className="sub-title items-center">
          Earn tokenized incentives for participating in surveys to improve
          population health.
        </p>
      </div>
      <div className="text-right mt-8">
        <Link
          to="/auth"
          className="bg-blue-500 py-2 text-lg rounded-full text-white inline-block px-6"
        >
          Get Started
        </Link>
      </div>
    </Div>
  );
}
