import React from "react";
import { NavLink } from "react-router-dom";
import Mavatar from "assets/images/Avatar.png";
import Favatar from "assets/images/favatar.png";
import styled from "styled-components";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }

  .main {
    width: 100%;

    > * {
      width: 45%;
    }

    .cont {
      height: 186px;
    }
  }

  /* .cont {
    height: 186px;
    width: 150px;
  } */
`;

function AddUser() {
  return (
    <Div>
      <h1 className="text-gray-300 font-bold text mb-14">Create New User</h1>
      <form>
        <div className="flex justify-between text-center main">
          <NavLink to="/app/chw/add/patient">
            <div className="bg-[#B7D0FB] rounded pt-3 cont">
              <div className="flex justify-center mb-3">
                <img src={Favatar} alt="avatar" />
              </div>
              <div>
                <div className="text-white text-sm">Patient</div>
              </div>
              {/* <input type="checkbox" /> */}
            </div>
          </NavLink>

          <NavLink to="/app/chw/add/minor">
            <div className="bg-[#B7D0FB] rounded pt-3 cont">
              <div className="flex justify-center mb-3">
                <img src={Mavatar} alt="avatar" />
              </div>
              <div className="text-white text-sm">Minor</div>
            </div>
          </NavLink>
        </div>
      </form>
    </Div>
  );
}

export default AddUser;
