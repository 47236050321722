import React, { useState } from "react";
import Input from "components/input";
import { useChangePinMutation } from "generated/graphql";
import StatusModal from "components/StatusModal";
import { useDisclosure, Portal } from "@chakra-ui/react";
import { ImSpinner8 } from "react-icons/im";
import classNames from "classnames";

function ConfirmPinChange({ pin, setPin, userId, otp, newPin, confirmPin }) {
  const [pinError, setPinError] = useState(null);
  const [Err, setErr] = useState(null);
  const [changePin, { loading }] = useChangePinMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const verify = () => {
    setPinError("");
    if (pin.length !== 4) {
      setPinError("Pin must be 4 characters long");
    } else {
      changePin({
        variables: {
          input: {
            userId,
            otp,
            newPin,
            verifyNewPin: confirmPin,
            pin,
          },
        },
      })
        .then(() => {
          onOpen();
        })
        .catch((error) => {
          setErr(error.message);
          onOpen();
        });
    }
  };

  return (
    <>
      <div className="font-bold">
        <p>
          By changing your pin, you are changing your login details and security
          pin for carrying out transactions.
        </p>
        <p>Enter your pin to continue:</p>
      </div>
      <Input
        hasError={Boolean(pinError)}
        message={pinError}
        required
        value={pin}
        onChange={(e) => {
          setPin(e.target.value);
        }}
        type="password"
        minLength={4}
        maxLength={4}
        pattern="[0-9]{4}"
        className="mt-5"
        label="Enter current pin"
        id="one"
        autoFocus
      />
      <div className="mt-12 flex justify-end">
        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 text-lg rounded-full text-white block",
            { "opacity-20": loading }
          )}
          onClick={verify}
          disabled={loading}
        >
          Confirm
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center bg-opacity-20 items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </div>
      {/* NOTE: fix this modal that doesn't show above the whole change pin modal  and better still add an error modal as well*/}

      {Err ? (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text={Err}
          // status={false}
          status="Failed"
          samePageNav
        />
      ) : (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text="Your pin has been changed successfully."
          navigateTo="/app/account"
        />
      )}
    </>
  );
}

export default ConfirmPinChange;
