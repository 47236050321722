import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useFindInteractionByChwQuery } from "generated/graphql";
import userVar from "vars/user";
import loggedInClinicVar from "vars/loggedInClinic";
import { Progress, Stack } from "@chakra-ui/react";
import Empty from "components/Empty";

const Div = styled.div`
  .list {
    -moz-box-shadow: 0 2px 5px -2px #ccc;
    -webkit-box-shadow: 0 2px 5px -2px #ccc;
    box-shadow: 0 2px 5px -2px #ccc;
    margin-bottom: 0.5rem;
  }
`;

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 400px);
  justify-content: center;
`;

const Practioner = () => {
  const { data, error, loading } = useFindInteractionByChwQuery({
    variables: {
      clinicId: loggedInClinicVar().id,
      chwId: userVar().id,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <div>
        <Loading>
          <Stack>
            <Progress size="xs" isIndeterminate />
          </Stack>
        </Loading>
      </div>
    );
  }

  if (error) {
    return <Empty text={error.message} />;
  }

  if (data?.findInteractionByCHW?.length < 1) {
    return <Empty text="No Interaction Found" />;
  }

  return (
    <Div>
      {data?.findInteractionByCHW.map((interaction, i) => (
        <Link
          to={"/app/chw/activity/practitioner"}
          state={{ id: interaction.id }}
          key={i}
        >
          <div className="flex list justify-between p-4">
            <p>
              {interaction?.practitioner?.firstName}{" "}
              {interaction?.practitioner?.lastName}
            </p>
            <p>{interaction?.prescriptionNumber}</p>
          </div>
        </Link>
      ))}
    </Div>
  );
};

export default Practioner;
