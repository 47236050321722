import React, { useEffect, useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import classNames from "classnames";
import Mavatar from "assets/images/Avatar.png";
import Favatar from "assets/images/favatar.png";
import MinorAvatar from "assets/images/minor.png";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import userVar from "vars/user";
import loggedInAsVar from "vars/loggedInAs";
import loggedInClinicVar from "vars/loggedInClinic";
import { useFindAllClinicsByIdLazyQuery } from "generated/graphql";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }

  .cont {
    height: 186px;
    width: 170px;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    width: 100%;
    justify-content: space-between;
    grid-row-gap: 32px;
    grid-column: 2/3;
  }

  @media screen and (max-width: 380px) {
    .cont {
      height: 186px;
      width: 160px;
    }
  }

  @media screen and (max-width: 360px) {
    .cont {
      height: 186px;
      width: 155px;
    }
  }

  @media screen and (max-width: 345px) {
    .cont {
      height: 186px;
      width: 140px;
    }
  }

  @media screen and (max-width: 330px) {
    .cont {
      height: 186px;
      width: 135px;
    }
  }

  @media screen and (max-width: 305px) {
    .cont {
      height: 186px;
      width: 125px;
    }
  }
`;

function Role() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedRole, setSelectedRole] = useState(null);
  const [findClinics, { data: clinicData, loading, error: clinicError }] =
    useFindAllClinicsByIdLazyQuery();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedRole) return;

    if (selectedRole === "CHW" || selectedRole === "PRACTITIONER") {
      // NOTE: Checking fetched clinics and if > 1 navigate to select clinic screen else, persist clinic details and navigate to home screen
      if (Array.isArray(clinicData?.findAllClinicsById)) {
        if (clinicData.findAllClinicsById.length > 1) {
          navigate("/auth/select-clinic", {
            replace: true,
            state: {
              user: location.state.user,
              clinics: clinicData.findAllClinicsById,
              selectedRole,
            },
          });
        } else if (clinicData.findAllClinicsById.length === 1) {
          localStorage.setItem(
            "cus-rib-loggedInClinic",
            JSON.stringify(clinicData.findAllClinicsById[0])
          );
          localStorage.setItem(
            "cus-rib-user",
            JSON.stringify(location.state.user)
          );
          localStorage.setItem("cus-rib-loggedInRole", selectedRole);
          userVar(location.state.user);
          loggedInAsVar(selectedRole);
          loggedInClinicVar(clinicData.findAllClinicsById[0]);
        }
        if (selectedRole === "CHW") {
          navigate("/app/chw/home", { replace: true });
        } else if (selectedRole === "PRACTITIONER") {
          navigate("/app/practitioner/home", { replace: true });
        }
      }
    } else if (selectedRole === "PATIENT") {
      localStorage.setItem("cus-rib-user", JSON.stringify(location.state.user));
      localStorage.setItem("cus-rib-loggedInRole", selectedRole);
      userVar(location.state.user);
      loggedInAsVar(selectedRole);
      navigate("/app/patient/home", { replace: true });
    } else if (selectedRole === "MINOR") {
      if (location.state.user.linkedMinorAccount.length > 1) {
        navigate("/auth/select-minor", {
          replace: true,
          state: {
            user: location.state.user,
            selectedRole,
          },
        });
      } else {
        localStorage.setItem(
          "cus-rib-user",
          JSON.stringify(location.state.user)
        );
        localStorage.setItem("cus-rib-loggedInRole", selectedRole);
        navigate("/app/minor/home", { replace: true });
      }
    }
  };

  async function fetchClinics() {
    await findClinics({
      variables: { input: location.state.user.clinicId },
    });
  }

  useEffect(() => {
    if (!location.state) navigate("/auth", { replace: true });
  }, [location.state, navigate]);

  useEffect(() => {
    if (
      location.state.user?.role === "CHW" ||
      location.state.user?.role === "PRACTITIONER"
    ) {
      fetchClinics();
    }
  }, []);

  if (loading) <div>Loading...</div>;
  if (clinicError) <div>{clinicError.message}</div>;

  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Select Profile</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text mb-8">
        Kindly select your profile.{loading}
      </p>
      <form onSubmit={handleSubmit}>
        <div className="text-center grid-container">
          <div
            className="bg-[#B7D0FB] rounded pt-3 cont cursor-pointer"
            onClick={() => setSelectedRole("PATIENT")}
          >
            <p className="flex justify-center mb-3">
              <img src={Favatar} alt="avatar" />
            </p>
            <p className="text-white text-sm">Patient</p>
            <input
              type="checkbox"
              value={"PATIENT"}
              name="role"
              checked={selectedRole === "PATIENT"}
              onChange={(e) => setSelectedRole(e.target.value)}
            />
          </div>

          {location?.state.user.role === "CHW" && (
            <div
              className="bg-[#B7D0FB] rounded pt-3 cont cursor-pointer"
              onClick={() => setSelectedRole("CHW")}
            >
              <p className="flex justify-center mb-3">
                <img src={Mavatar} alt="avatar" />
              </p>
              <p className="text-white text-sm">Community Health Worker</p>
              <input
                type="checkbox"
                value={"CHW"}
                name="role"
                checked={selectedRole === "CHW"}
                onChange={(e) => setSelectedRole(e.target.value)}
              />
            </div>
          )}

          {location?.state.user.role === "PRACTITIONER" && (
            <div
              className="bg-[#B7D0FB] rounded pt-3 cont cursor-pointer"
              onClick={() => setSelectedRole("PRACTITIONER")}
            >
              <p className="flex justify-center mb-3">
                <img src={Mavatar} alt="avatar" />
              </p>
              <p className="text-white text-sm">Practitioner</p>
              <input
                type="checkbox"
                value={"PRACTITIONER"}
                name="role"
                checked={selectedRole === "PRACTITIONER"}
                onChange={(e) => setSelectedRole(e.target.value)}
              />
            </div>
          )}

          {location?.state.user.linkedMinorAccount &&
            location?.state.user.linkedMinorAccount.length > 0 && (
              <div
                className="bg-[#B7D0FB] rounded pt-3 cont cursor-pointer"
                onClick={() => setSelectedRole("MINOR")}
              >
                <p className="flex justify-center mb-3">
                  <img src={MinorAvatar} alt="avatar" />
                </p>
                <p className="text-white text-sm">Minor</p>
                <input
                  type="checkbox"
                  value={"MINOR"}
                  checked={selectedRole === "MINOR"}
                  name="role"
                  onChange={(e) => setSelectedRole(e.target.value)}
                />
              </div>
            )}
        </div>

        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-14 text-lg rounded-full text-white block",
            { "opacity-20": loading }
          )}
          disabled={loading}
        >
          Next
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center bg-opacity-20 items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </form>
    </Div>
  );
}

export default Role;
