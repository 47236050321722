import React from "react";
import { MdSearch } from "react-icons/md";

const InputSearch = () => {
  return (
    <div className="w-full relative">
      <input
        // type="search"
        placeholder="Search"
        className="w-full p-4 dark:text-black"
        style={{ border: "1px solid #B7D0FB", borderRadius: '4px' }}
      />
       <div className="absolute right-0 mr-5" style={{top: '40%'}}>
        <MdSearch className="dark:hidden block" />
        <MdSearch className="dark:block hidden" color="black" />
      </div>
    </div>
  );
};

export default InputSearch;
