import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "components/input";
import { MdOutlineCheck } from "react-icons/md";
import classNames from "classnames";
import { useCreatePinMutation } from "generated/graphql";
import userVar from "vars/user";
import tokenVar from "vars/token";
import { ImSpinner8 } from "react-icons/im";
import styled from "styled-components";
import loggedInAsVar from "../../vars/loggedInAs";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function Pin() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pin, setpin] = useState("");
  const [error, setError] = useState("");
  const [accept, setAccept] = useState(false);
  const [createPin, { data, loading, error: validateError }] =
    useCreatePinMutation();

  useEffect(() => {
    if (!location.state) navigate("/auth");
  }, [location.state, navigate]);

  function handleSubmit(e) {
    e.preventDefault();
    if (pin.length === 4 && /^\d{4}$/.test(pin)) {
      setError("");
      createPin({
        variables: { input: { pin, phoneNumber: location.state.phoneNumber } },
      });
    } else setError("Pin must be four (4) digits");
  }

  useEffect(() => {
    if (data) {
      localStorage.setItem("cus-rib-token", data.createPin.token);
      localStorage.setItem("cus-rib-user", JSON.stringify(data.createPin));
      localStorage.setItem("cus-rib-loggedInRole", data.createPin.role);
      tokenVar(data.createPin.token);
      userVar(data.createPin);
      loggedInAsVar(data.createPin.role);
    }
  }, [data, navigate]);

  return (
    <Div>
      <h1 className="text-gray-300 text">Create your PIN.</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Finish setting up by creating a 4-digit PIN
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          hasError={Boolean(error)}
          message={error}
          value={pin}
          onChange={(e) => {
            setpin(e.target.value);
          }}
          required
          minLength={4}
          maxLength={4}
          pattern="[0-9]{4}"
          type="password"
          className="mt-5"
          label="Pin"
          id="pin"
          autoFocus
        />
        <div
          className="grid gap-4 mt-8"
          style={{ gridTemplateColumns: "auto 1fr" }}
        >
          <div>
            <label className="relative inline-flex justify-center items-center ring-2 ring-blue-500">
              <input
                required
                checked={accept}
                onChange={(e) => {
                  setAccept(e.target.checked);
                }}
                className="absolute z-[-99]"
                type="checkbox"
                name="acceppt"
                aria-describedby="#condition"
                id=""
              />
              <p>{!!validateError && validateError.message} </p>
              <div
                className={classNames([
                  {
                    "bg-blue-500": accept,
                    "bg-white": !accept,
                  },
                ])}
              >
                <MdOutlineCheck className="text-white" />
              </div>
            </label>
          </div>
          <p>
            I agree to the beneficiation of my data to improve the platform’s
            sustainability and population health outcomes.
          </p>
        </div>
        <button
          className={classNames(
            "bg-blue-500 relative outline-none focus-visible:ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-10 text-lg rounded-full text-white block",
            { "!bg-blue-200": !accept || loading }
          )}
        >
          Next
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </form>
    </Div>
  );
}

export default Pin;
