import React, { useState } from "react";
import { BsArrowUpRight } from "react-icons/bs";
import {
  MdCompareArrows,
  MdNotifications,
  MdRemoveRedEye,
} from "react-icons/md";
import { HiOutlineArrowRight } from "react-icons/hi";
import ItemsTab from "components/itemstab";
import Icon from "react-crypto-icons";
import styled from "styled-components";
import SendModal from "./Send/Modal";
import ReceiveModal from "./Receive/Modal";
import bback from "../../../assets/images/bback.png";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  font-family: 'Airbnb Cereal App', sans-serif;

  .just {
    display: flex;
    width: 100%;
    justify-content: end;
  }

  .fixed {
    bottom: -15px;
  }

  .f {
    flex-direction: column;
  }

  .bal {
    font-size: 1.31rem;
  }
`;

function PatientWallet() {
  const [sendModal, setSendModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);

  const openSendModal = () => {
    setSendModal((prevState) => !prevState);
  };

  const openReceiveModal = () => {
    setWithdrawModal((prevState) => !prevState);
  };

  return (
    <Div className="px-4 relative h-screen">
      <div className="fixed bottom-0 left-0 right-0" style={{ zIndex: -10 }}>
        <img src={bback} alt="bback" className="w-full" />
      </div>

      <div>
        <button className="flex justify-end w-full">
          <MdNotifications className="text-xl" />
        </button>

        <div className="flex flex-col f items-center">
          <div className="flex">
            <p className="text-sm font-normal">Total Wallet Balance</p>
            <button className="px-2">
              <MdRemoveRedEye />
            </button>
          </div>
          <p className="bal font-bold">ZAR 1890.00</p>
        </div>
      </div>
      <div className="my-8 flex items-center text-blue-main justify-between space-x-4">
        <div
          className="ring-1 
          max-w-[100px] 
          flex-auto 
          text-center 
          py-3 
          rounded-sm 
          cursor-pointer"
          onClick={openSendModal}
        >
          <HiOutlineArrowRight className="mx-auto" />
          <p className="font-bold">Send</p>
        </div>
        <div
          className="ring-1 
          max-w-[100px] 
          flex-auto 
          text-center 
          py-3 
          rounded-sm 
          cursor-pointer"
          onClick={openReceiveModal}
        >
          <BsArrowUpRight className="mx-auto" />
          <p className="font-bold">Withdraw</p>
        </div>
        <div className="ring-1 max-w-[100px] flex-auto text-center py-3 rounded-sm cursor-pointer">
          <MdCompareArrows className="mx-auto" />
          <p className="font-bold">Swap</p>
        </div>
      </div>
      <ItemsTab
        className="hover:shadow-md font-medium mb-2"
        leading={<Icon name="btc" size={25} />}
        trailing={
          <>
            <p className="font-medium">102.73A</p>
            <p className="text-sm text-gray-300 text-right">R20</p>
          </>
        }
      >
        <p className="text-left font-medium">AFYA</p>
      </ItemsTab>
      <ItemsTab
        className="hover:shadow-md font-medium mb-2"
        leading={<Icon name="btc" size={25} />}
        trailing={
          <>
            <p className="font-medium">0.73 BTC</p>
            <p className="text-sm text-gray-300 text-right">R20</p>
          </>
        }
      >
        <p className="text-left font-medium">Bitcoin</p>
      </ItemsTab>
      <ItemsTab
        className="hover:shadow-md font-medium mb-2"
        leading={<Icon name="eth" size={25} />}
        trailing={
          <>
            <p className="font-medium">10.73 ETH</p>
            <p className="text-sm text-gray-300 text-right">R20</p>
          </>
        }
      >
        <p className="text-left font-medium">Etherium</p>
      </ItemsTab>
      <ItemsTab
        className="hover:shadow-md font-medium mb-2"
        leading={<Icon name="ltc" size={25} />}
        trailing={
          <>
            <p className="font-medium">102.73 LTC</p>
            <p className="text-sm text-gray-300 text-right">R20</p>
          </>
        }
      >
        <p className="text-left font-medium">Litecoin</p>
      </ItemsTab>
      <ItemsTab
        className="hover:shadow-md font-medium mb-2"
        leading={<Icon name="doge" size={25} />}
        trailing={
          <>
            <p className="font-medium">102.73 Doge</p>
            <p className="text-sm text-gray-300 text-right">R20</p>
          </>
        }
      >
        <p className="text-left font-medium">Dogecoin</p>
      </ItemsTab>

      <SendModal sendModal={sendModal} setSendModal={setSendModal} />
      <ReceiveModal
        withdrawModal={withdrawModal}
        setWithdrawModal={setWithdrawModal}
      />
    </Div>
  );
}

export default PatientWallet;
