import React from "react";
import Doodles from "assets/images/Doodles.png";
import styled from "styled-components";

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 400px);
  justify-content: center;
  align-items: center;
`;

const Empty = ({ text }) => {
  return (
    <div>
      <Section>
        <img src={Doodles} alt="doodle" />
        <p className="mt-2 text-center">{text}</p>
      </Section>
    </div>
  );
};

export default Empty;
