import React from "react";
import { useGetCompletedTasksQuery } from "generated/graphql";
import userVar from "vars/user";
import loggedInAsVar from "vars/loggedInAs";
import { Stack, Progress } from "@chakra-ui/react";
import styled from "styled-components";
import Empty from "components/Empty";

const Div = styled.div`
  .todo {
    moz-box-shadow: 0 2px 5px -2px #ccc;
    -webkit-box-shadow: 0 2px 5px -2px #ccc;
    box-shadow: 0 2px 5px -2px #ccc;
    margin-bottom: 0.5rem;
  }
`;
const Loading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 400px);
  justify-content: center;
`;

function CompletedTasksList() {
  const { data, loading, error } = useGetCompletedTasksQuery({
    variables: { input: { userId: userVar()?.id, userRole: loggedInAsVar() } },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <Empty text={error.message} />;
  }

  if (loading) {
    return (
      <div>
        <Loading>
          <Stack>
            <Progress size="xs" isIndeterminate />
          </Stack>
        </Loading>
      </div>
    );
  }

  return (
    <>
      {data?.getCompletedTasks?.length < 1 ? (
        <section className="mt-8 dark:text-[rgb(255,251,229)] py-3 px-2">
          <Empty text="You have not completed any activity." />
        </section>
      ) : (
        <Div>
          <div className="mt-8 dark:text-[#FFFBE5]">
            {data?.getCompletedTasks.map((task) => (
              <div className="flex justify-between p-4 todo" key={task.id}>
                <p>{task.name}</p>
                <p>A{parseFloat(task.reward).toFixed(2)}</p>
              </div>
            ))}
          </div>
        </Div>
      )}
    </>
  );
}

export default CompletedTasksList;
