import React from "react";
import styled from "styled-components";

const minors = [
  {
    name: "Michael Olubaje Adams",
  },
  {
    name: "John Adebayo Adams",
  },
  {
    name: "Mercy Fisayo Adams",
  },
];

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function ChooseHospital() {
  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Select Hospital,</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Kindly choose from the options, which hospital you want to log in to.
      </p>

      <section className = 'mt-3'>
        {minors.map((minor, i) => (
          <div key={i} className = 'flex justify-between p-4 mb-3 bg-[#B7D0FB]'>
            <p>{minor.name}</p>
            <p>select</p>
          </div>
        ))}
      </section>
    </Div>
  );
}

export default ChooseHospital;
