import React, { useState, useRef } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdOutlineArrowBack } from "react-icons/md";
import { RiArrowDownSLine } from "react-icons/ri";
import Input from "../../../../components/input";
import { Portal } from "@chakra-ui/react";
// import Confirm from './Confirm'

const Background = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
  z-index: 10000000000000;
`;

const ModalContent = styled.div`
  height: 400px;
  border-radius: 24px 24px 0px 0px;

  button {
    border-radius: 24px;
  }

  .line {
    height: 5px;
    width: 30px;
    background: #b3b6ba;
  }

  .title {
    color: #4285f4;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1.8rem;
  }
`;

const DropDownPage = styled.div`
  .dropdown {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    position: relative;
    user-select: none;
  }

  .dropdown .dropdown-btn {
    /* background: #F7FAFF; */
    padding: 0.875rem 1.06rem;
    font-size: 1rem;
    cursor: pointer;
    color: #b3b6ba;
  }

  .dropdown .dropdown-content {
    /* padding: 15px; */
    background: #f7faff;
    font-size: 0.875rem;
    box-sizing: border-box;
    margin-top: 0.3rem;
  }

  .dropdown .dropdown-content .dropdown-item {
    padding: 1rem;
    cursor: pointer;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputField = styled.div``;

const options = ["GHF", "AFYA"];

const Modal = ({ withdrawModal, setWithdrawModal }) => {
  const [selected, setSelected] = useState("Currency");
  const [dropdown, setDropDown] = useState(false);
  const setModal = useRef();
  const [confirm, setConfirm] = useState(false);
  const [values, setValues] = useState({});

  const closeModal = (e) => {
    if (setModal.current === e.target) {
      setWithdrawModal(false);
    }
  };

  const handleDropDownShow = () => {
    setDropDown((prevState) => {
      return !prevState;
    });
  };

  const animate = useSpring({
    config: {
      duration: 250,
    },
    opacity: withdrawModal ? 1 : 0,
    transform: withdrawModal ? `translateY(0%)` : `translateY(100%)`,
  });

  return (
    <div className="max-w-[500px]">
      {withdrawModal && (
        <Portal>
          <Background ref={setModal} onClick={closeModal}>
            <ModalWrapper withdrawModal={withdrawModal}>
              <animated.div style={animate}>
                <ModalContent className="p-4 bg-white dark:bg-[#343B46]">
                  <div className="flex mt-2 justify-center mb-2.5">
                    <div className="line"></div>
                  </div>

                  <div className="flex items-center justify-between px-2 mb-4">
                    <div
                      className="items-center"
                      onClick={(prevState) => setWithdrawModal(!prevState)}
                    >
                      <MdOutlineArrowBack size={36} color="#4285F4" />
                    </div>
                    <div className="title mr-4">Withdraw</div>
                    <div></div>
                  </div>

                  <DropDownPage>
                    <div className="dropdown bg-[#F7FAFF] dark:bg-[#343B46]">
                      <div
                        className="dropdown-btn flex text-[#B3b6Ba] text-base justify-between items-center"
                        onClick={handleDropDownShow}
                      >
                        {selected}
                        <div>
                          <RiArrowDownSLine size={24} color="#B3B6BA" />
                        </div>
                      </div>

                      {dropdown && (
                        <div className="dropdown-content bg-[#F7FAFF] dark:bg-[#343B46]">
                          {options.map((option, index) => {
                            return (
                              <div
                                className="dropdown-item bg-[#F7FAFF] dark:bg-[#343B46]"
                                key={index}
                                onClick={() => {
                                  setSelected(option);
                                  setDropDown(false);
                                }}
                              >
                                {option}
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </DropDownPage>

                  <InputField>
                    <Input
                      hasError={false}
                      message=""
                      required
                      type="text"
                      className="mt-5"
                      label="User Wallet ID"
                      id="wallet"
                      autoFocus
                    />

                    <Input
                      hasError={false}
                      message=""
                      required
                      type="number"
                      className="mt-5"
                      label="Amount"
                      id="amount"
                    />

                    <div className="mt-12 flex justify-end">
                      <button className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5">
                        Next
                      </button>
                    </div>
                  </InputField>
                </ModalContent>
              </animated.div>
            </ModalWrapper>

            {/* <Confirm confirm /> */}
          </Background>
        </Portal>
      )}
    </div>
  );
};

export default Modal;
