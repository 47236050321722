import React, { useRef } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdOutlineArrowBack } from "react-icons/md";
import Input from "components/input";

const Background = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  z-index: 10000000000000;
`;

const ModalContent = styled.div`
  background: #fff;
  height: 600px;
  border-radius: 24px 24px 0px 0px;
  width: 100vw;

  button {
    border-radius: 24px;
  }

  .line {
    height: 5px;
    width: 30px;
    background: #b3b6ba;
  }

  .title {
    color: #4285f4;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1.8rem;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
`;

const InputField = styled.div``;

const Confirm = ({ confirm, setConfirm }) => {
  const setModal = useRef();

  const closeModal = (e) => {
    if (setModal.current === e.target) {
      setConfirm(false);
    }
  };

  const animate = useSpring({
    config: {
      duration: 250,
    },
    // opacity: showModal ? 1 : 0,
    // transform: showModal ? `translateY(0%)` : `translateY(100%)`
  });

  return (
    <>
      {confirm && (
        <Background ref={setModal} onClick={closeModal}>
          <ModalWrapper>
            <animated.div style={animate}>
              <ModalContent className="p-4">
                <div className="flex mt-2 justify-center mb-2.5">
                  <div className="line"></div>
                </div>

                <div className="flex items-center justify-between px-2 mb-8">
                  <div className="items-center">
                    <MdOutlineArrowBack size={36} color="#4285F4" />
                  </div>
                  <div className="title mr-4">Send</div>
                  <div></div>
                </div>

                <div className="mb-16">
                  <div className="flex justify-between">
                    <div>Amount</div>
                    <div>500 AFYA</div>
                  </div>

                  <div className="flex justify-between">
                    <div>Receiver</div>
                    <div>FGguy43537@gmail.com</div>
                  </div>
                </div>

                <InputField>
                  <Input
                    hasError={false}
                    message=""
                    required
                    type="password"
                    className="mb-20"
                    label="Pin"
                    id="pin"
                    autoFocus
                  />

                  <div className="flex justify-end">
                    <button className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5">
                      Confirm
                    </button>
                  </div>
                </InputField>
              </ModalContent>
            </animated.div>
          </ModalWrapper>
        </Background>
      )}
    </>
  );
};

export default Confirm;
