import React from "react";
import { MdNotifications, MdRemoveRedEye } from "react-icons/md";
import styled from "styled-components";
import background from "assets/images/background.png";
import b1 from 'assets/images/b1.png'
import b1dark from 'assets/images/b2.png'

import Navs from "components/Practitioner/Home/navs";

import RecentActivities from "components/Home/RecentActivities";
import { useGetWalletQuery } from "generated/graphql";

const Div = styled.div`
  position: relative;
  padding-top: 1rem;
  padding-bottom: 1.5rem;

  .just {
    display: flex;
    width: 100%;
    justify-content: end;
  }

  .f {
    flex-direction: column;
    justify-content: center;
    max-width: 158px;
    height: 79px;
    margin: 0 auto;
    border: 1px solid rgba(212, 226, 250, 0.38);
    border-radius: 4px 4px 0px 0px;
  }

  .bal {
    font-size: 1.31rem;
  }

  .todo,
  .activity {
    color: #b3b6ba;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .refresh,
  .see {
    color: #4285f4;
    font-size: 0.875rem;
    font-weight: 500;
  }

  .complete {
    font-size: 1rem;
  }

  .to-cont {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
`;

function PractitionerHome() {
  const { data: walletData } = useGetWalletQuery();
  return (
    <Div>
      <div className="px-4">
        <div className="absolute top-0 left-0 w-full" style={{ zIndex: "-1" }}>
          <img
            src={background}
            alt=""
            className="bg-cover object-cover w-full"
          />
          <img 
            src={b1}
            className = 'dark:hidden absolute top-0 w-full left-0 right-0'
          />
          <img 
            src={b1dark}
            className = 'hidden dark:block absolute top-0 w-full left-0 right-0'
          />
        </div>
        <div className="back mt-4">
          <button className="flex justify-end w-full">
            <MdNotifications className="text-xl" />
          </button>

          <div className="flex flex-col f items-center text-[#0D5AD9]">
            <div className="flex">
              <p className="text-sm font-normal">AFYA Balance</p>
              <button className="px-2">
                <MdRemoveRedEye />
              </button>
            </div>
            <p className="bal font-bold">
              {walletData?.getWallet && walletData.getWallet?.balance} AFYA
            </p>
          </div>
        </div>

        <div className="mt-8">
          <Navs />

          <div className="mt-10">
            <RecentActivities />
          </div>
        </div>
      </div>
    </Div>
  );
}

export default PractitionerHome;
