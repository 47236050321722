import BottomNav from "components/practitionernav";
import React from "react";
import { Outlet } from "react-router";
// import bback from "../../../assets/images/bback.png";

function PractitionerLayout() {
  return (
    <div className="relative" style={{fontFamily: 'Airbnb Cereal App'}}>
      <div className="overflow-y-auto h-screen">
        <Outlet />
      </div>
      <BottomNav />
    </div>
  );
}

export default PractitionerLayout;
