import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Activities = {
  __typename?: 'Activities';
  createdAt: Scalars['DateTime'];
  dataId: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  reward: Scalars['Float'];
  role: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Int'];
};

export type Activity = {
  __typename?: 'Activity';
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['Int'];
  reward: Scalars['Float'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AdminLogin = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  clinicId?: Maybe<Array<Scalars['Float']>>;
  email?: Maybe<Scalars['String']>;
  healthRecordNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  linkedMinorAccount?: Maybe<Array<Scalars['Float']>>;
  nationalIdNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  role: Scalars['String'];
  status: Scalars['String'];
  token: Scalars['String'];
  wallet?: Maybe<Wallet>;
};

export type BarrUserInput = {
  userId: Scalars['Float'];
};

export type BaseUserResponse = {
  __typename?: 'BaseUserResponse';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  phoneNumber?: Maybe<Scalars['String']>;
  role: Scalars['String'];
  status: Scalars['String'];
};

export type ChangePasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  verifyNewPassword: Scalars['String'];
};

export type ChangePhoneNumberInput = {
  newPhoneNumber: Scalars['String'];
  otp: Scalars['String'];
  pin: Scalars['String'];
  userId: Scalars['Float'];
};

export type ChangePhoneNumberOtpInput = {
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ChangePinInput = {
  newPin: Scalars['String'];
  otp: Scalars['String'];
  pin: Scalars['String'];
  userId: Scalars['Float'];
  verifyNewPin: Scalars['String'];
};

export type ChangePinOtpInput = {
  email: Scalars['String'];
  pin: Scalars['String'];
};

export type Clinic = {
  __typename?: 'Clinic';
  authority: Scalars['String'];
  disabled: Scalars['Boolean'];
  faxCode: Scalars['String'];
  faxNumber: Scalars['String'];
  headOfFacility: Scalars['String'];
  headOfFacilityEmail: Scalars['String'];
  healthDistrict: Scalars['String'];
  id: Scalars['Int'];
  institutionEmail: Scalars['String'];
  institutionName: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  phoneNumber: Scalars['String'];
  physicalAddress: Scalars['String'];
  postalAddress: Scalars['String'];
  postalCode: Scalars['String'];
  subDistrict: Scalars['String'];
  suburb: Scalars['String'];
  type: Scalars['String'];
  uniCode: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type CreateActivityInput = {
  description: Scalars['String'];
  reward: Scalars['Float'];
  title: Scalars['String'];
};

export type CreateChwInput = {
  clinicId: Scalars['Float'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type CreateChwMinorInput = {
  address?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  guardianId?: InputMaybe<Scalars['Float']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type CreateChwPatientInput = {
  address?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  healthRecordNumber?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  nationalIdNumber?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type CreateClinicAdminInput = {
  clinicId: Scalars['Float'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CreateClinicInput = {
  authority: Scalars['String'];
  faxCode: Scalars['String'];
  faxNumber: Scalars['String'];
  headOfFacility: Scalars['String'];
  headOfFacilityEmail: Scalars['String'];
  healthDistrict: Scalars['String'];
  institutionEmail: Scalars['String'];
  institutionName: Scalars['String'];
  latitude: Scalars['String'];
  longitude: Scalars['String'];
  phoneNumber: Scalars['String'];
  physicalAddress: Scalars['String'];
  postalAddress: Scalars['String'];
  postalCode: Scalars['String'];
  subDistrict: Scalars['String'];
  suburb: Scalars['String'];
  type: Scalars['String'];
  uniCode: Scalars['String'];
};

export type CreateInteractionInput = {
  activityId: Array<Scalars['Float']>;
  additionalNotes?: InputMaybe<Scalars['String']>;
  chwId: Scalars['Float'];
  clinicId: Scalars['Float'];
  patientId: Scalars['Float'];
  practitionerId: Scalars['Float'];
  prescriptionId: Array<Scalars['Float']>;
  prescriptionNumber: Scalars['String'];
  role: Scalars['String'];
};

export type CreatePatientInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreatePinInput = {
  phoneNumber: Scalars['String'];
  pin: Scalars['String'];
};

export type CreatePracInput = {
  clinicId: Scalars['Float'];
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
};

export type CreatePrescription = {
  adultDose: Scalars['Float'];
  allowedUsers: Array<Scalars['String']>;
  brand: Scalars['String'];
  childrenDose: Scalars['Float'];
  description: Scalars['String'];
  dosageForm: Array<Scalars['String']>;
  manufacturer: Scalars['String'];
  route: Array<Scalars['String']>;
  supplier: Scalars['String'];
  title: Scalars['String'];
  weight: Scalars['Float'];
};

export type CreateQuestionCategoryInput = {
  name: Scalars['String'];
  ownerId: Scalars['Float'];
  ownerRewardPercentage: Scalars['Float'];
  userRewardPercentage: Scalars['Float'];
};

export type CreateQuestionInput = {
  optionalNotes: Scalars['Boolean'];
  options: Array<Scalars['String']>;
  required?: InputMaybe<Scalars['Boolean']>;
  taskId: Scalars['Int'];
  text: Scalars['String'];
  type: Scalars['String'];
};

export type CreateTask = {
  allowedRoles: Array<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  dependencyId?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  gender: Array<Scalars['String']>;
  name: Scalars['String'];
  published?: InputMaybe<Scalars['Boolean']>;
  questionCategoryId: Scalars['Float'];
  reward: Scalars['Float'];
  type: Scalars['String'];
};

export type Currency = {
  __typename?: 'Currency';
  code: Scalars['String'];
  name: Scalars['String'];
};

export type EditInteractionRequest = {
  interactionId: Scalars['Int'];
  reason: Scalars['String'];
};

export type FindAllActivitiesInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<Activity_Sortby>;
};

export type FindAllActivitiesResponse = {
  __typename?: 'FindAllActivitiesResponse';
  data?: Maybe<Array<Activity>>;
  total: Scalars['Int'];
};

export type FindAllClinicsInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<Clinic_Sortby>;
};

export type FindAllClinicsResponse = {
  __typename?: 'FindAllClinicsResponse';
  data?: Maybe<Array<Clinic>>;
  total: Scalars['Int'];
};

export type FindAllInteractionsInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<Interaction_Sortby>;
};

export type FindAllInteractionsResponse = {
  __typename?: 'FindAllInteractionsResponse';
  data?: Maybe<Array<Interactions>>;
  total: Scalars['Int'];
};

export type FindAllPrescriptionsInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<Prescription_Sortby>;
};

export type FindAllPrescriptionsResponse = {
  __typename?: 'FindAllPrescriptionsResponse';
  data?: Maybe<Array<Prescription>>;
  total: Scalars['Int'];
};

export type FindAllQuestionCategories = {
  __typename?: 'FindAllQuestionCategories';
  data: Array<FindAllQuestionCategory>;
  total: Scalars['Int'];
};

export type FindAllQuestionCategory = {
  __typename?: 'FindAllQuestionCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['Float'];
  ownerRewardPercentage: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  userRewardPercentage: Scalars['Float'];
};

export type FindAllQuestionCategoryInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<Question_Category_Sortby>;
};

export type FindAllQuestionsInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<Question_Sortby>;
};

export type FindAllQuestionsResponse = {
  __typename?: 'FindAllQuestionsResponse';
  data?: Maybe<Array<Questions>>;
  total: Scalars['Int'];
};

export type FindAllTask = {
  __typename?: 'FindAllTask';
  allowedRoles: Array<Scalars['String']>;
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  dependencyId?: Maybe<Scalars['Int']>;
  description: Scalars['String'];
  gender: Array<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  questionCategory: QuestionCategory;
  questionCategoryId: Scalars['Int'];
  reward: Scalars['Float'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FindAllTasksInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  role?: InputMaybe<Task_Role>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<Task_Sortby>;
};

export type FindAllTasksResponse = {
  __typename?: 'FindAllTasksResponse';
  data?: Maybe<Array<FindAllTask>>;
  total: Scalars['Int'];
};

export type FindAllUsersInput = {
  dir?: InputMaybe<Dir>;
  page?: InputMaybe<Scalars['Float']>;
  role?: InputMaybe<User_Request_Role>;
  search?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Float']>;
  sortby?: InputMaybe<User_Request_Sortby>;
  status?: InputMaybe<User_Status>;
};

export type FindAllUsersResponse = {
  __typename?: 'FindAllUsersResponse';
  data?: Maybe<Array<User>>;
  total: Scalars['Int'];
};

export type FindQuestionCategoryById = {
  __typename?: 'FindQuestionCategoryById';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  ownerId: Scalars['Float'];
  ownerRewardPercentage: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  userRewardPercentage: Scalars['Float'];
};

export type FindUserByIdResponse = {
  __typename?: 'FindUserByIdResponse';
  email?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  phoneNumber?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  role: Scalars['String'];
  status: Scalars['String'];
};

export type Interaction = {
  __typename?: 'Interaction';
  activityId: Array<Scalars['Float']>;
  additionalNotes?: Maybe<Scalars['String']>;
  chw: Profile;
  clinic: Clinic;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  patient: Profile;
  practitioner: Profile;
  prescriptionId: Array<Scalars['Float']>;
  prescriptionNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InteractionEdit = {
  __typename?: 'InteractionEdit';
  approvedBy: User;
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  interaction: Interaction;
  reason: Scalars['String'];
  requester: User;
  status: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type Interactions = {
  __typename?: 'Interactions';
  activityId: Array<Scalars['Float']>;
  additionalNotes: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  patientId: Scalars['Float'];
  practitionerId: Scalars['Float'];
  prescriptionId: Array<Scalars['Float']>;
  prescriptionNumber: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type LoginInput = {
  phoneNumber: Scalars['String'];
  pin: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  adminCreatePatient: Scalars['Boolean'];
  adminLogin: AuthResponse;
  approveInteractionEditRequest: Scalars['Boolean'];
  barrUser: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  changePhoneNumber: Scalars['Boolean'];
  changePhoneNumberOtp: Scalars['Boolean'];
  changePin: Scalars['Boolean'];
  changePinOtp: Scalars['Boolean'];
  createActivity: Scalars['Boolean'];
  createCHW: Scalars['Boolean'];
  createChwMinor: Scalars['Boolean'];
  createChwPatient: Scalars['Boolean'];
  createClinic: Clinic;
  createClinicAdmin: Scalars['Boolean'];
  createInteraction: Scalars['Boolean'];
  createPatient: Scalars['Boolean'];
  createPin: AuthResponse;
  createPractitioner: Scalars['Boolean'];
  createPrescription: Scalars['Boolean'];
  createQuestion: Scalars['Boolean'];
  createQuestionCategory: Scalars['Boolean'];
  createTask: Scalars['Boolean'];
  deleteActivity: Scalars['Boolean'];
  deleteClinic: Scalars['Boolean'];
  deleteInteraction: Scalars['Boolean'];
  deletePrescription: Scalars['Boolean'];
  deleteQuestion: Scalars['Boolean'];
  deleteQuestionCategory: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  disableClinic: Scalars['Boolean'];
  editInteractionRequest: Scalars['Boolean'];
  enableClinic: Scalars['Boolean'];
  login: AuthResponse;
  registerUser: Scalars['Boolean'];
  rejectInteractionEditRequest: Scalars['Boolean'];
  resendOtp: Scalars['Boolean'];
  resetPin: Scalars['Boolean'];
  resetPinOtp: Scalars['Boolean'];
  submitTask: Scalars['Boolean'];
  unbarrUser: Scalars['Boolean'];
  updateActivity: Scalars['Boolean'];
  updateClinic: Scalars['Boolean'];
  updateInteraction: Scalars['Boolean'];
  updatePrescription: Scalars['Boolean'];
  updateQuestion: Scalars['Boolean'];
  updateQuestionCategory: Scalars['Boolean'];
  updateTask: Scalars['Boolean'];
  updateUserProfile: User;
  upgradePatient: Scalars['Boolean'];
  validateAuthOtp: Scalars['Boolean'];
  validateOtp: Scalars['Boolean'];
};


export type MutationAdminCreatePatientArgs = {
  input: CreatePatientInput;
};


export type MutationAdminLoginArgs = {
  input: AdminLogin;
};


export type MutationApproveInteractionEditRequestArgs = {
  id: Scalars['Float'];
};


export type MutationBarrUserArgs = {
  input: BarrUserInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


export type MutationChangePhoneNumberArgs = {
  input: ChangePhoneNumberInput;
};


export type MutationChangePhoneNumberOtpArgs = {
  input: ChangePhoneNumberOtpInput;
};


export type MutationChangePinArgs = {
  input: ChangePinInput;
};


export type MutationChangePinOtpArgs = {
  input: ChangePinOtpInput;
};


export type MutationCreateActivityArgs = {
  input: Array<CreateActivityInput>;
};


export type MutationCreateChwArgs = {
  input: CreateChwInput;
};


export type MutationCreateChwMinorArgs = {
  input: CreateChwMinorInput;
};


export type MutationCreateChwPatientArgs = {
  input: CreateChwPatientInput;
};


export type MutationCreateClinicArgs = {
  input: CreateClinicInput;
};


export type MutationCreateClinicAdminArgs = {
  input: CreateClinicAdminInput;
};


export type MutationCreateInteractionArgs = {
  input: CreateInteractionInput;
};


export type MutationCreatePatientArgs = {
  input: CreatePatientInput;
};


export type MutationCreatePinArgs = {
  input: CreatePinInput;
};


export type MutationCreatePractitionerArgs = {
  input: CreatePracInput;
};


export type MutationCreatePrescriptionArgs = {
  input: CreatePrescription;
};


export type MutationCreateQuestionArgs = {
  input: Array<CreateQuestionInput>;
};


export type MutationCreateQuestionCategoryArgs = {
  input: CreateQuestionCategoryInput;
};


export type MutationCreateTaskArgs = {
  input: CreateTask;
};


export type MutationDeleteActivityArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteClinicArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteInteractionArgs = {
  id: Scalars['Float'];
};


export type MutationDeletePrescriptionArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteQuestionArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteQuestionCategoryArgs = {
  id: Scalars['Float'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['Float'];
};


export type MutationDisableClinicArgs = {
  id: Scalars['Float'];
};


export type MutationEditInteractionRequestArgs = {
  input: EditInteractionRequest;
};


export type MutationEnableClinicArgs = {
  id: Scalars['Float'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationRejectInteractionEditRequestArgs = {
  id: Scalars['Float'];
};


export type MutationResendOtpArgs = {
  input: ResendOtpInput;
};


export type MutationResetPinArgs = {
  input: ResetPinInput;
};


export type MutationResetPinOtpArgs = {
  input: ResetPinOtpInput;
};


export type MutationSubmitTaskArgs = {
  input: SubmitTasksInput;
};


export type MutationUnbarrUserArgs = {
  input: BarrUserInput;
};


export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput;
};


export type MutationUpdateClinicArgs = {
  id: Scalars['Float'];
  input: UpdateClinicInput;
};


export type MutationUpdateInteractionArgs = {
  id: Scalars['Float'];
  input: UpdateInteractionInput;
};


export type MutationUpdatePrescriptionArgs = {
  input: UpdatePrescription;
};


export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};


export type MutationUpdateQuestionCategoryArgs = {
  input: UpdateQuestionCategoryInput;
};


export type MutationUpdateTaskArgs = {
  input: UpdateTask;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateProfileInput;
};


export type MutationUpgradePatientArgs = {
  input: UpgradePatientInput;
};


export type MutationValidateAuthOtpArgs = {
  input: ValidateOtpInput;
};


export type MutationValidateOtpArgs = {
  input: OtpValidationInput;
};

export type OtpValidationInput = {
  otp: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type Prescription = {
  __typename?: 'Prescription';
  adultDose?: Maybe<Scalars['Float']>;
  allowedUsers?: Maybe<Array<Scalars['String']>>;
  brand?: Maybe<Scalars['String']>;
  childrenDose?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  dosageForm?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  manufacturer?: Maybe<Scalars['String']>;
  route?: Maybe<Array<Scalars['String']>>;
  sn: Scalars['String'];
  supplier?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
};

export type Profile = {
  __typename?: 'Profile';
  DOB?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  barred: Scalars['Boolean'];
  country?: Maybe<Country>;
  currency?: Maybe<Currency>;
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type QandA = {
  answer: Scalars['String'];
  questionId: Scalars['Float'];
};

export type Query = {
  __typename?: 'Query';
  fetchInteractionEditRequest?: Maybe<InteractionEdit>;
  findActivityById?: Maybe<Activity>;
  findAllActivities?: Maybe<FindAllActivitiesResponse>;
  findAllActivityById: Array<Activity>;
  findAllClinics?: Maybe<FindAllClinicsResponse>;
  findAllClinicsById: Array<Clinic>;
  findAllInteractions: FindAllInteractionsResponse;
  findAllPrescriptions: FindAllPrescriptionsResponse;
  findAllPrescriptionsById: Array<Prescription>;
  findAllQuestionCategories?: Maybe<FindAllQuestionCategories>;
  findAllQuestions: FindAllQuestionsResponse;
  findAllTasks: FindAllTasksResponse;
  findAllUsers?: Maybe<FindAllUsersResponse>;
  findAllUsersById: Array<User>;
  findClinicById?: Maybe<Clinic>;
  findClinicPractitioner: Array<User>;
  findInteractionByCHW?: Maybe<Array<Interaction>>;
  findInteractionByCHWById?: Maybe<Interaction>;
  findInteractionById?: Maybe<Interaction>;
  findInteractionByPractitioner?: Maybe<Array<Interaction>>;
  findInteractionByPractitionerById?: Maybe<Interaction>;
  findPrescriptionById?: Maybe<Prescription>;
  findQuestionById?: Maybe<Question>;
  findQuestionCategoryById: FindQuestionCategoryById;
  findRecentActivities?: Maybe<Array<Activities>>;
  findTaskById?: Maybe<Task>;
  findUserByEmail?: Maybe<FindUserByIdResponse>;
  findUserById?: Maybe<FindUserByIdResponse>;
  findUserByPhoneNumber?: Maybe<BaseUserResponse>;
  findUserInteractions?: Maybe<Array<Interaction>>;
  findWalletByUserId: Wallet;
  getCompletedTasks: Array<Task>;
  getUnCompletedTasks: Array<Task>;
  getWallet: Wallet;
  test: Scalars['Boolean'];
  validatePhone: ValidatePhoneOutput;
  whoAmI?: Maybe<User>;
};


export type QueryFetchInteractionEditRequestArgs = {
  id: Scalars['Float'];
};


export type QueryFindActivityByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindAllActivitiesArgs = {
  input?: InputMaybe<FindAllActivitiesInput>;
};


export type QueryFindAllActivityByIdArgs = {
  input: Array<Scalars['Float']>;
};


export type QueryFindAllClinicsArgs = {
  input?: InputMaybe<FindAllClinicsInput>;
};


export type QueryFindAllClinicsByIdArgs = {
  input: Array<Scalars['Float']>;
};


export type QueryFindAllInteractionsArgs = {
  input?: InputMaybe<FindAllInteractionsInput>;
};


export type QueryFindAllPrescriptionsArgs = {
  input?: InputMaybe<FindAllPrescriptionsInput>;
};


export type QueryFindAllPrescriptionsByIdArgs = {
  input: Array<Scalars['Float']>;
};


export type QueryFindAllQuestionCategoriesArgs = {
  input?: InputMaybe<FindAllQuestionCategoryInput>;
};


export type QueryFindAllQuestionsArgs = {
  input?: InputMaybe<FindAllQuestionsInput>;
};


export type QueryFindAllTasksArgs = {
  input?: InputMaybe<FindAllTasksInput>;
};


export type QueryFindAllUsersArgs = {
  input?: InputMaybe<FindAllUsersInput>;
};


export type QueryFindAllUsersByIdArgs = {
  input: Array<Scalars['Float']>;
};


export type QueryFindClinicByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindClinicPractitionerArgs = {
  clinicId: Scalars['Float'];
};


export type QueryFindInteractionByChwArgs = {
  chwId: Scalars['Float'];
  clinicId?: InputMaybe<Scalars['Float']>;
};


export type QueryFindInteractionByChwByIdArgs = {
  chwId: Scalars['Float'];
  interactionId: Scalars['Float'];
};


export type QueryFindInteractionByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindInteractionByPractitionerArgs = {
  clinicId?: InputMaybe<Scalars['Float']>;
  practitionerId: Scalars['Float'];
};


export type QueryFindInteractionByPractitionerByIdArgs = {
  interactionId: Scalars['Float'];
  practitionerId: Scalars['Float'];
};


export type QueryFindPrescriptionByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindQuestionByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindQuestionCategoryByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindRecentActivitiesArgs = {
  role: Scalars['String'];
};


export type QueryFindTaskByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryFindUserByIdArgs = {
  id: Scalars['Float'];
};


export type QueryFindUserByPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryFindUserInteractionsArgs = {
  role: Scalars['String'];
  userId: Scalars['Float'];
};


export type QueryFindWalletByUserIdArgs = {
  userId: Scalars['Float'];
};


export type QueryGetCompletedTasksArgs = {
  input: UserTaskActionInput;
};


export type QueryGetUnCompletedTasksArgs = {
  input: UserTaskActionInput;
};


export type QueryValidatePhoneArgs = {
  input: ValidatePhoneInput;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['Int'];
  optionalNotes?: Maybe<Scalars['Boolean']>;
  options: Array<Scalars['String']>;
  questionCategory?: Maybe<QuestionCategory>;
  required: Scalars['Boolean'];
  task?: Maybe<Task>;
  text: Scalars['String'];
  type: Scalars['String'];
};

export type QuestionCategory = {
  __typename?: 'QuestionCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  name: Scalars['String'];
  owner?: Maybe<User>;
  ownerRewardPercentage: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  userRewardPercentage: Scalars['Float'];
};

export type Questions = {
  __typename?: 'Questions';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  options: Array<Scalars['String']>;
  questionCategoryId: Scalars['Float'];
  task: Task;
  taskId: Scalars['Float'];
  text: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type RegisterUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ResendOtpInput = {
  phoneNumber: Scalars['String'];
};

export type ResetPinInput = {
  confirmPin: Scalars['String'];
  newPin: Scalars['String'];
  otp: Scalars['String'];
};

export type ResetPinOtpInput = {
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type SubmitTasksInput = {
  qanda: Array<QandA>;
  taskId: Scalars['Float'];
  userId: Scalars['Float'];
  userRole?: InputMaybe<Role>;
};

export type Task = {
  __typename?: 'Task';
  allowedRoles: Array<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dependency?: Maybe<Task>;
  description: Scalars['String'];
  gender: Array<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  published: Scalars['Boolean'];
  question?: Maybe<Array<Question>>;
  questionCategory: QuestionCategory;
  reward: Scalars['Float'];
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UpdateActivityInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  reward?: InputMaybe<Scalars['Float']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateClinicInput = {
  authority?: InputMaybe<Scalars['String']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  faxCode?: InputMaybe<Scalars['String']>;
  faxNumber?: InputMaybe<Scalars['String']>;
  headOfFacility?: InputMaybe<Scalars['String']>;
  headOfFacilityEmail?: InputMaybe<Scalars['String']>;
  healthDistrict?: InputMaybe<Scalars['String']>;
  institutionEmail?: InputMaybe<Scalars['String']>;
  institutionName?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  physicalAddress?: InputMaybe<Scalars['String']>;
  postalAddress?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  subDistrict?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  uniCode?: InputMaybe<Scalars['String']>;
};

export type UpdateInteractionInput = {
  activityId?: InputMaybe<Array<Scalars['Float']>>;
  additionalNotes?: InputMaybe<Scalars['String']>;
  prescriptionId?: InputMaybe<Array<Scalars['Float']>>;
  prescriptionNumber?: InputMaybe<Scalars['String']>;
};

export type UpdatePrescription = {
  adultDose?: InputMaybe<Scalars['Float']>;
  allowedUsers?: InputMaybe<Array<Scalars['String']>>;
  brand?: InputMaybe<Scalars['String']>;
  childrenDose?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  dosageForm?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  manufacturer?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Array<Scalars['String']>>;
  supplier?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  weight?: InputMaybe<Scalars['Float']>;
};

export type UpdateProfileInput = {
  address?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  race?: InputMaybe<Scalars['String']>;
  skin?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId: Scalars['Float'];
  ward?: InputMaybe<Scalars['String']>;
};

export type UpdateQuestionCategoryInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Float']>;
  ownerRewardPercentage?: InputMaybe<Scalars['Float']>;
  userRewardPercentage?: InputMaybe<Scalars['Float']>;
};

export type UpdateQuestionInput = {
  id: Scalars['Int'];
  optionalNotes?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Array<Scalars['String']>>;
  required?: InputMaybe<Scalars['Boolean']>;
  taskId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateTask = {
  allowedRoles?: InputMaybe<Array<Scalars['String']>>;
  code?: InputMaybe<Scalars['String']>;
  dependencyId?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  questionCategoryId?: InputMaybe<Scalars['Float']>;
  reward?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpgradePatientInput = {
  clinicId: Scalars['Float'];
  role?: InputMaybe<User_Request_Role>;
  userId: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  clinicId?: Maybe<Array<Scalars['Float']>>;
  email?: Maybe<Scalars['String']>;
  healthRecordNumber?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  linkedMinorAccount?: Maybe<Array<Scalars['Float']>>;
  nationalIdNumber?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  profile?: Maybe<Profile>;
  role: Scalars['String'];
  status: Scalars['String'];
  wallet?: Maybe<Wallet>;
};

export type UserTaskActionInput = {
  userId: Scalars['Float'];
  userRole: Scalars['String'];
};

export type ValidateOtpInput = {
  otp: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ValidatePhoneInput = {
  phoneNumber: Scalars['String'];
};

export type ValidatePhoneOutput = {
  __typename?: 'ValidatePhoneOutput';
  exists: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type Wallet = {
  __typename?: 'Wallet';
  address: Scalars['String'];
  balance: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum Activity_Sortby {
  Reward = 'REWARD',
  Title = 'TITLE',
  Updatedate = 'UPDATEDATE'
}

export enum Clinic_Sortby {
  Authority = 'AUTHORITY',
  Healthdistrict = 'HEALTHDISTRICT',
  Subdistrict = 'SUBDISTRICT',
  Type = 'TYPE',
  Unicode = 'UNICODE',
  Updatedate = 'UPDATEDATE'
}

export enum Dir {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Interaction_Sortby {
  UpdateDate = 'UPDATE_DATE'
}

export enum Prescription_Sortby {
  Manufacturer = 'MANUFACTURER',
  Supplier = 'SUPPLIER',
  Title = 'TITLE',
  UpdateDate = 'UPDATE_DATE',
  Weight = 'WEIGHT'
}

export enum Question_Category_Sortby {
  Name = 'NAME',
  Ownerrewardpercentage = 'OWNERREWARDPERCENTAGE',
  Updatedate = 'UPDATEDATE',
  Userrewardpercentage = 'USERREWARDPERCENTAGE'
}

export enum Question_Sortby {
  Taskid = 'TASKID',
  Text = 'TEXT',
  Type = 'TYPE',
  Updatedate = 'UPDATEDATE'
}

export enum Role {
  Admin = 'ADMIN',
  Chw = 'CHW',
  Minor = 'MINOR',
  Patient = 'PATIENT',
  Practitioner = 'PRACTITIONER',
  SuperAdmin = 'SUPER_ADMIN'
}

export enum Task_Role {
  Admin = 'ADMIN',
  Patient = 'PATIENT',
  Personnel = 'PERSONNEL'
}

export enum Task_Sortby {
  Name = 'NAME',
  Reward = 'REWARD',
  Type = 'TYPE',
  Updatedate = 'UPDATEDATE'
}

export enum User_Request_Role {
  Admin = 'ADMIN',
  Chw = 'CHW',
  Patient = 'PATIENT',
  Personnel = 'PERSONNEL',
  Practitioner = 'PRACTITIONER'
}

export enum User_Request_Sortby {
  Role = 'ROLE',
  UpdateDate = 'UPDATE_DATE'
}

export enum User_Status {
  Barred = 'BARRED',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED',
  PinCreated = 'PIN_CREATED',
  Start = 'START',
  Validated = 'VALIDATED'
}

export type ChangePhoneNumberMutationVariables = Exact<{
  input: ChangePhoneNumberInput;
}>;


export type ChangePhoneNumberMutation = { __typename?: 'Mutation', changePhoneNumber: boolean };

export type ChangePhoneNumberOtpMutationVariables = Exact<{
  input: ChangePhoneNumberOtpInput;
}>;


export type ChangePhoneNumberOtpMutation = { __typename?: 'Mutation', changePhoneNumberOtp: boolean };

export type ChangePinMutationVariables = Exact<{
  input: ChangePinInput;
}>;


export type ChangePinMutation = { __typename?: 'Mutation', changePin: boolean };

export type ChangePinOtpMutationVariables = Exact<{
  input: ChangePinOtpInput;
}>;


export type ChangePinOtpMutation = { __typename?: 'Mutation', changePinOtp: boolean };

export type CreateChwMinorMutationVariables = Exact<{
  input: CreateChwMinorInput;
}>;


export type CreateChwMinorMutation = { __typename?: 'Mutation', createChwMinor: boolean };

export type CreateChwPatientMutationVariables = Exact<{
  input: CreateChwPatientInput;
}>;


export type CreateChwPatientMutation = { __typename?: 'Mutation', createChwPatient: boolean };

export type CreateInteractionMutationVariables = Exact<{
  input: CreateInteractionInput;
}>;


export type CreateInteractionMutation = { __typename?: 'Mutation', createInteraction: boolean };

export type CreatePinMutationVariables = Exact<{
  input: CreatePinInput;
}>;


export type CreatePinMutation = { __typename?: 'Mutation', createPin: { __typename?: 'AuthResponse', id: number, role: string, phoneNumber?: string | null, email?: string | null, status: string, token: string, clinicId?: Array<number> | null, linkedMinorAccount?: Array<number> | null, profile?: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null, country?: { __typename?: 'Country', name: string, code: string } | null, currency?: { __typename?: 'Currency', code: string } | null } | null } };

export type EditInteractionRequestMutationVariables = Exact<{
  input: EditInteractionRequest;
}>;


export type EditInteractionRequestMutation = { __typename?: 'Mutation', editInteractionRequest: boolean };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', id: number, role: string, phoneNumber?: string | null, email?: string | null, status: string, token: string, clinicId?: Array<number> | null, linkedMinorAccount?: Array<number> | null, profile?: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null, country?: { __typename?: 'Country', name: string, code: string } | null, currency?: { __typename?: 'Currency', code: string } | null } | null } };

export type ResetPinMutationVariables = Exact<{
  input: ResetPinInput;
}>;


export type ResetPinMutation = { __typename?: 'Mutation', resetPin: boolean };

export type ResetPinOtpMutationVariables = Exact<{
  input: ResetPinOtpInput;
}>;


export type ResetPinOtpMutation = { __typename?: 'Mutation', resetPinOtp: boolean };

export type CreatePatientMutationVariables = Exact<{
  input: CreatePatientInput;
}>;


export type CreatePatientMutation = { __typename?: 'Mutation', createPatient: boolean };

export type SubmitTaskMutationVariables = Exact<{
  input: SubmitTasksInput;
}>;


export type SubmitTaskMutation = { __typename?: 'Mutation', submitTask: boolean };

export type UpdateInteractionMutationVariables = Exact<{
  input: UpdateInteractionInput;
  id: Scalars['Float'];
}>;


export type UpdateInteractionMutation = { __typename?: 'Mutation', updateInteraction: boolean };

export type ValidateOtpMutationVariables = Exact<{
  input: OtpValidationInput;
}>;


export type ValidateOtpMutation = { __typename?: 'Mutation', validateOtp: boolean };

export type ValidateAuthOtpMutationVariables = Exact<{
  input: ValidateOtpInput;
}>;


export type ValidateAuthOtpMutation = { __typename?: 'Mutation', validateAuthOtp: boolean };

export type FindAllActivitiesQueryVariables = Exact<{
  input?: InputMaybe<FindAllActivitiesInput>;
}>;


export type FindAllActivitiesQuery = { __typename?: 'Query', findAllActivities?: { __typename?: 'FindAllActivitiesResponse', data?: Array<{ __typename?: 'Activity', id: number, title: string, reward: number }> | null } | null };

export type FindAllActivityByIdQueryVariables = Exact<{
  input: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type FindAllActivityByIdQuery = { __typename?: 'Query', findAllActivityById: Array<{ __typename?: 'Activity', id: number, title: string }> };

export type FindAllClinicsByIdQueryVariables = Exact<{
  input: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type FindAllClinicsByIdQuery = { __typename?: 'Query', findAllClinicsById: Array<{ __typename?: 'Clinic', id: number, institutionName: string }> };

export type FindAllInteractionsQueryVariables = Exact<{
  input?: InputMaybe<FindAllInteractionsInput>;
}>;


export type FindAllInteractionsQuery = { __typename?: 'Query', findAllInteractions: { __typename?: 'FindAllInteractionsResponse', total: number, data?: Array<{ __typename?: 'Interactions', patientId: number, practitionerId: number, activityId: Array<number>, prescriptionId: Array<number>, prescriptionNumber: number, additionalNotes: string, createdAt: any, updatedAt: any }> | null } };

export type FindAllPrescriptionsQueryVariables = Exact<{
  input?: InputMaybe<FindAllPrescriptionsInput>;
}>;


export type FindAllPrescriptionsQuery = { __typename?: 'Query', findAllPrescriptions: { __typename?: 'FindAllPrescriptionsResponse', data?: Array<{ __typename?: 'Prescription', id: number, title: string, manufacturer?: string | null }> | null } };

export type FindAllPrescriptionsByIdQueryVariables = Exact<{
  input: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type FindAllPrescriptionsByIdQuery = { __typename?: 'Query', findAllPrescriptionsById: Array<{ __typename?: 'Prescription', id: number, title: string }> };

export type FindAllUsersQueryVariables = Exact<{
  input?: InputMaybe<FindAllUsersInput>;
}>;


export type FindAllUsersQuery = { __typename?: 'Query', findAllUsers?: { __typename?: 'FindAllUsersResponse', total: number, data?: Array<{ __typename?: 'User', id: number, phoneNumber?: string | null, profile?: { __typename?: 'Profile', firstName?: string | null, lastName?: string | null } | null }> | null } | null };

export type FindAllUsersByIdQueryVariables = Exact<{
  input: Array<Scalars['Float']> | Scalars['Float'];
}>;


export type FindAllUsersByIdQuery = { __typename?: 'Query', findAllUsersById: Array<{ __typename?: 'User', id: number, role: string, profile?: { __typename?: 'Profile', firstName?: string | null, lastName?: string | null } | null }> };

export type FindClinicPractitionerQueryVariables = Exact<{
  clinicId: Scalars['Float'];
}>;


export type FindClinicPractitionerQuery = { __typename?: 'Query', findClinicPractitioner: Array<{ __typename?: 'User', id: number, email?: string | null, phoneNumber?: string | null, profile?: { __typename?: 'Profile', firstName?: string | null, lastName?: string | null } | null }> };

export type FindInteractionByChwQueryVariables = Exact<{
  chwId: Scalars['Float'];
  clinicId?: InputMaybe<Scalars['Float']>;
}>;


export type FindInteractionByChwQuery = { __typename?: 'Query', findInteractionByCHW?: Array<{ __typename?: 'Interaction', id: number, prescriptionNumber: string, createdAt: any, updatedAt: any, patient: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, practitioner: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null } }> | null };

export type FindInteractionByChwByIdQueryVariables = Exact<{
  interactionId: Scalars['Float'];
  chwId: Scalars['Float'];
}>;


export type FindInteractionByChwByIdQuery = { __typename?: 'Query', findInteractionByCHWById?: { __typename?: 'Interaction', id: number, prescriptionId: Array<number>, activityId: Array<number>, prescriptionNumber: string, additionalNotes?: string | null, createdAt: any, updatedAt: any, patient: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, practitioner: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null } } | null };

export type FindInteractionByPractitionerQueryVariables = Exact<{
  practitionerId: Scalars['Float'];
  clinicId?: InputMaybe<Scalars['Float']>;
}>;


export type FindInteractionByPractitionerQuery = { __typename?: 'Query', findInteractionByPractitioner?: Array<{ __typename?: 'Interaction', id: number, prescriptionNumber: string, createdAt: any, updatedAt: any, patient: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, practitioner: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, chw: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null } }> | null };

export type FindInteractionByPractitionerByIdQueryVariables = Exact<{
  interactionId: Scalars['Float'];
  practitionerId: Scalars['Float'];
}>;


export type FindInteractionByPractitionerByIdQuery = { __typename?: 'Query', findInteractionByPractitionerById?: { __typename?: 'Interaction', id: number, prescriptionId: Array<number>, activityId: Array<number>, prescriptionNumber: string, additionalNotes?: string | null, createdAt: any, updatedAt: any, patient: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null }, chw: { __typename?: 'Profile', id: number, firstName?: string | null, lastName?: string | null } } | null };

export type FindRecentActivitiesQueryVariables = Exact<{
  role: Scalars['String'];
}>;


export type FindRecentActivitiesQuery = { __typename?: 'Query', findRecentActivities?: Array<{ __typename?: 'Activities', id: number, name: string, userId: number, type: string, role: string, reward: number, dataId: number, createdAt: any, updatedAt: any }> | null };

export type FindTaskByIdQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type FindTaskByIdQuery = { __typename?: 'Query', findTaskById?: { __typename?: 'Task', id: number, name: string, description: string, question?: Array<{ __typename?: 'Question', id: number, text: string, type: string, required: boolean, options: Array<string> }> | null } | null };

export type FindUserByPhoneNumberQueryVariables = Exact<{
  phoneNumber: Scalars['String'];
}>;


export type FindUserByPhoneNumberQuery = { __typename?: 'Query', findUserByPhoneNumber?: { __typename?: 'BaseUserResponse', id: number, status: string } | null };

export type GetCompletedTasksQueryVariables = Exact<{
  input: UserTaskActionInput;
}>;


export type GetCompletedTasksQuery = { __typename?: 'Query', getCompletedTasks: Array<{ __typename?: 'Task', id: number, name: string, reward: number, createdAt: any }> };

export type GetUnCompletedTasksQueryVariables = Exact<{
  input: UserTaskActionInput;
}>;


export type GetUnCompletedTasksQuery = { __typename?: 'Query', getUnCompletedTasks: Array<{ __typename?: 'Task', id: number, name: string, reward: number }> };

export type GetWalletQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWalletQuery = { __typename?: 'Query', getWallet: { __typename?: 'Wallet', id: number, address: string, balance: number, createdAt: any, updatedAt: any } };

export type ValidatePhoneQueryVariables = Exact<{
  input: ValidatePhoneInput;
}>;


export type ValidatePhoneQuery = { __typename?: 'Query', validatePhone: { __typename?: 'ValidatePhoneOutput', exists: boolean, user?: { __typename?: 'User', status: string, phoneNumber?: string | null } | null } };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI?: { __typename?: 'User', id: number, email?: string | null, phoneNumber?: string | null, status: string, role: string, clinicId?: Array<number> | null, linkedMinorAccount?: Array<number> | null, profile?: { __typename?: 'Profile', firstName?: string | null, lastName?: string | null, country?: { __typename?: 'Country', code: string, name: string } | null, currency?: { __typename?: 'Currency', code: string } | null } | null } | null };


export const ChangePhoneNumberDocument = gql`
    mutation changePhoneNumber($input: ChangePhoneNumberInput!) {
  changePhoneNumber(input: $input)
}
    `;
export type ChangePhoneNumberMutationFn = Apollo.MutationFunction<ChangePhoneNumberMutation, ChangePhoneNumberMutationVariables>;

/**
 * __useChangePhoneNumberMutation__
 *
 * To run a mutation, you first call `useChangePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePhoneNumberMutation, { data, loading, error }] = useChangePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePhoneNumberMutation(baseOptions?: Apollo.MutationHookOptions<ChangePhoneNumberMutation, ChangePhoneNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePhoneNumberMutation, ChangePhoneNumberMutationVariables>(ChangePhoneNumberDocument, options);
      }
export type ChangePhoneNumberMutationHookResult = ReturnType<typeof useChangePhoneNumberMutation>;
export type ChangePhoneNumberMutationResult = Apollo.MutationResult<ChangePhoneNumberMutation>;
export type ChangePhoneNumberMutationOptions = Apollo.BaseMutationOptions<ChangePhoneNumberMutation, ChangePhoneNumberMutationVariables>;
export const ChangePhoneNumberOtpDocument = gql`
    mutation changePhoneNumberOtp($input: ChangePhoneNumberOtpInput!) {
  changePhoneNumberOtp(input: $input)
}
    `;
export type ChangePhoneNumberOtpMutationFn = Apollo.MutationFunction<ChangePhoneNumberOtpMutation, ChangePhoneNumberOtpMutationVariables>;

/**
 * __useChangePhoneNumberOtpMutation__
 *
 * To run a mutation, you first call `useChangePhoneNumberOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePhoneNumberOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePhoneNumberOtpMutation, { data, loading, error }] = useChangePhoneNumberOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePhoneNumberOtpMutation(baseOptions?: Apollo.MutationHookOptions<ChangePhoneNumberOtpMutation, ChangePhoneNumberOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePhoneNumberOtpMutation, ChangePhoneNumberOtpMutationVariables>(ChangePhoneNumberOtpDocument, options);
      }
export type ChangePhoneNumberOtpMutationHookResult = ReturnType<typeof useChangePhoneNumberOtpMutation>;
export type ChangePhoneNumberOtpMutationResult = Apollo.MutationResult<ChangePhoneNumberOtpMutation>;
export type ChangePhoneNumberOtpMutationOptions = Apollo.BaseMutationOptions<ChangePhoneNumberOtpMutation, ChangePhoneNumberOtpMutationVariables>;
export const ChangePinDocument = gql`
    mutation changePin($input: ChangePinInput!) {
  changePin(input: $input)
}
    `;
export type ChangePinMutationFn = Apollo.MutationFunction<ChangePinMutation, ChangePinMutationVariables>;

/**
 * __useChangePinMutation__
 *
 * To run a mutation, you first call `useChangePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePinMutation, { data, loading, error }] = useChangePinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePinMutation(baseOptions?: Apollo.MutationHookOptions<ChangePinMutation, ChangePinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePinMutation, ChangePinMutationVariables>(ChangePinDocument, options);
      }
export type ChangePinMutationHookResult = ReturnType<typeof useChangePinMutation>;
export type ChangePinMutationResult = Apollo.MutationResult<ChangePinMutation>;
export type ChangePinMutationOptions = Apollo.BaseMutationOptions<ChangePinMutation, ChangePinMutationVariables>;
export const ChangePinOtpDocument = gql`
    mutation changePinOtp($input: ChangePinOtpInput!) {
  changePinOtp(input: $input)
}
    `;
export type ChangePinOtpMutationFn = Apollo.MutationFunction<ChangePinOtpMutation, ChangePinOtpMutationVariables>;

/**
 * __useChangePinOtpMutation__
 *
 * To run a mutation, you first call `useChangePinOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePinOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePinOtpMutation, { data, loading, error }] = useChangePinOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePinOtpMutation(baseOptions?: Apollo.MutationHookOptions<ChangePinOtpMutation, ChangePinOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePinOtpMutation, ChangePinOtpMutationVariables>(ChangePinOtpDocument, options);
      }
export type ChangePinOtpMutationHookResult = ReturnType<typeof useChangePinOtpMutation>;
export type ChangePinOtpMutationResult = Apollo.MutationResult<ChangePinOtpMutation>;
export type ChangePinOtpMutationOptions = Apollo.BaseMutationOptions<ChangePinOtpMutation, ChangePinOtpMutationVariables>;
export const CreateChwMinorDocument = gql`
    mutation createChwMinor($input: CreateChwMinorInput!) {
  createChwMinor(input: $input)
}
    `;
export type CreateChwMinorMutationFn = Apollo.MutationFunction<CreateChwMinorMutation, CreateChwMinorMutationVariables>;

/**
 * __useCreateChwMinorMutation__
 *
 * To run a mutation, you first call `useCreateChwMinorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChwMinorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChwMinorMutation, { data, loading, error }] = useCreateChwMinorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChwMinorMutation(baseOptions?: Apollo.MutationHookOptions<CreateChwMinorMutation, CreateChwMinorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChwMinorMutation, CreateChwMinorMutationVariables>(CreateChwMinorDocument, options);
      }
export type CreateChwMinorMutationHookResult = ReturnType<typeof useCreateChwMinorMutation>;
export type CreateChwMinorMutationResult = Apollo.MutationResult<CreateChwMinorMutation>;
export type CreateChwMinorMutationOptions = Apollo.BaseMutationOptions<CreateChwMinorMutation, CreateChwMinorMutationVariables>;
export const CreateChwPatientDocument = gql`
    mutation createChwPatient($input: CreateChwPatientInput!) {
  createChwPatient(input: $input)
}
    `;
export type CreateChwPatientMutationFn = Apollo.MutationFunction<CreateChwPatientMutation, CreateChwPatientMutationVariables>;

/**
 * __useCreateChwPatientMutation__
 *
 * To run a mutation, you first call `useCreateChwPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChwPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChwPatientMutation, { data, loading, error }] = useCreateChwPatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChwPatientMutation(baseOptions?: Apollo.MutationHookOptions<CreateChwPatientMutation, CreateChwPatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChwPatientMutation, CreateChwPatientMutationVariables>(CreateChwPatientDocument, options);
      }
export type CreateChwPatientMutationHookResult = ReturnType<typeof useCreateChwPatientMutation>;
export type CreateChwPatientMutationResult = Apollo.MutationResult<CreateChwPatientMutation>;
export type CreateChwPatientMutationOptions = Apollo.BaseMutationOptions<CreateChwPatientMutation, CreateChwPatientMutationVariables>;
export const CreateInteractionDocument = gql`
    mutation createInteraction($input: CreateInteractionInput!) {
  createInteraction(input: $input)
}
    `;
export type CreateInteractionMutationFn = Apollo.MutationFunction<CreateInteractionMutation, CreateInteractionMutationVariables>;

/**
 * __useCreateInteractionMutation__
 *
 * To run a mutation, you first call `useCreateInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInteractionMutation, { data, loading, error }] = useCreateInteractionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInteractionMutation(baseOptions?: Apollo.MutationHookOptions<CreateInteractionMutation, CreateInteractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInteractionMutation, CreateInteractionMutationVariables>(CreateInteractionDocument, options);
      }
export type CreateInteractionMutationHookResult = ReturnType<typeof useCreateInteractionMutation>;
export type CreateInteractionMutationResult = Apollo.MutationResult<CreateInteractionMutation>;
export type CreateInteractionMutationOptions = Apollo.BaseMutationOptions<CreateInteractionMutation, CreateInteractionMutationVariables>;
export const CreatePinDocument = gql`
    mutation createPin($input: CreatePinInput!) {
  createPin(input: $input) {
    id
    profile {
      id
      firstName
      lastName
      country {
        name
        code
      }
      currency {
        code
      }
    }
    role
    phoneNumber
    email
    status
    token
    clinicId
    linkedMinorAccount
  }
}
    `;
export type CreatePinMutationFn = Apollo.MutationFunction<CreatePinMutation, CreatePinMutationVariables>;

/**
 * __useCreatePinMutation__
 *
 * To run a mutation, you first call `useCreatePinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPinMutation, { data, loading, error }] = useCreatePinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePinMutation(baseOptions?: Apollo.MutationHookOptions<CreatePinMutation, CreatePinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePinMutation, CreatePinMutationVariables>(CreatePinDocument, options);
      }
export type CreatePinMutationHookResult = ReturnType<typeof useCreatePinMutation>;
export type CreatePinMutationResult = Apollo.MutationResult<CreatePinMutation>;
export type CreatePinMutationOptions = Apollo.BaseMutationOptions<CreatePinMutation, CreatePinMutationVariables>;
export const EditInteractionRequestDocument = gql`
    mutation editInteractionRequest($input: EditInteractionRequest!) {
  editInteractionRequest(input: $input)
}
    `;
export type EditInteractionRequestMutationFn = Apollo.MutationFunction<EditInteractionRequestMutation, EditInteractionRequestMutationVariables>;

/**
 * __useEditInteractionRequestMutation__
 *
 * To run a mutation, you first call `useEditInteractionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditInteractionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editInteractionRequestMutation, { data, loading, error }] = useEditInteractionRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditInteractionRequestMutation(baseOptions?: Apollo.MutationHookOptions<EditInteractionRequestMutation, EditInteractionRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditInteractionRequestMutation, EditInteractionRequestMutationVariables>(EditInteractionRequestDocument, options);
      }
export type EditInteractionRequestMutationHookResult = ReturnType<typeof useEditInteractionRequestMutation>;
export type EditInteractionRequestMutationResult = Apollo.MutationResult<EditInteractionRequestMutation>;
export type EditInteractionRequestMutationOptions = Apollo.BaseMutationOptions<EditInteractionRequestMutation, EditInteractionRequestMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: LoginInput!) {
  login(input: $input) {
    id
    profile {
      id
      firstName
      lastName
      country {
        name
        code
      }
      currency {
        code
      }
    }
    role
    phoneNumber
    email
    status
    token
    clinicId
    linkedMinorAccount
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPinDocument = gql`
    mutation resetPin($input: ResetPinInput!) {
  resetPin(input: $input)
}
    `;
export type ResetPinMutationFn = Apollo.MutationFunction<ResetPinMutation, ResetPinMutationVariables>;

/**
 * __useResetPinMutation__
 *
 * To run a mutation, you first call `useResetPinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPinMutation, { data, loading, error }] = useResetPinMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPinMutation(baseOptions?: Apollo.MutationHookOptions<ResetPinMutation, ResetPinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPinMutation, ResetPinMutationVariables>(ResetPinDocument, options);
      }
export type ResetPinMutationHookResult = ReturnType<typeof useResetPinMutation>;
export type ResetPinMutationResult = Apollo.MutationResult<ResetPinMutation>;
export type ResetPinMutationOptions = Apollo.BaseMutationOptions<ResetPinMutation, ResetPinMutationVariables>;
export const ResetPinOtpDocument = gql`
    mutation resetPinOtp($input: ResetPinOtpInput!) {
  resetPinOtp(input: $input)
}
    `;
export type ResetPinOtpMutationFn = Apollo.MutationFunction<ResetPinOtpMutation, ResetPinOtpMutationVariables>;

/**
 * __useResetPinOtpMutation__
 *
 * To run a mutation, you first call `useResetPinOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPinOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPinOtpMutation, { data, loading, error }] = useResetPinOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPinOtpMutation(baseOptions?: Apollo.MutationHookOptions<ResetPinOtpMutation, ResetPinOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPinOtpMutation, ResetPinOtpMutationVariables>(ResetPinOtpDocument, options);
      }
export type ResetPinOtpMutationHookResult = ReturnType<typeof useResetPinOtpMutation>;
export type ResetPinOtpMutationResult = Apollo.MutationResult<ResetPinOtpMutation>;
export type ResetPinOtpMutationOptions = Apollo.BaseMutationOptions<ResetPinOtpMutation, ResetPinOtpMutationVariables>;
export const CreatePatientDocument = gql`
    mutation createPatient($input: CreatePatientInput!) {
  createPatient(input: $input)
}
    `;
export type CreatePatientMutationFn = Apollo.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: Apollo.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, options);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const SubmitTaskDocument = gql`
    mutation submitTask($input: SubmitTasksInput!) {
  submitTask(input: $input)
}
    `;
export type SubmitTaskMutationFn = Apollo.MutationFunction<SubmitTaskMutation, SubmitTaskMutationVariables>;

/**
 * __useSubmitTaskMutation__
 *
 * To run a mutation, you first call `useSubmitTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTaskMutation, { data, loading, error }] = useSubmitTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitTaskMutation(baseOptions?: Apollo.MutationHookOptions<SubmitTaskMutation, SubmitTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitTaskMutation, SubmitTaskMutationVariables>(SubmitTaskDocument, options);
      }
export type SubmitTaskMutationHookResult = ReturnType<typeof useSubmitTaskMutation>;
export type SubmitTaskMutationResult = Apollo.MutationResult<SubmitTaskMutation>;
export type SubmitTaskMutationOptions = Apollo.BaseMutationOptions<SubmitTaskMutation, SubmitTaskMutationVariables>;
export const UpdateInteractionDocument = gql`
    mutation updateInteraction($input: UpdateInteractionInput!, $id: Float!) {
  updateInteraction(input: $input, id: $id)
}
    `;
export type UpdateInteractionMutationFn = Apollo.MutationFunction<UpdateInteractionMutation, UpdateInteractionMutationVariables>;

/**
 * __useUpdateInteractionMutation__
 *
 * To run a mutation, you first call `useUpdateInteractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteractionMutation, { data, loading, error }] = useUpdateInteractionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateInteractionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInteractionMutation, UpdateInteractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInteractionMutation, UpdateInteractionMutationVariables>(UpdateInteractionDocument, options);
      }
export type UpdateInteractionMutationHookResult = ReturnType<typeof useUpdateInteractionMutation>;
export type UpdateInteractionMutationResult = Apollo.MutationResult<UpdateInteractionMutation>;
export type UpdateInteractionMutationOptions = Apollo.BaseMutationOptions<UpdateInteractionMutation, UpdateInteractionMutationVariables>;
export const ValidateOtpDocument = gql`
    mutation validateOtp($input: OtpValidationInput!) {
  validateOtp(input: $input)
}
    `;
export type ValidateOtpMutationFn = Apollo.MutationFunction<ValidateOtpMutation, ValidateOtpMutationVariables>;

/**
 * __useValidateOtpMutation__
 *
 * To run a mutation, you first call `useValidateOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateOtpMutation, { data, loading, error }] = useValidateOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateOtpMutation(baseOptions?: Apollo.MutationHookOptions<ValidateOtpMutation, ValidateOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateOtpMutation, ValidateOtpMutationVariables>(ValidateOtpDocument, options);
      }
export type ValidateOtpMutationHookResult = ReturnType<typeof useValidateOtpMutation>;
export type ValidateOtpMutationResult = Apollo.MutationResult<ValidateOtpMutation>;
export type ValidateOtpMutationOptions = Apollo.BaseMutationOptions<ValidateOtpMutation, ValidateOtpMutationVariables>;
export const ValidateAuthOtpDocument = gql`
    mutation validateAuthOtp($input: ValidateOTPInput!) {
  validateAuthOtp(input: $input)
}
    `;
export type ValidateAuthOtpMutationFn = Apollo.MutationFunction<ValidateAuthOtpMutation, ValidateAuthOtpMutationVariables>;

/**
 * __useValidateAuthOtpMutation__
 *
 * To run a mutation, you first call `useValidateAuthOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateAuthOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateAuthOtpMutation, { data, loading, error }] = useValidateAuthOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidateAuthOtpMutation(baseOptions?: Apollo.MutationHookOptions<ValidateAuthOtpMutation, ValidateAuthOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateAuthOtpMutation, ValidateAuthOtpMutationVariables>(ValidateAuthOtpDocument, options);
      }
export type ValidateAuthOtpMutationHookResult = ReturnType<typeof useValidateAuthOtpMutation>;
export type ValidateAuthOtpMutationResult = Apollo.MutationResult<ValidateAuthOtpMutation>;
export type ValidateAuthOtpMutationOptions = Apollo.BaseMutationOptions<ValidateAuthOtpMutation, ValidateAuthOtpMutationVariables>;
export const FindAllActivitiesDocument = gql`
    query findAllActivities($input: FindAllActivitiesInput) {
  findAllActivities(input: $input) {
    data {
      id
      title
      reward
    }
  }
}
    `;

/**
 * __useFindAllActivitiesQuery__
 *
 * To run a query within a React component, call `useFindAllActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllActivitiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<FindAllActivitiesQuery, FindAllActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllActivitiesQuery, FindAllActivitiesQueryVariables>(FindAllActivitiesDocument, options);
      }
export function useFindAllActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllActivitiesQuery, FindAllActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllActivitiesQuery, FindAllActivitiesQueryVariables>(FindAllActivitiesDocument, options);
        }
export type FindAllActivitiesQueryHookResult = ReturnType<typeof useFindAllActivitiesQuery>;
export type FindAllActivitiesLazyQueryHookResult = ReturnType<typeof useFindAllActivitiesLazyQuery>;
export type FindAllActivitiesQueryResult = Apollo.QueryResult<FindAllActivitiesQuery, FindAllActivitiesQueryVariables>;
export const FindAllActivityByIdDocument = gql`
    query findAllActivityById($input: [Float!]!) {
  findAllActivityById(input: $input) {
    id
    title
  }
}
    `;

/**
 * __useFindAllActivityByIdQuery__
 *
 * To run a query within a React component, call `useFindAllActivityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllActivityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllActivityByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllActivityByIdQuery(baseOptions: Apollo.QueryHookOptions<FindAllActivityByIdQuery, FindAllActivityByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllActivityByIdQuery, FindAllActivityByIdQueryVariables>(FindAllActivityByIdDocument, options);
      }
export function useFindAllActivityByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllActivityByIdQuery, FindAllActivityByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllActivityByIdQuery, FindAllActivityByIdQueryVariables>(FindAllActivityByIdDocument, options);
        }
export type FindAllActivityByIdQueryHookResult = ReturnType<typeof useFindAllActivityByIdQuery>;
export type FindAllActivityByIdLazyQueryHookResult = ReturnType<typeof useFindAllActivityByIdLazyQuery>;
export type FindAllActivityByIdQueryResult = Apollo.QueryResult<FindAllActivityByIdQuery, FindAllActivityByIdQueryVariables>;
export const FindAllClinicsByIdDocument = gql`
    query findAllClinicsById($input: [Float!]!) {
  findAllClinicsById(input: $input) {
    id
    institutionName
  }
}
    `;

/**
 * __useFindAllClinicsByIdQuery__
 *
 * To run a query within a React component, call `useFindAllClinicsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllClinicsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllClinicsByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllClinicsByIdQuery(baseOptions: Apollo.QueryHookOptions<FindAllClinicsByIdQuery, FindAllClinicsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllClinicsByIdQuery, FindAllClinicsByIdQueryVariables>(FindAllClinicsByIdDocument, options);
      }
export function useFindAllClinicsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllClinicsByIdQuery, FindAllClinicsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllClinicsByIdQuery, FindAllClinicsByIdQueryVariables>(FindAllClinicsByIdDocument, options);
        }
export type FindAllClinicsByIdQueryHookResult = ReturnType<typeof useFindAllClinicsByIdQuery>;
export type FindAllClinicsByIdLazyQueryHookResult = ReturnType<typeof useFindAllClinicsByIdLazyQuery>;
export type FindAllClinicsByIdQueryResult = Apollo.QueryResult<FindAllClinicsByIdQuery, FindAllClinicsByIdQueryVariables>;
export const FindAllInteractionsDocument = gql`
    query findAllInteractions($input: FindAllInteractionsInput) {
  findAllInteractions(input: $input) {
    total
    data {
      patientId
      practitionerId
      activityId
      prescriptionId
      prescriptionNumber
      additionalNotes
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useFindAllInteractionsQuery__
 *
 * To run a query within a React component, call `useFindAllInteractionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllInteractionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllInteractionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllInteractionsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllInteractionsQuery, FindAllInteractionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllInteractionsQuery, FindAllInteractionsQueryVariables>(FindAllInteractionsDocument, options);
      }
export function useFindAllInteractionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllInteractionsQuery, FindAllInteractionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllInteractionsQuery, FindAllInteractionsQueryVariables>(FindAllInteractionsDocument, options);
        }
export type FindAllInteractionsQueryHookResult = ReturnType<typeof useFindAllInteractionsQuery>;
export type FindAllInteractionsLazyQueryHookResult = ReturnType<typeof useFindAllInteractionsLazyQuery>;
export type FindAllInteractionsQueryResult = Apollo.QueryResult<FindAllInteractionsQuery, FindAllInteractionsQueryVariables>;
export const FindAllPrescriptionsDocument = gql`
    query findAllPrescriptions($input: FindAllPrescriptionsInput) {
  findAllPrescriptions(input: $input) {
    data {
      id
      title
      manufacturer
    }
  }
}
    `;

/**
 * __useFindAllPrescriptionsQuery__
 *
 * To run a query within a React component, call `useFindAllPrescriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPrescriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPrescriptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllPrescriptionsQuery(baseOptions?: Apollo.QueryHookOptions<FindAllPrescriptionsQuery, FindAllPrescriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllPrescriptionsQuery, FindAllPrescriptionsQueryVariables>(FindAllPrescriptionsDocument, options);
      }
export function useFindAllPrescriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllPrescriptionsQuery, FindAllPrescriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllPrescriptionsQuery, FindAllPrescriptionsQueryVariables>(FindAllPrescriptionsDocument, options);
        }
export type FindAllPrescriptionsQueryHookResult = ReturnType<typeof useFindAllPrescriptionsQuery>;
export type FindAllPrescriptionsLazyQueryHookResult = ReturnType<typeof useFindAllPrescriptionsLazyQuery>;
export type FindAllPrescriptionsQueryResult = Apollo.QueryResult<FindAllPrescriptionsQuery, FindAllPrescriptionsQueryVariables>;
export const FindAllPrescriptionsByIdDocument = gql`
    query findAllPrescriptionsById($input: [Float!]!) {
  findAllPrescriptionsById(input: $input) {
    id
    title
  }
}
    `;

/**
 * __useFindAllPrescriptionsByIdQuery__
 *
 * To run a query within a React component, call `useFindAllPrescriptionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPrescriptionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPrescriptionsByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllPrescriptionsByIdQuery(baseOptions: Apollo.QueryHookOptions<FindAllPrescriptionsByIdQuery, FindAllPrescriptionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllPrescriptionsByIdQuery, FindAllPrescriptionsByIdQueryVariables>(FindAllPrescriptionsByIdDocument, options);
      }
export function useFindAllPrescriptionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllPrescriptionsByIdQuery, FindAllPrescriptionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllPrescriptionsByIdQuery, FindAllPrescriptionsByIdQueryVariables>(FindAllPrescriptionsByIdDocument, options);
        }
export type FindAllPrescriptionsByIdQueryHookResult = ReturnType<typeof useFindAllPrescriptionsByIdQuery>;
export type FindAllPrescriptionsByIdLazyQueryHookResult = ReturnType<typeof useFindAllPrescriptionsByIdLazyQuery>;
export type FindAllPrescriptionsByIdQueryResult = Apollo.QueryResult<FindAllPrescriptionsByIdQuery, FindAllPrescriptionsByIdQueryVariables>;
export const FindAllUsersDocument = gql`
    query findAllUsers($input: FindAllUsersInput) {
  findAllUsers(input: $input) {
    total
    data {
      id
      phoneNumber
      profile {
        firstName
        lastName
      }
    }
  }
}
    `;

/**
 * __useFindAllUsersQuery__
 *
 * To run a query within a React component, call `useFindAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(FindAllUsersDocument, options);
      }
export function useFindAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(FindAllUsersDocument, options);
        }
export type FindAllUsersQueryHookResult = ReturnType<typeof useFindAllUsersQuery>;
export type FindAllUsersLazyQueryHookResult = ReturnType<typeof useFindAllUsersLazyQuery>;
export type FindAllUsersQueryResult = Apollo.QueryResult<FindAllUsersQuery, FindAllUsersQueryVariables>;
export const FindAllUsersByIdDocument = gql`
    query findAllUsersById($input: [Float!]!) {
  findAllUsersById(input: $input) {
    id
    profile {
      firstName
      lastName
    }
    role
  }
}
    `;

/**
 * __useFindAllUsersByIdQuery__
 *
 * To run a query within a React component, call `useFindAllUsersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllUsersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllUsersByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFindAllUsersByIdQuery(baseOptions: Apollo.QueryHookOptions<FindAllUsersByIdQuery, FindAllUsersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllUsersByIdQuery, FindAllUsersByIdQueryVariables>(FindAllUsersByIdDocument, options);
      }
export function useFindAllUsersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllUsersByIdQuery, FindAllUsersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllUsersByIdQuery, FindAllUsersByIdQueryVariables>(FindAllUsersByIdDocument, options);
        }
export type FindAllUsersByIdQueryHookResult = ReturnType<typeof useFindAllUsersByIdQuery>;
export type FindAllUsersByIdLazyQueryHookResult = ReturnType<typeof useFindAllUsersByIdLazyQuery>;
export type FindAllUsersByIdQueryResult = Apollo.QueryResult<FindAllUsersByIdQuery, FindAllUsersByIdQueryVariables>;
export const FindClinicPractitionerDocument = gql`
    query findClinicPractitioner($clinicId: Float!) {
  findClinicPractitioner(clinicId: $clinicId) {
    id
    email
    phoneNumber
    profile {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useFindClinicPractitionerQuery__
 *
 * To run a query within a React component, call `useFindClinicPractitionerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClinicPractitionerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClinicPractitionerQuery({
 *   variables: {
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useFindClinicPractitionerQuery(baseOptions: Apollo.QueryHookOptions<FindClinicPractitionerQuery, FindClinicPractitionerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindClinicPractitionerQuery, FindClinicPractitionerQueryVariables>(FindClinicPractitionerDocument, options);
      }
export function useFindClinicPractitionerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindClinicPractitionerQuery, FindClinicPractitionerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindClinicPractitionerQuery, FindClinicPractitionerQueryVariables>(FindClinicPractitionerDocument, options);
        }
export type FindClinicPractitionerQueryHookResult = ReturnType<typeof useFindClinicPractitionerQuery>;
export type FindClinicPractitionerLazyQueryHookResult = ReturnType<typeof useFindClinicPractitionerLazyQuery>;
export type FindClinicPractitionerQueryResult = Apollo.QueryResult<FindClinicPractitionerQuery, FindClinicPractitionerQueryVariables>;
export const FindInteractionByChwDocument = gql`
    query findInteractionByCHW($chwId: Float!, $clinicId: Float) {
  findInteractionByCHW(chwId: $chwId, clinicId: $clinicId) {
    id
    patient {
      id
      firstName
      lastName
    }
    practitioner {
      id
      firstName
      lastName
    }
    prescriptionNumber
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindInteractionByChwQuery__
 *
 * To run a query within a React component, call `useFindInteractionByChwQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInteractionByChwQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInteractionByChwQuery({
 *   variables: {
 *      chwId: // value for 'chwId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useFindInteractionByChwQuery(baseOptions: Apollo.QueryHookOptions<FindInteractionByChwQuery, FindInteractionByChwQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindInteractionByChwQuery, FindInteractionByChwQueryVariables>(FindInteractionByChwDocument, options);
      }
export function useFindInteractionByChwLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindInteractionByChwQuery, FindInteractionByChwQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindInteractionByChwQuery, FindInteractionByChwQueryVariables>(FindInteractionByChwDocument, options);
        }
export type FindInteractionByChwQueryHookResult = ReturnType<typeof useFindInteractionByChwQuery>;
export type FindInteractionByChwLazyQueryHookResult = ReturnType<typeof useFindInteractionByChwLazyQuery>;
export type FindInteractionByChwQueryResult = Apollo.QueryResult<FindInteractionByChwQuery, FindInteractionByChwQueryVariables>;
export const FindInteractionByChwByIdDocument = gql`
    query findInteractionByCHWById($interactionId: Float!, $chwId: Float!) {
  findInteractionByCHWById(interactionId: $interactionId, chwId: $chwId) {
    id
    patient {
      id
      firstName
      lastName
    }
    practitioner {
      id
      firstName
      lastName
    }
    prescriptionId
    activityId
    prescriptionNumber
    additionalNotes
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindInteractionByChwByIdQuery__
 *
 * To run a query within a React component, call `useFindInteractionByChwByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInteractionByChwByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInteractionByChwByIdQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *      chwId: // value for 'chwId'
 *   },
 * });
 */
export function useFindInteractionByChwByIdQuery(baseOptions: Apollo.QueryHookOptions<FindInteractionByChwByIdQuery, FindInteractionByChwByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindInteractionByChwByIdQuery, FindInteractionByChwByIdQueryVariables>(FindInteractionByChwByIdDocument, options);
      }
export function useFindInteractionByChwByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindInteractionByChwByIdQuery, FindInteractionByChwByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindInteractionByChwByIdQuery, FindInteractionByChwByIdQueryVariables>(FindInteractionByChwByIdDocument, options);
        }
export type FindInteractionByChwByIdQueryHookResult = ReturnType<typeof useFindInteractionByChwByIdQuery>;
export type FindInteractionByChwByIdLazyQueryHookResult = ReturnType<typeof useFindInteractionByChwByIdLazyQuery>;
export type FindInteractionByChwByIdQueryResult = Apollo.QueryResult<FindInteractionByChwByIdQuery, FindInteractionByChwByIdQueryVariables>;
export const FindInteractionByPractitionerDocument = gql`
    query findInteractionByPractitioner($practitionerId: Float!, $clinicId: Float) {
  findInteractionByPractitioner(
    practitionerId: $practitionerId
    clinicId: $clinicId
  ) {
    id
    patient {
      id
      firstName
      lastName
    }
    practitioner {
      id
      firstName
      lastName
    }
    chw {
      id
      firstName
      lastName
    }
    prescriptionNumber
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindInteractionByPractitionerQuery__
 *
 * To run a query within a React component, call `useFindInteractionByPractitionerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInteractionByPractitionerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInteractionByPractitionerQuery({
 *   variables: {
 *      practitionerId: // value for 'practitionerId'
 *      clinicId: // value for 'clinicId'
 *   },
 * });
 */
export function useFindInteractionByPractitionerQuery(baseOptions: Apollo.QueryHookOptions<FindInteractionByPractitionerQuery, FindInteractionByPractitionerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindInteractionByPractitionerQuery, FindInteractionByPractitionerQueryVariables>(FindInteractionByPractitionerDocument, options);
      }
export function useFindInteractionByPractitionerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindInteractionByPractitionerQuery, FindInteractionByPractitionerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindInteractionByPractitionerQuery, FindInteractionByPractitionerQueryVariables>(FindInteractionByPractitionerDocument, options);
        }
export type FindInteractionByPractitionerQueryHookResult = ReturnType<typeof useFindInteractionByPractitionerQuery>;
export type FindInteractionByPractitionerLazyQueryHookResult = ReturnType<typeof useFindInteractionByPractitionerLazyQuery>;
export type FindInteractionByPractitionerQueryResult = Apollo.QueryResult<FindInteractionByPractitionerQuery, FindInteractionByPractitionerQueryVariables>;
export const FindInteractionByPractitionerByIdDocument = gql`
    query findInteractionByPractitionerById($interactionId: Float!, $practitionerId: Float!) {
  findInteractionByPractitionerById(
    interactionId: $interactionId
    practitionerId: $practitionerId
  ) {
    id
    patient {
      id
      firstName
      lastName
    }
    chw {
      id
      firstName
      lastName
    }
    prescriptionId
    activityId
    prescriptionNumber
    additionalNotes
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindInteractionByPractitionerByIdQuery__
 *
 * To run a query within a React component, call `useFindInteractionByPractitionerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInteractionByPractitionerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInteractionByPractitionerByIdQuery({
 *   variables: {
 *      interactionId: // value for 'interactionId'
 *      practitionerId: // value for 'practitionerId'
 *   },
 * });
 */
export function useFindInteractionByPractitionerByIdQuery(baseOptions: Apollo.QueryHookOptions<FindInteractionByPractitionerByIdQuery, FindInteractionByPractitionerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindInteractionByPractitionerByIdQuery, FindInteractionByPractitionerByIdQueryVariables>(FindInteractionByPractitionerByIdDocument, options);
      }
export function useFindInteractionByPractitionerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindInteractionByPractitionerByIdQuery, FindInteractionByPractitionerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindInteractionByPractitionerByIdQuery, FindInteractionByPractitionerByIdQueryVariables>(FindInteractionByPractitionerByIdDocument, options);
        }
export type FindInteractionByPractitionerByIdQueryHookResult = ReturnType<typeof useFindInteractionByPractitionerByIdQuery>;
export type FindInteractionByPractitionerByIdLazyQueryHookResult = ReturnType<typeof useFindInteractionByPractitionerByIdLazyQuery>;
export type FindInteractionByPractitionerByIdQueryResult = Apollo.QueryResult<FindInteractionByPractitionerByIdQuery, FindInteractionByPractitionerByIdQueryVariables>;
export const FindRecentActivitiesDocument = gql`
    query findRecentActivities($role: String!) {
  findRecentActivities(role: $role) {
    id
    name
    userId
    type
    role
    reward
    dataId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFindRecentActivitiesQuery__
 *
 * To run a query within a React component, call `useFindRecentActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRecentActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRecentActivitiesQuery({
 *   variables: {
 *      role: // value for 'role'
 *   },
 * });
 */
export function useFindRecentActivitiesQuery(baseOptions: Apollo.QueryHookOptions<FindRecentActivitiesQuery, FindRecentActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindRecentActivitiesQuery, FindRecentActivitiesQueryVariables>(FindRecentActivitiesDocument, options);
      }
export function useFindRecentActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindRecentActivitiesQuery, FindRecentActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindRecentActivitiesQuery, FindRecentActivitiesQueryVariables>(FindRecentActivitiesDocument, options);
        }
export type FindRecentActivitiesQueryHookResult = ReturnType<typeof useFindRecentActivitiesQuery>;
export type FindRecentActivitiesLazyQueryHookResult = ReturnType<typeof useFindRecentActivitiesLazyQuery>;
export type FindRecentActivitiesQueryResult = Apollo.QueryResult<FindRecentActivitiesQuery, FindRecentActivitiesQueryVariables>;
export const FindTaskByIdDocument = gql`
    query findTaskById($id: Float!) {
  findTaskById(id: $id) {
    id
    name
    description
    question {
      id
      text
      type
      required
      options
    }
  }
}
    `;

/**
 * __useFindTaskByIdQuery__
 *
 * To run a query within a React component, call `useFindTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindTaskByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindTaskByIdQuery(baseOptions: Apollo.QueryHookOptions<FindTaskByIdQuery, FindTaskByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindTaskByIdQuery, FindTaskByIdQueryVariables>(FindTaskByIdDocument, options);
      }
export function useFindTaskByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindTaskByIdQuery, FindTaskByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindTaskByIdQuery, FindTaskByIdQueryVariables>(FindTaskByIdDocument, options);
        }
export type FindTaskByIdQueryHookResult = ReturnType<typeof useFindTaskByIdQuery>;
export type FindTaskByIdLazyQueryHookResult = ReturnType<typeof useFindTaskByIdLazyQuery>;
export type FindTaskByIdQueryResult = Apollo.QueryResult<FindTaskByIdQuery, FindTaskByIdQueryVariables>;
export const FindUserByPhoneNumberDocument = gql`
    query findUserByPhoneNumber($phoneNumber: String!) {
  findUserByPhoneNumber(phoneNumber: $phoneNumber) {
    id
    status
  }
}
    `;

/**
 * __useFindUserByPhoneNumberQuery__
 *
 * To run a query within a React component, call `useFindUserByPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserByPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserByPhoneNumberQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useFindUserByPhoneNumberQuery(baseOptions: Apollo.QueryHookOptions<FindUserByPhoneNumberQuery, FindUserByPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserByPhoneNumberQuery, FindUserByPhoneNumberQueryVariables>(FindUserByPhoneNumberDocument, options);
      }
export function useFindUserByPhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserByPhoneNumberQuery, FindUserByPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserByPhoneNumberQuery, FindUserByPhoneNumberQueryVariables>(FindUserByPhoneNumberDocument, options);
        }
export type FindUserByPhoneNumberQueryHookResult = ReturnType<typeof useFindUserByPhoneNumberQuery>;
export type FindUserByPhoneNumberLazyQueryHookResult = ReturnType<typeof useFindUserByPhoneNumberLazyQuery>;
export type FindUserByPhoneNumberQueryResult = Apollo.QueryResult<FindUserByPhoneNumberQuery, FindUserByPhoneNumberQueryVariables>;
export const GetCompletedTasksDocument = gql`
    query getCompletedTasks($input: UserTaskActionInput!) {
  getCompletedTasks(input: $input) {
    id
    name
    reward
    createdAt
  }
}
    `;

/**
 * __useGetCompletedTasksQuery__
 *
 * To run a query within a React component, call `useGetCompletedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCompletedTasksQuery(baseOptions: Apollo.QueryHookOptions<GetCompletedTasksQuery, GetCompletedTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompletedTasksQuery, GetCompletedTasksQueryVariables>(GetCompletedTasksDocument, options);
      }
export function useGetCompletedTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedTasksQuery, GetCompletedTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompletedTasksQuery, GetCompletedTasksQueryVariables>(GetCompletedTasksDocument, options);
        }
export type GetCompletedTasksQueryHookResult = ReturnType<typeof useGetCompletedTasksQuery>;
export type GetCompletedTasksLazyQueryHookResult = ReturnType<typeof useGetCompletedTasksLazyQuery>;
export type GetCompletedTasksQueryResult = Apollo.QueryResult<GetCompletedTasksQuery, GetCompletedTasksQueryVariables>;
export const GetUnCompletedTasksDocument = gql`
    query getUnCompletedTasks($input: UserTaskActionInput!) {
  getUnCompletedTasks(input: $input) {
    id
    name
    reward
  }
}
    `;

/**
 * __useGetUnCompletedTasksQuery__
 *
 * To run a query within a React component, call `useGetUnCompletedTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnCompletedTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnCompletedTasksQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUnCompletedTasksQuery(baseOptions: Apollo.QueryHookOptions<GetUnCompletedTasksQuery, GetUnCompletedTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnCompletedTasksQuery, GetUnCompletedTasksQueryVariables>(GetUnCompletedTasksDocument, options);
      }
export function useGetUnCompletedTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnCompletedTasksQuery, GetUnCompletedTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnCompletedTasksQuery, GetUnCompletedTasksQueryVariables>(GetUnCompletedTasksDocument, options);
        }
export type GetUnCompletedTasksQueryHookResult = ReturnType<typeof useGetUnCompletedTasksQuery>;
export type GetUnCompletedTasksLazyQueryHookResult = ReturnType<typeof useGetUnCompletedTasksLazyQuery>;
export type GetUnCompletedTasksQueryResult = Apollo.QueryResult<GetUnCompletedTasksQuery, GetUnCompletedTasksQueryVariables>;
export const GetWalletDocument = gql`
    query getWallet {
  getWallet {
    id
    address
    balance
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useGetWalletQuery__
 *
 * To run a query within a React component, call `useGetWalletQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWalletQuery(baseOptions?: Apollo.QueryHookOptions<GetWalletQuery, GetWalletQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalletQuery, GetWalletQueryVariables>(GetWalletDocument, options);
      }
export function useGetWalletLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalletQuery, GetWalletQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalletQuery, GetWalletQueryVariables>(GetWalletDocument, options);
        }
export type GetWalletQueryHookResult = ReturnType<typeof useGetWalletQuery>;
export type GetWalletLazyQueryHookResult = ReturnType<typeof useGetWalletLazyQuery>;
export type GetWalletQueryResult = Apollo.QueryResult<GetWalletQuery, GetWalletQueryVariables>;
export const ValidatePhoneDocument = gql`
    query validatePhone($input: ValidatePhoneInput!) {
  validatePhone(input: $input) {
    exists
    user {
      status
      phoneNumber
    }
  }
}
    `;

/**
 * __useValidatePhoneQuery__
 *
 * To run a query within a React component, call `useValidatePhoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidatePhoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidatePhoneQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidatePhoneQuery(baseOptions: Apollo.QueryHookOptions<ValidatePhoneQuery, ValidatePhoneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidatePhoneQuery, ValidatePhoneQueryVariables>(ValidatePhoneDocument, options);
      }
export function useValidatePhoneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidatePhoneQuery, ValidatePhoneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidatePhoneQuery, ValidatePhoneQueryVariables>(ValidatePhoneDocument, options);
        }
export type ValidatePhoneQueryHookResult = ReturnType<typeof useValidatePhoneQuery>;
export type ValidatePhoneLazyQueryHookResult = ReturnType<typeof useValidatePhoneLazyQuery>;
export type ValidatePhoneQueryResult = Apollo.QueryResult<ValidatePhoneQuery, ValidatePhoneQueryVariables>;
export const WhoAmIDocument = gql`
    query whoAmI {
  whoAmI {
    id
    email
    phoneNumber
    status
    role
    profile {
      firstName
      lastName
      country {
        code
        name
      }
      currency {
        code
      }
    }
    clinicId
    linkedMinorAccount
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;