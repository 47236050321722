import React, { useState, useRef } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdOutlineArrowBack } from "react-icons/md";
import userVal from "vars/user";
import Input from "components/input";
import VerifyCurrentPin from "components/Settings/ChangePin/VerifyCurrentPin";
import VerifyEmailPin from "components/Settings/ChangePin/VerifyEmailPin";
import VerifyOtpPin from "components/Settings/ChangePin/VerifyOtpPin";
import VerifyNewPin from "components/Settings/ChangePin/VerifyNewPin";
import ConfirmPinChange from "components/Settings/ChangePin/ConfirmPinChange";
import { Portal } from "@chakra-ui/react";

const Background = styled.div`
  position: fixed;
  max-width: 500px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
  /* z-index: 10000000000000; */
  margin: auto;
`;

const ModalContents = styled.div`
  height: 500px;
  border-radius: 24px 24px 0px 0px;

  button {
    border-radius: 24px;
  }

  .line {
    height: 5px;
    width: 30px;
    background: #b3b6ba;
  }

  .title {
    color: #4285f4;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1.8rem;
  }
`;

const DropDownPage = styled.div`
  .dropdown {
    width: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    position: relative;
    user-select: none;
  }

  .dropdown .dropdown-btn {
    /* background: #F7FAFF; */
    padding: 0.875rem 1.06rem;
    font-size: 1rem;
    cursor: pointer;
    color: #b3b6ba;
  }

  .dropdown .dropdown-content {
    /* padding: 15px; */
    background: #f7faff;
    font-size: 0.875rem;
    box-sizing: border-box;
    margin-top: 0.3rem;
  }

  .dropdown .dropdown-content .dropdown-item {
    padding: 1rem;
    cursor: pointer;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputField = styled.div``;

const Modals = ({ changePin, setChangePin }) => {
  const [steps, setStep] = useState(1);
  const [currentPin, setcurrentPin] = useState("");
  const [email, setemail] = useState("");
  const [otp, setotp] = useState("");
  const [newPin, setNewPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pin, setPin] = useState("");

  const handleSteps = () => {
    if (steps > 0 && steps < 5) {
      setStep((prev) => prev + 1);
    }
  };
  const handleBack = () => {
    if (steps > 1 && steps <= 5) {
      setStep((prev) => prev - 1);
    } else {
      setChangePin(false);
    }
  };

  const setModal = useRef();

  const closeModal = (e) => {
    if (setModal.current === e.target) {
      setChangePin(false);
    }
  };

  const animate = useSpring({
    config: {
      duration: 250,
    },
    opacity: changePin ? 1 : 0,
    transform: changePin ? `translateY(0%)` : `translateY(100%)`,
  });

  return (
    <>
      {changePin && (
        <Portal>
          <Background ref={setModal} onClick={closeModal}>
            
            <ModalWrapper changePin={changePin}>
              <animated.div style={animate}>
                <ModalContents className="p-4 bg-white dark:bg-[#343B46]">
                  <div className="flex mt-2 justify-center mb-2.5">
                    <div className="line"></div>
                  </div>

                  <div className="flex items-center justify-between px-2 mb-4">
                    <div className="items-center" onClick={handleBack}>
                      <MdOutlineArrowBack size={36} color="#4285F4" />
                    </div>
                    <div className="title mr-4">Change pin</div>
                    <div></div>
                  </div>

                  <InputField>
                    {steps === 1 && (
                      <VerifyCurrentPin
                        handleSteps={handleSteps}
                        currentPin={currentPin}
                        setcurrentPin={setcurrentPin}
                      />
                    )}

                    {steps === 2 && (
                      <VerifyEmailPin
                        email={email}
                        setemail={setemail}
                        currentPin={currentPin}
                        handleSteps={handleSteps}
                      />
                    )}

                    {steps === 3 && (
                      <VerifyOtpPin
                        otp={otp}
                        setotp={setotp}
                        currentPin={currentPin}
                        handleSteps={handleSteps}
                        email={email}
                        currentPhone={userVal()?.phoneNumber}
                      />
                    )}

                    {steps === 4 && (
                      <VerifyNewPin
                        newPin={newPin}
                        setNewPin={setNewPin}
                        confirmPin={confirmPin}
                        setConfirmPin={setConfirmPin}
                        handleSteps={handleSteps}
                      />
                    )}

                    {steps === 5 && (
                      <ConfirmPinChange
                        pin={pin}
                        setPin={setPin}
                        userId={userVal()?.id}
                        otp={otp}
                        newPin={newPin}
                        confirmPin={confirmPin}
                      />
                    )}
                  </InputField>
                </ModalContents>
              </animated.div>
            </ModalWrapper>
          </Background>
        </Portal>
      )}
    </>
  );
};

export default Modals;
