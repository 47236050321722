import React, { useState } from "react";
import Input from "components/input";

function VerifyNewPin({
  handleSteps,
  newPin,
  confirmPin,
  setNewPin,
  setConfirmPin
}) {
  const [pinError, setPinError] = useState("");
  const [confirmPinError, setConfirmPinError] = useState("");

  const verify = () => {
    setPinError("");
    setConfirmPinError("");
    if (newPin.length !== 4) {
      setPinError("Pin must be 4 characters long");
    } else {
      if (confirmPin !== newPin) {
        setConfirmPinError("Pin don't match");
        document.querySelector("#confirm_pin")?.focus();
      } else {
        handleSteps();
      }
    }
  };

  return (
    <>
      <Input
        hasError={Boolean(pinError)}
        message={pinError}
        required
        value={newPin}
        onChange={e => {
          setNewPin(e.target.value);
        }}
        type="password"
        minLength={4}
        maxLength={4}
        pattern="[0-9]{4}"
        className="mt-5"
        label="New Pin"
        id="new_pin"
        autoFocus
      />
      <Input
        hasError={Boolean(confirmPinError)}
        message={confirmPinError}
        required
        value={confirmPin}
        onChange={e => {
          setConfirmPin(e.target.value);
        }}
        type="password"
        minLength={4}
        maxLength={4}
        pattern="[0-9]{4}"
        className="mt-5"
        label="Confirm New Pin"
        id="confirm_pin"
      />
      <div className="mt-12 flex justify-end">
        <button
          className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5"
          onClick={verify}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default VerifyNewPin;
