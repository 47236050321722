import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Progress, Stack } from "@chakra-ui/react";

import { useFindInteractionByPractitionerQuery } from "generated/graphql";
import userVar from "vars/user";
import loggedInClinicVar from "vars/loggedInClinic";
import Empty from "components/Empty";

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 400px);
  justify-content: center;
`;

const PatientList = () => {
  const { data, error, loading } = useFindInteractionByPractitionerQuery({
    variables: {
      clinicId: loggedInClinicVar().id,
      practitionerId: userVar().id,
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return (
      <div>
        <Loading>
          <Stack>
            <Progress size="xs" isIndeterminate />
          </Stack>
        </Loading>
      </div>
    );
  }

  if (error) {
    return <Empty text={error.message} />;
  }

  if (data?.findInteractionByPractitioner?.length < 1) {
    return <Empty text="No Interaction found" />;
  }

  return (
    <div>
      {data?.findInteractionByPractitioner?.map((interaction, i) => (
        <Link
          to={"/app/practitioner/activity/patient"}
          state={{ id: interaction.id }}
          key={i}
        >
          <div className="flex justify-between p-4">
            <p>
              {interaction?.patient?.firstName} {interaction?.patient?.lastName}
            </p>
            <p>{interaction?.prescriptionNumber}</p>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default PatientList;
