import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useFindInteractionByChwQuery } from "generated/graphql";
import Empty from "components/Empty";
import userVar from "vars/user";
import loggedInClinicVar from "vars/loggedInClinic";

const Section = styled.section`
  .cont {
    border: 1px solid #b3b6ba;
    border-radius: 4px 4px 0px 0px;
  }
`;

const Editinteraction = () => {
  const { data, loading, error } = useFindInteractionByChwQuery({
    fetchPolicy: "cache-and-network",
    variables: { chwId: userVar().id, clinicId: loggedInClinicVar().id },
  });

  return (
    <Section>
      <h1>Edit Activity</h1>

      {error ? (
        <Empty text={error.message} />
      ) : loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {data.findInteractionByCHW &&
          data?.findInteractionByCHW.length > 0 ? (
            data?.findInteractionByCHW.map((interaction, i) => (
              <NavLink
                to={"/app/chw/record/why"}
                key={i}
                state={{ id: interaction.id }}
              >
                <div className="flex justify-between p-4 cont mb-4">
                  <p className="text-base">
                    {interaction?.patient.firstName}{" "}
                    {interaction?.patient.lastName}
                  </p>
                  <p className="text-base font-medium">
                    {interaction?.prescriptionNumber}
                  </p>
                </div>
              </NavLink>
            ))
          ) : (
            <Empty text="No Interaction Found" />
          )}
        </div>
      )}
    </Section>
  );
};

export default Editinteraction;
