import React from "react";
import styled from "styled-components";
import ItemsTab from "components/itemstab";

import { MdNotifications } from "react-icons/md";
import { useWhoAmIQuery } from "generated/graphql";
import GoBack from "components/GoBack";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  h6 {
    font-size: 0.875rem;
  }

  p {
    font-size: 1rem;
  }

  .font-medium {
    font-size: 0.875rem;
  }

  h1 {
    color: #4285f4;
  }
`;

const Profile = () => {
  const { data, loading, error } = useWhoAmIQuery();

  console.log(data)

  return (
    <Div className="px-4 relative">
      <div className="flex items-center">
        <GoBack />
        <button className="ml-auto">
          <MdNotifications className="text-xl" />
        </button>
      </div>

      {error ? (
        <div className="text-red-500 flex align-center justify-center">
          {error.message}
        </div>
      ) : loading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div className="text-center">
            <p className="text-sm text-gray-300">Wallet Address</p>
            <p className="text-2xl font-bold text-gray-800 dark:text-white">
              1GVY5eZvtc5bA6EFEGnpqJeHUC5YaV5dsb
            </p>
          </div>

          <div className="my-6 grid gap-2">
            <ItemsTab className="ring-1 ring-gray-300 !py-4">
              <p className="text-left font-medium">Name</p>
              <p className="text-left">
                {data?.whoAmI?.profile?.firstName +
                  " " +
                  data?.whoAmI?.profile?.lastName}
              </p>
            </ItemsTab>

            <ItemsTab className="ring-1 ring-gray-300 !py-4">
              <p className="text-left font-medium">Phone Number</p>
              <p className="text-left">{data?.whoAmI?.phoneNumber}</p>
            </ItemsTab>

            <ItemsTab className="ring-1 ring-gray-300 !py-4">
              <p className="text-left font-medium">Email</p>
              <p className="text-left">{data?.whoAmI?.email}</p>
            </ItemsTab>

            <ItemsTab className="ring-1 ring-gray-300 !py-4">
              <p className="text-left font-medium">Country</p>
              <p className="text-left">
                {data?.whoAmI?.profile?.country?.name}
              </p>
            </ItemsTab>

            <ItemsTab className="ring-1 ring-gray-300 !py-4">
              <p className="text-left font-medium">Bank account</p>
              <p className="text-left">212901573 (UBA)</p>
            </ItemsTab>
          </div>
        </>
      )}
    </Div>
  );
};

export default Profile;
