import React, { useState, useEffect } from "react";
import Input from "components/input";
import { useLocation, useNavigate } from "react-router-dom";
import { useDisclosure } from "@chakra-ui/react";
import StatusModal from "components/StatusModal";
import { useEditInteractionRequestMutation } from "generated/graphql";

const Reason = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const navigate = useNavigate();
  const [requestEdit, { loading, error }] = useEditInteractionRequestMutation();

  const [err, seterr] = useState(null);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState(null);

  function validate() {
    setReasonError(null);
    const error = [];
    if (!reason) {
      setReasonError("Required");
      error.push("required");
    } else if (reason.length < 5) {
      setReasonError("Reason cannot be less than 5 characters");
      error.push("too-short");
    }

    if (error.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    seterr(false);
    if (validate()) {
      requestEdit({
        variables: {
          input: {
            interactionId: location.state.id,
            reason,
          },
        },
      })
        .then(() => {
          onOpen();
        })
        .catch(() => {
          seterr(true);
          onOpen();
        });
    }
  }

  useEffect(() => {
    if (!location.state) navigate(-1);
  }, [location.state, navigate]);

  return (
    <div>
      <p className="mb-4 text-lg">Why are you requesting an edit ?</p>
      <form onSubmit={handleSubmit}>
        <Input
          hasError={Boolean(reasonError)}
          message={reasonError}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          label="Enter Reason"
          id="reason"
          type="text"
        />

        <div className="mt-20 flex">
          <button
            disabled={loading}
            className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5 rounded-full"
          >
            Submit
          </button>
        </div>
      </form>

      {err ? (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text={error?.message}
          status="Failed"
          samePageNav={true}
        />
      ) : (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          onOpen={onOpen}
          text="You have successfully requested for an edit. Check your notification for an update later."
        />
      )}
    </div>
  );
};

export default Reason;
