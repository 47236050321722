import React from "react";
// import logo from "assets/images/logofull.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Light2 from "assets/images/light2.svg";
import Dark2 from "assets/images/dark2.svg";

const Div = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: flex-end; */
  /* height: 95vh; */

  img {
    width: 225px;
    height: 250px;
  }

  .title {
    font-family: "Airbnb Cereal App", sans-serif;
    font-size: 1.75rem;
    line-height: 2.43rem;
    font-weight: 700;
  }

  .sub-title {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.37rem;
    max-width: 19.625rem;
    letter-spacing: .25px;
  }

  a {
    font-size: 1rem;
    font-weight: 700;
  }
`;

export default function Two() {
  return (
    <Div className="p-5 max-w-[500px]">
      <div className="mb-20 mt-20 block dark:hidden">
        <img src={Light2} alt="" className="mx-auto w-full" />
      </div>

      <div className="mb-20 mt-20 hidden dark:block">
        <img src={Dark2} alt="" className="mx-auto w-full" />
      </div>
      {/* <div className="my-11 h-[250px]"></div> */}
      <div className="text-left">
        <h1 className="mb-4 title">Welcome to Ribbon</h1>
        <p className="sub-title items-center">
          Prioritize patient health and get instant access to patient records
          and information.
        </p>
      </div>
      <div className="text-right">
        <Link
          to="/auth"
          className="bg-blue-500 py-2 text-lg rounded-full text-white inline-block px-6"
        >
          Get Started
        </Link>
      </div>
    </Div>
  );
}
