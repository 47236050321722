import React, { useState, useRef } from "react";
import Input from "components/input";
import { useSpring, animated } from "react-spring";
import { MdOutlineArrowBack } from "react-icons/md";
import { AiOutlineLock } from "react-icons/ai";
import styled from "styled-components";
import Confirm from "./confirm";

const Background = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: auto;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-end;
  z-index: 10000000000000;
`;

const ModalContent = styled.div`
  height: 600px;
  border-radius: 24px 24px 0px 0px;

  button {
    border-radius: 24px;
  }

  .line {
    height: 5px;
    width: 30px;
    background: #b3b6ba;
  }

  .title {
    color: #4285f4;
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1.8rem;
  }
`;

const ModalWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const InputField = styled.div``;

// send and setSend are coming from navs

const SendAFYA = ({ send, setSend }) => {
  const [confirm, setConfirm] = useState(false);

  const setModal = useRef();

  const closeModal = (e) => {
    if (setModal.current === e.target) {
      setSend(false);
    }
  };

  const animate = useSpring({
    config: {
      duration: 2500000,
    },
    opacity: send === true ? 1 : 0,
    transform: send === true ? `translateY(0%)` : `translateY(100%)`
  });

  const handleConfirmButton = (e) => {
    setConfirm(true);
  };

  return (
    <Background ref={setModal} onClick={closeModal}>
      <ModalWrapper>
        <animated.div style={animate}>
          <ModalContent className="p-4 bg-white dark:bg-[#343B46]">
            <div className="flex mt-2 justify-center mb-2.5">
              <div className="line"></div>
            </div>

            <div className="flex items-center justify-between px-2 mb-8">
              <div
                className="items-center"
                onClick={(prevState) => setSend(!prevState)}
              >
                <MdOutlineArrowBack size={36} color="#4285F4" />
              </div>
              <div className="title mr-4">Send</div>
              <div></div>
            </div>

            <InputField>
              <div className="bg-blue flex justify-between p-4">
                <p>AFYA</p>
                <AiOutlineLock color="#B3B6BA" />
              </div>

              <Input
                hasError={false}
                message=""
                required
                type="text"
                className="mt-6"
                label="User Wallet ID"
                id="wallet"
                autoFocus
              />

              <Input
                hasError={false}
                message=""
                required
                type="number"
                className="mt-6"
                label="Amount"
                id="amount"
              />

              <div className="mt-12 flex justify-end">
                <button
                  className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5"
                  onClick={handleConfirmButton}
                >
                  Next
                </button>
              </div>
            </InputField>
          </ModalContent>
        </animated.div>
      </ModalWrapper>

      <Confirm confirm={confirm} setConfirm = {setConfirm} />
    </Background>
  );
};

export default SendAFYA;
