import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import userVar from "vars/user";
import { useReactiveVar } from "@apollo/client";
import styled from "styled-components";
import GoBack from "../../../GoBack";

const Div = styled.div`
  .arrow {
    font-weight: 500;
    font-size: 0.875rem;
  }
`;

function AddUserContainer() {
  const user = useReactiveVar(userVar);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (user) {
      // navigate(location.state?.uri || "/app/patient/home", { replace: true });
    }
  }, [user, navigate, location.state?.uri]);
  return (
    <Div className="p-4 mx-auto max-w-[500px]">
      <div>
        <GoBack />
      </div>
      <Outlet />
    </Div>
  );
}

export default AddUserContainer;
