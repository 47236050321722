import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  MdMenuBook,
  MdAssignmentInd,
  MdCallMade,
  MdCallReceived,
} from "react-icons/md";
import SendAFYA from "./Send/send";
import ReceiveAFYA from "./Receive/receive";

const Section = styled.div`
  div {
    background: #e7effe;

    a {
      display: flex;
      align-items: center;
      padding: 0.75rem;
      border-radius: 4px;
      height: 100%;
    }

    p {
      font-size: 0.875rem;
      font-weight: 500;
      margin-left: 0.75rem;
    }
  }
`;

export default function Navs() {
  const [send, setSend] = useState(false);
  const [receive, setReceive] = useState(false);

  const handleSend = () => {
    setSend(!send);
  };

  const handleReceive = () => {
    setReceive(!receive);
  };

  return (
    <>
      <Section className="mt-8 md:mt-12 grid grid-cols-2 gap-x-6 gap-y-4 dark:text-black">
        <div>
          <NavLink to="/app/chw/add">
            <MdAssignmentInd color="#4285F4" />
            <p>
              Record new <br /> User
            </p>
          </NavLink>
        </div>

        <div>
          <NavLink to="/app/chw/record">
            <MdMenuBook color="#4285F4" />
            <p>
              Record <br /> Activity
            </p>
          </NavLink>
        </div>

        <div onClick={handleSend}>
          <div className="flex h-full items-center pl-3 py-3">
            <MdCallMade color="#4285F4" />
            <p>Send AFYA</p>
          </div>
        </div>

        <div onClick={handleReceive}>
          <div className="flex h-full items-center pl-3 py-3">
            <MdCallReceived color="#4285F4" />
            <p>
              Receive <br /> AFYA
            </p>
          </div>
        </div>
      </Section>
      <div>{send && <SendAFYA setSend={setSend} send={send} />}</div>
      <div>
        {receive && <ReceiveAFYA setReceive={setReceive} receive={receive} />}
      </div>
    </>
  );
}
