import React, { useState, useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'
import { MdOutlineArrowBack } from 'react-icons/md'
import Input  from '../../../../components/input'

const Background = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .6);
    display: flex;
    align-items: flex-end;
    z-index: 10000000000000;
`

const ModalContent = styled.div`
    background: #fff;
    height: 100%;
    border-radius: 24px 24px 0px 0px;
    width: 100vw;
    font-family: 'Airbnb Cereal App', sans-serif;

    button {
        border-radius: 24px;
    }

    .line {
        height: 5px;
        width: 30px;
        background: #B3B6BA;
    }

    .title {
        color: #4285F4;
        font-weight: 500;
        font-size: 1rem;
        margin-right: 1.8rem;
    }
`

const ModalWrapper = styled.div`
    position: relative;
`

const InputField = styled.div`

`

const Confirm = ({ showModal, setShowModal }) => {
    const setModal = useRef();

    const closeModal = e => {
        if(setModal.current === e.target) {
            setShowModal(false)
        }
    }

    const animate = useSpring({
        config: {
            duration: 250
        },
        opacity: showModal ? 1 : 0,
        transform: showModal ? `translateY(0%)` : `translateY(100%)`
    })

    return (
        <>
          {showModal && (
            <Background ref = {setModal} onClick = {closeModal}>
                <ModalWrapper showModal = {showModal}>
                    <animated.div style = {animate}>
                        <ModalContent className = 'p-4'>
                            <div className = 'flex mt-2 justify-center mb-2.5'>
                                <div className = 'line'></div>
                            </div>

                            <div className = 'flex items-center justify-between px-2 mb-4'>
                                <div className = 'items-center' onClick = {(prevState) => setShowModal(!prevState)}>
                                    <MdOutlineArrowBack size = {36} color = "#4285F4" />
                                </div>
                                <div className = 'title mr-4'>
                                    Send
                                </div>
                                <div></div>
                            </div>

                            <div>
                                <div>
                                    <div>Amount</div>
                                    <div>500 AFYA</div>
                                </div>

                                <div>
                                    <div>Receiver</div>
                                    <div>FGguy43537@gmail.com</div>
                                </div>
                            </div>
                            
                            <InputField>
                                <Input
                                    hasError={false}
                                    message=""
                                    required
                                    type="password"
                                    className="mt-5"
                                    label="Pin"
                                    id="pin"
                                    autoFocus
                                />
                                
                                <div className = 'mt-12 flex justify-end'>
                                    <button className = "bg-[#4285F4] text-base text-white font-bold px-6 py-2.5">
                                        Confirm
                                    </button>
                                </div>
                            </InputField>
                        </ModalContent>
                    </animated.div>
                </ModalWrapper>
            </Background>
            )
          }
        </>
    )
}

export default Confirm
