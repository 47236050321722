import React, { useState } from "react";
import Input from "components/input";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import userVal from "vars/user";

function VerifyCurrentNumber({ handleSteps, currentPhone }) {
  const [phoneError, setPhoneError] = useState("");
  const verify = () => {
    if (isValidPhoneNumber(currentPhone, userVal()?.profile?.country?.code)) {
      handleSteps();
    } else {
      setPhoneError("Invalid phone number");
      document.querySelector("#currentPhone")?.focus();
    }
  };
  return (
    <>
      <Input
        value={currentPhone}
        onChange={null}
        hasError={Boolean(phoneError)}
        message={phoneError}
        required
        type="tel"
        className="mt-5"
        label="Current Phone Number"
        id="currentPhone"
        autoFocus
      />
      <div className="mt-12 flex justify-end">
        <button
          className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5"
          onClick={verify}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default VerifyCurrentNumber;
