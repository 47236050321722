import React, { useState } from "react";
import Input from "components/input";
import * as yup from "yup";
import { useChangePhoneNumberOtpMutation } from "generated/graphql";
import { ImSpinner8 } from "react-icons/im";
import classNames from "classnames";

const schema = yup.object().shape({
  email: yup.string().email().required("required"),
});

function VerifyEmail({ email, setemail, handleSteps, currentPhone }) {
  const [emailError, setemailError] = useState("");
  const [requestOtp, { loading }] = useChangePhoneNumberOtpMutation();
  
  const verify = () => {
    setemailError("");
    schema
      .validate({ email })
      .then(() => {
        requestOtp({
          variables: { input: { phoneNumber: currentPhone, email } },
        })
          .then(() => {
            handleSteps();
          })
          .catch((error) => {
            setemailError(error.message);
          });
      })
      .catch((err) => {
        setemailError(err.errors[0]);
        document.querySelector("#email")?.focus();
      });
  };
  return (
    <>
      <Input
        value={email}
        onChange={(e) => {
          setemail(e.target.value);
        }}
        hasError={Boolean(emailError)}
        message={emailError}
        required
        type="email"
        className="mt-5"
        label="Email address"
        id="email"
        autoFocus
      />
      <div className="mt-12 flex justify-end">
        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 text-lg rounded-full text-white block",
            { "opacity-20": loading }
          )}
          onClick={verify}
          disabled = {loading}
        >
          Next
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center bg-opacity-20 items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </div>
    </>
  );
}

export default VerifyEmail;
