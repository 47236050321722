import React, { useState } from "react";
import styled from "styled-components";
import Input from "components/input";
import MinorSummary from "./MinorSummary";
import { useFindAllUsersLazyQuery } from "generated/graphql";
import { debounce } from "lodash";
import Multiselect from "multiselect-react-dropdown";

const Div = styled.section`
  button {
    border-radius: 24px;
  }
`;

const Minor = () => {
  const [findAllUsers] = useFindAllUsersLazyQuery();

  const [summary, setSummary] = useState(false);

  const [firstName, setfirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [address, setaddress] = useState(null);
  const [dob, setdob] = useState(null);
  const [gender, setgender] = useState(null);
  // const [nationalIdNumber, setnationalIdNumber] = useState(null);
  // const [healthRecordNumber, sethealthRecordNumber] = useState(null);
  const [guardian, setguardian] = useState(null);

  const [firstNameError, setfirstNameError] = useState(null);
  const [lastNameError, setlastNameError] = useState(null);
  const [addressError, setaddressError] = useState(null);
  const [dobError, setdobError] = useState(null);
  const [genderError, setgenderError] = useState(null);
  // const [nationalIdNumberError, setnationalIdNumberError] = useState(null);
  // const [healthRecordNumberError, sethealthRecordNumberError] = useState(null);
  const [guardianError, setguardianError] = useState(null);

  const [minorData, setminorData] = useState(null);

  const [gaurdiansList, setGaurdiansList] = useState([]);
  const [gaurdianListLoading, setGaurdianListLoading] = useState(false);

  function validate() {
    const error = [];
    setfirstNameError(null);
    setlastNameError(null);
    setaddressError(null);
    setdobError(null);
    setgenderError(null);
    // setnationalIdNumberError(null);
    // sethealthRecordNumberError(null);
    setguardianError(null);

    if (!firstName) {
      setfirstNameError("Required");
      error.push("required");
    } else if (firstName.length < 2) {
      setfirstNameError("Must contain atleast 3 characters");
      error.push("required");
    }

    if (!lastName) {
      setlastNameError("Required");
      error.push("required");
    } else if (lastName.length < 2) {
      setlastNameError("Must contain atleast 3 characters");
      error.push("required");
    }

    if (!address) {
      setaddressError("Required");
      error.push("required");
    } else if (address.length < 9) {
      setaddressError("Must contain atleast 10 characters");
      error.push("required");
    }

    if (!dob) {
      setdobError("Required");
      error.push("required");
    }

    if (!gender) {
      setgenderError("Required");
      error.push("required");
    } else if (!["MALE", "FEMALE", "OTHER"].includes(gender)) {
      setgenderError("Not a valid gender selection");
      error.push("required");
    }

    // if (!nationalIdNumber) {
    //   setnationalIdNumberError("Required");
    //   error.push("required");
    // } else if (nationalIdNumber.length < 2) {
    //   setnationalIdNumberError("Must contain atleast 3 characters");
    //   error.push("required");
    // }

    // if (!healthRecordNumber) {
    //   sethealthRecordNumberError("Required");
    //   error.push("required");
    // } else if (healthRecordNumber.length < 2) {
    //   sethealthRecordNumberError("Must contain atleast 3 characters");
    //   error.push("required");
    // }

    if (!guardian) {
      setguardianError("Required");
      error.push("required");
    } else if (Number(guardian) <= 0) {
      setguardianError("No Guardian Selected");
      error.push("required");
    }

    if (error.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  const handleGenderChange = (event) => {
    setgender(event.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      setminorData({
        firstName,
        lastName,
        address,
        dob,
        gender,
        guardian,
        // nationalIdNumber,
        // healthRecordNumber,
      });
      setSummary(true);
    }
  }

  function handleGaurdian(selectedList) {
    const users = [];
    selectedList.map((x) => users.push(x));
    setguardian(users[0]);
  }

  function handleRemoveGaurdian() {
    setguardian(null);
  }

  const handleGaurdiansSearchDebounce = React.useCallback((query) => {
    searchGaurdian(query);
  }, []);

  const searchGaurdian = debounce((query) => {
    setGaurdianListLoading(true);
    findAllUsers({
      variables: {
        input: {
          search: query,
        },
      },
    })
      .then(({ data }) => {
        const newUser = [];
        if (data.findAllUsers.data && data.findAllUsers.data?.length > 0) {
          data.findAllUsers.data.map((x) =>
            newUser.push({
              id: x.id,
              name: `${x.profile.firstName} ${x.profile.lastName} (${x.phoneNumber})`,
            })
          );
          setGaurdiansList(newUser);
        } else {
          setGaurdiansList([{ name: "No Item Found" }]);
        }
      })
      .catch(() => {
        setGaurdiansList([{ name: "Error Occurred" }]);
      })
      .finally(() => {
        setGaurdianListLoading(false);
      });
  }, 1000);

  return (
    <Div>
      <div className="text-center">
        <p className="text-xl font-medium">Create Minor</p>
      </div>
      <div className="mt-5">
        <form onSubmit={handleSubmit}>
          <Input
            hasError={Boolean(firstNameError)}
            message={firstNameError}
            value={firstName}
            onChange={(e) => {
              setfirstName(e.target.value);
            }}
            required
            type="text"
            className="mb-4"
            label="First Name"
            id="firstname"
          />
          <Input
            hasError={Boolean(lastNameError)}
            message={lastNameError}
            value={lastName}
            onChange={(e) => {
              setlastName(e.target.value);
            }}
            required
            type="text"
            className="mb-4"
            label="Last Name"
            id="lastname"
          />
          <Input
            hasError={Boolean(dobError)}
            message={dobError}
            value={dob}
            onChange={(e) => {
              setdob(e.target.value);
            }}
            required
            type="date"
            className="mb-4"
            label="Date of Birth"
            id="dob"
          />
          <div className="mb-4">
            <div className="flex justify-between items-center bg-[#FBFCFE] dark:bg-[#17202F] p-4">
              <div>
                <input
                  type="radio"
                  id="male"
                  value="MALE" //must be in capital
                  className="mr-2"
                  checked={gender === "MALE"}
                  onChange={handleGenderChange}
                />
                <label htmlFor="male">Male</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="female"
                  value="FEMALE" //must be in capital
                  className="mr-2"
                  checked={gender === "FEMALE"}
                  onChange={handleGenderChange}
                />
                <label htmlFor="female">Female</label>
              </div>

              <div>
                <input
                  type="radio"
                  id="other"
                  value="OTHER" //must be in capital
                  className="mr-2"
                  checked={gender === "OTHER"}
                  onChange={handleGenderChange}
                />
                <label htmlFor="other">Other</label>
              </div>
            </div>
            <div className="text-red-500">{genderError}</div>
          </div>

          <Input
            hasError={Boolean(addressError)}
            message={addressError}
            value={address}
            onChange={(e) => {
              setaddress(e.target.value);
            }}
            required
            type="text"
            className="mb-4"
            label="Address"
            id="address"
          />
          {/* <Input
            hasError={Boolean(nationalIdNumberError)}
            message={nationalIdNumberError}
            value={nationalIdNumber}
            onChange={(e) => {
              setnationalIdNumber(e.target.value);
            }}
            // required
            type="text"
            className="mb-4"
            label="National ID Number"
            id="nin"
          />
          <Input
            hasError={Boolean(healthRecordNumberError)}
            message={healthRecordNumberError}
            value={healthRecordNumber}
            onChange={(e) => {
              sethealthRecordNumber(e.target.value);
            }}
            // required
            type="text"
            className="mb-4"
            label="Health Record Number"
            id="health"
          /> */}

          <div className="mb-4">
            <div className="bg-[#FBFCFE] dark:bg-[#17202F] p-2">
              <Multiselect
                isObject={true}
                options={
                  gaurdiansList.length > 0
                    ? gaurdiansList
                    : [{ name: "No Item" }]
                }
                // singleSelect={true}
                placeholder="Gaurdian"
                displayValue="name"
                emptyRecordMsg={"No Gaurdian"}
                onSelect={handleGaurdian}
                onSearch={handleGaurdiansSearchDebounce}
                onRemove={handleRemoveGaurdian}
                loading={gaurdianListLoading}
                id="gaurdian"
                style={{
                  searchBox: { border: "none", background: "none" },
                  optionContainer: {
                    background: "rgba(0,150,251,.7)",
                    color: "#fff",
                  },
                }}
                selectionLimit={1}
              />
            </div>
            <small className={"text-red-500"}>{guardianError}</small>
          </div>
          <div className="mt-20 flex justify-end">
            <button className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5">
              Submit
            </button>
          </div>
        </form>
      </div>
      <MinorSummary
        summary={summary}
        setSummary={setSummary}
        data={minorData}
      />
    </Div>
  );
};

export default Minor;
