import React, { useState, useMemo, useRef } from "react";
import classNames from "classnames";

function Input(
  { label, id, hasError, message, onFocus, className, onBlur, ...props },
  ref
) {
  const [focused, setFocused] = useState(false);
  const inputRef = useRef(null);
  const activeRef = useMemo(() => {
    return ref || inputRef;
  }, [ref]);

  const active = useMemo(() => {
    return Boolean(activeRef.current?.value) || focused;
  }, [activeRef, focused]);
  return (
    <div className={className}>
      <div
        className={classNames([
          "motion-safe:transition-colors duration-1000 h-14  px-4 py-2 relative border-b-2 border-b-transparent",
          {
            "bg-blue": !hasError,
            "bg-pink": hasError,
            "bg-opacity-10": !active,
            "bg-opacity-20": active,
            "border-b-blue-500": active && !hasError,
            "border-b-pink": active && hasError,
          },
        ])}>
        <label
          htmlFor={id}
          className={classNames([
            "block motion-safe:transition-all duration-1000",
            {
              "py-2": !active,
              "text-blue-500": active && !hasError,
              "text-pink": hasError,
              "text-xs": active,
              "text-gray-300": !active && !hasError,
            },
          ])}>
          {label}
        </label>
        <input
          ref={activeRef}
          id={id}
          onFocus={(e) => {
            setFocused(true);
            onFocus?.(e);
          }}
          onBlur={(e) => {
            setFocused(false);
            onBlur?.(e);
          }}
          className={classNames([
            "outline-none block w-full bg-transparent duration-1000 motion-safe:transition-all autofill:bg-transparent",
            {
              "h-0": !active,
              "": active,
            },
          ])}
          {...props}
        />
      </div>
      <small className={classNames([{ "text-red-500": hasError }])}>{message}</small>
    </div>
  );
}

export default React.forwardRef(Input);
