import React from "react";
import styled from "styled-components";
import ItemsTab from "components/itemstab";

import { MdNotifications } from "react-icons/md";
// import { useWhoAmIQuery } from "generated/graphql";
import GoBack from "components/GoBack";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  h6 {
    font-size: 0.875rem;
  }

  p {
    font-size: 1rem;
  }

  .font-medium {
    font-size: 0.875rem;
  }

  h1 {
    color: #4285f4;
  }
`;

const MinorProfile = () => {
  //   const { data, loading, error } = useWhoAmIQuery();

  return (
    <Div className="px-4 relative">
      <div className="flex items-center">
        <GoBack />
        <button className="ml-auto">
          <MdNotifications className="text-xl" />
        </button>
      </div>

      <>
        <div className="text-center">
          <p className="text-sm text-gray-300">Wallet Address</p>
          <p className="text-2xl font-bold text-gray-800 dark:text-white">
            1GVY5eZvtc5bA6EFEGnpqJeHUC5YaV5dsb
          </p>
        </div>

        <div className="my-6 grid gap-2">
          <ItemsTab className="ring-1 ring-gray-300 !py-4">
            <p className="text-left font-medium">Name</p>
            <p className="text-left">
              Ruth Douglas
            </p>
          </ItemsTab>

          <ItemsTab className="ring-1 ring-gray-300 !py-4">
            <p className="text-left font-medium">Phone Number</p>
            <p className="text-left">08020544017</p>
          </ItemsTab>

          <ItemsTab className="ring-1 ring-gray-300 !py-4">
            <p className="text-left font-medium">Email</p>
            <p className="text-left">email@email.com</p>
          </ItemsTab>

          <ItemsTab className="ring-1 ring-gray-300 !py-4">
            <p className="text-left font-medium">Country</p>
            <p className="text-left">Nigeria</p>
          </ItemsTab>
        </div>
      </>
    </Div>
  );
};

export default MinorProfile;
