import React, { useState } from "react";
import { Outlet } from "react-router";

const Index = () => {
  const [state, setState] = useState({
    patient: true,
    practitioner: false,
  });

  const handlePatient = () => {
    setState({
      patient: true,
      practitioner: false,
    });
  };

  const handlePractitioner = () => {
    setState({
      patient: false,
      practitioner: true,
    });
  };

  return (
    <div>
      <Outlet
        context={{
          state,
          handlePatient,
          handlePractitioner,
        }}
      />
    </div>
  );
};

export default Index;
