import ReactDom from "react-dom";
import { useNavigate } from "react-router-dom";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";

function StatusModal({
  isOpen,
  onClose,
  text,
  navigateTo,
  status,
  samePageNav,
}) {
  const navigate = useNavigate();

  return (
    <div style={{fontFamily: 'Airbnb Cereal App'}}>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xs"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="dark:text-black">
            {status ? status : "Successful"}
          </ModalHeader>
          {samePageNav ? (
            <ModalCloseButton
              className="dark:text-black"
              onClick={() => onClose()}
            />
          ) : (
            <ModalCloseButton
              className="dark:text-black"
              onClick={() => {
                navigate(navigateTo ? navigateTo : -1);
              }}
            />
          )}
          <ModalBody>
            <p className="dark:text-black">{text}</p>
          </ModalBody>

          <ModalFooter>
            {samePageNav ? (
              <button
                className="bg-blue-500 px-6 py-2.5 rounded-full text-white font-bold"
                onClick={() => onClose()}
              >
                Continue
              </button>
            ) : (
              <button
                className="bg-blue-500 px-6 py-2.5 rounded-full text-white font-bold"
                onClick={() => {
                  navigate(navigateTo ? navigateTo : -1);
                }}
              >
                Continue
              </button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default StatusModal;
