import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import userVar from "vars/user";
import { useReactiveVar } from "@apollo/client";

function Reset() {
  const user = useReactiveVar(userVar);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user) {
      navigate(location.state?.uri || "/app/patient/home", { replace: true });
    }
  }, [user, navigate, location.state?.uri]);

  return <Outlet />;
}

export default Reset;
