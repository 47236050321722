import React, { useState, useEffect } from "react";
import Input from "components/input";
import {
  useChangePhoneNumberOtpMutation,
  useValidateOtpMutation,
} from "generated/graphql";
import { Link } from "react-router-dom";
import { ImSpinner8 } from "react-icons/im";
import classNames from "classnames";

function VerifyOtp({ otp, setotp, handleSteps, currentPhone, email }) {
  const [otpError, setotpError] = useState("");
  const [resendPreview, setresendPreview] = useState(false);
  const [validate, { loading }] = useValidateOtpMutation();
  const [requestOtp] = useChangePhoneNumberOtpMutation();

  let resendTimeout;

  const verify = () => {
    setotpError("");

    if (otp.length < 5) {
      setotpError("Otp must contain atleast 5 characters");
    } else {
      validate({
        variables: { input: { otp, phoneNumber: currentPhone } },
      })
        .then((data) => {
          if (data.data.validateOtp) {
            handleSteps();
          } else {
            setotpError("Invalid OTP");
          }
        })
        .catch((error) => {
          setotpError(error.message);
        });
    }
  };

  const handleResendOtp = (e) => {
    e.preventDefault();
    requestOtp({
      variables: { input: { phoneNumber: currentPhone, email } },
    });
    setresendPreview(true);
    resendTimeout = setTimeout(() => {
      setresendPreview(false);
    }, 60000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(resendTimeout);
    };
  }, []);

  return (
    <>
      <p>Kindly enter the OTP sent to your email to proceed</p>
      <Input
        value={otp}
        onChange={(e) => {
          setotp(e.target.value);
        }}
        hasError={Boolean(otpError)}
        message={otpError}
        required
        type="text"
        className="mt-5"
        label="OTP"
        id="two"
        autoFocus
      />
      {resendPreview ? (
        <p className="mt-5">Please wait a minute and check mail for otp ...</p>
      ) : (
        <p className="mt-5">
          Didn't get the OTP,{" "}
          <Link to={"#"} onClick={handleResendOtp} className="text-blue-500">
            Resend OTP
          </Link>
        </p>
      )}
      <div className="mt-12 flex justify-end">
        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 text-lg rounded-full text-white block",
            { "opacity-20": loading }
          )}
          onClick={verify}
          disabled={loading}
        >
          Verify
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center bg-opacity-20 items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </div>
    </>
  );
}

export default VerifyOtp;
