import React from "react";
import { useOutletContext } from "react-router-dom";
import { MdNotifications } from "react-icons/md";
import styled from "styled-components";
import PatientList from "components/CHW/Activity/PatientList";
import PractitionerList from "components/CHW/Activity/PractitionerList";
import InputSearch from "components/InputSearch";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  .list div {
    /* -moz-box-shadow: 0 2px 5px -2px #ccc;
    -webkit-box-shadow: 0 2px 5px -2px #ccc;
    box-shadow: 0 2px 5px -2px #ccc;
    margin-bottom: 0.5rem; */
  }

  input {
    margin-right: 1rem;
  }
`;

const Tab = styled.div`
  background: ${(props) => (props.active ? "#B7D0FB" : "none")};
  border-radius: ${(props) => (props.active ? "24px" : "0px")};
`;

function Activity() {
  const context = useOutletContext();
  const { state, handlePatient, handlePractitioner } = context;

  return (
    <Div className="px-4">
      <div className="mb-4">
        <button className="flex justify-end w-full">
          <MdNotifications className="text-xl" />
        </button>
      </div>

      <div className="mb-8">
        <InputSearch />
      </div>

      <div className="flex font-medium text-lg w-full">
        <Tab
          className="w-1/2 text-center py-3 cursor-pointer"
          onClick={handlePatient}
          active={state.patient}
        >
          <p>Patient</p>
        </Tab>

        <Tab
          className="w-1/2 text-center py-3 cursor-pointer"
          onClick={handlePractitioner}
          active={state.practitioner}
        >
          <p>Practitioner</p>
        </Tab>
      </div>

      <div className="mt-8 list dark:text-[#FFFBE5]">
        {state.patient && <PatientList />}
        {state.practitioner && <PractitionerList />}
      </div>
    </Div>
  );
}

export default Activity;
