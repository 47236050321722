import { useReactiveVar } from "@apollo/client";
import { useNavigate, Link } from "react-router-dom";
import ItemsTab from "components/itemstab";
import React from "react";
import {
  MdNotifications,
  MdOutlineAccountBalance,
  MdOutlineBrightness4,
  MdOutlineErrorOutline,
  MdOutlineLogout,
  MdOutlinePerson,
  MdOutlineSettings,
  MdOutlineToggleOff,
  MdOutlineToggleOn,
  MdCached,
} from "react-icons/md";
import themeVar from "vars/theme";
import userVar from "vars/user";
import { Logout } from "utils/auth";

import styled from "styled-components";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
`;

export default function MyAccount() {
  const theme = useReactiveVar(themeVar);
  const user = useReactiveVar(userVar);
  const navigate = useNavigate();

  function handleAccountSwitch() {
    navigate("/app/account/choose/role", {
      state: { user: user },
    });
  }

  return (
    <Div className="px-4">
      <div className="flex items-center">
        <h1>My Account</h1>
        <button className="ml-auto">
          <MdNotifications className="text-xl" />
        </button>
      </div>
      <p className="text-sm text-gray-300">{`${user?.profile?.firstName} ${user?.profile?.lastName}`}</p>
      <div className="my-6 grid gap-2">
        <ItemsTab
          onClick={() => {
            themeVar(theme === "light" ? "dark" : "light");
          }}
          className="ring-1 ring-gray-300 !py-4"
          leading={<MdOutlineBrightness4 className="text-2xl" />}
          trailing={
            <>
              <MdOutlineToggleOff
                className="text-2xl dark:hidden"
                aria-hidden
              />
              <MdOutlineToggleOn
                className="text-2xl hidden dark:block"
                aria-hidden
              />
            </>
          }
        >
          <p className="text-left">Enable Dark Mode</p>
        </ItemsTab>

        {(user.role === "CHW" ||
          user.role === "PRACTITIONER" ||
          user.linkedMinorAccount) && (
          <ItemsTab
            className="ring-1 ring-gray-300 !py-4"
            leading={<MdCached aria-hidden className="text-2xl" />}
            onClick={handleAccountSwitch}
          >
            <p className="text-left">Switch Profile</p>
          </ItemsTab>
        )}

        <Link to="/app/account/profile">
          <ItemsTab
            className="ring-1 ring-gray-300 !py-4"
            leading={<MdOutlinePerson aria-hidden className="text-2xl" />}
          >
            <p className="text-left">My Profile</p>
          </ItemsTab>
        </Link>

        <Link to="/app/account/settings">
          <ItemsTab
            className="ring-1 ring-gray-300 !py-4"
            leading={<MdOutlineSettings aria-hidden className="text-2xl" />}
          >
            <p className="text-left">My Settings</p>
          </ItemsTab>
        </Link>

        <ItemsTab
          className="ring-1 ring-gray-300 !py-4"
          leading={<MdOutlineAccountBalance aria-hidden className="text-2xl" />}
        >
          <p className="text-left">Access Loans</p>
        </ItemsTab>

        <Link to="/app/account/help">
          <ItemsTab
            className="ring-1 ring-gray-300 !py-4"
            leading={<MdOutlineErrorOutline aria-hidden className="text-2xl" />}
          >
            <p className="text-left">Help</p>
          </ItemsTab>
        </Link>

        <ItemsTab
          onClick={() => {
            Logout();
            navigate("/");
          }}
          className="ring-1 ring-gray-300 !py-4"
          leading={<MdOutlineLogout className="text-2xl text-pink" />}
        >
          <p className="text-left text-pink">Log Out</p>
        </ItemsTab>
      </div>
    </Div>
  );
}
