import { useReactiveVar } from "@apollo/client";
import React, { useEffect } from "react";
import themeVar from "vars/theme";

function ThemeEffects() {
  const theme = useReactiveVar(themeVar);

  useEffect(() => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) && window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      themeVar("dark");
    } else {
      themeVar("light");
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);
  return <></>;
}

export default ThemeEffects;
