import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "components/input";
import classNames from "classnames";
import { useLoginMutation } from "generated/graphql";
import userVar from "vars/user";
import tokenVar from "vars/token";
import styled from "styled-components";
import loggedInAsVar from "../../vars/loggedInAs";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [pin, setpin] = useState("");
  const [error, setError] = useState("");
  const [logIn, { data, loading, error: validateError }] = useLoginMutation();

  function handleSubmit(e) {
    e.preventDefault();
    if (pin.length === 4 && /^\d{4}$/.test(pin)) {
      setError("");
      logIn({
        variables: { input: { pin, phoneNumber: location.state.phoneNumber } },
      }).catch(() => {});
    } else setError("Pin must be four (4) digits");
  }

  useEffect(() => {
    if (!location.state) navigate("/auth");
  }, [location.state, navigate]);

  useEffect(() => {
    if (data) {
      localStorage.setItem("cus-rib-token", data.login.token);
      tokenVar(data.login.token);
      if (
        data.login?.role === "CHW" ||
        data.login?.role === "PRACTITIONER" ||
        data.login?.linkedMinorAccount
      ) {
        navigate("/auth/role", {
          state: { user: data.login },
        });
      } else {
        localStorage.setItem("cus-rib-user", JSON.stringify(data.login));
        localStorage.setItem("cus-rib-loggedInRole", data.login.role);
        userVar(data.login);
        loggedInAsVar(data.login.role);
      }
    }
  }, [data, navigate]);

  return (
    <Div>
      <h1 className="text-gray-300 mb-2 text">Welcome back!</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Enter your 4-digits pin to login.
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          hasError={Boolean(error)}
          message={error}
          value={pin}
          onChange={(e) => {
            setpin(e.target.value);
          }}
          required
          minLength={4}
          maxLength={4}
          pattern="[0-9]{4}"
          type="password"
          className="mt-5"
          label="Pin"
          id="pin"
          autoFocus
        />
        <p className="text-[#FD0404]">
          {!!validateError && validateError.message}
        </p>
        <div className="grid gap-4 mt-14">
          <p>
            Forgot pin?{" "}
            <Link to={"/auth/reset/phone"} className="text-blue-500">
              Click here.
            </Link>
          </p>
        </div>
        <button
          className={classNames(
            "bg-blue-500 font-bold outline-none focus-visible:ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-4 text-lg rounded-full text-white block",
            { "!bg-blue-200": loading }
          )}
        >
          Next
        </button>
      </form>
    </Div>
  );
}

export default Login;
