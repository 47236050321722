import BottomNav from "components/chwbottomnav";
import React from "react";
import { Outlet } from "react-router";

function CHWLayout() {
  return (
    <div className="relative">
      <div className="overflow-y-auto h-screen">
        <Outlet />
      </div>
      <BottomNav />
    </div>
  );
}

export default CHWLayout;
