import styled from "styled-components";
import { AiFillStar } from "react-icons/ai";

import { MdCallMade, MdCallReceived } from "react-icons/md";

const Div = styled.div`
  .ratings {
    font-weight: 800;
    font-size: 2.5rem;
  }
  .ratings-container {
    border-radius: 12px;
  }
`;

const Navs = () => {
  return (
    <Div>
      <section className="bg-[#073278] ratings-container text-white flex items-center justify-around px-2">
        <p className="ratings py-2">76%</p>
        <p className="text-base">Quality Rating</p>
        <div className="flex">
          <AiFillStar color="#FBBC05" size={20} />
          <AiFillStar color="#FBBC05" size={20} />
          <AiFillStar color="#FBBC05" size={20} />
          <AiFillStar color="#FBBC05" size={20} />
          <AiFillStar color="#FFF" size={20} />
        </div>
      </section>

      <section className="flex mt-5 justify-between" style={{ width: "100%" }}>
        <div
          className="bg-[#E7EFFE] flex justify-around items-center py-4 px-4"
          style={{ width: "45%" }}
        >
          <MdCallMade size={20} color="#4285F4" />
          <p>Send AFYA</p>
        </div>
        <div
          className="bg-[#E7EFFE] flex justify-around items-center py-3 px-4"
          style={{ width: "45%" }}
        >
          <MdCallReceived size={20} color="#4285F4" />
          <p>Receive AFYA</p>
        </div>
      </section>
    </Div>
  );
};

export default Navs;
