var options: any = { month: "long" };

export default function formatDate(date: any) {
  if (date) {
    var Xmas95 = new Date(date);
    const day = Xmas95.getDate();
    const month = new Intl.DateTimeFormat("en-US", options).format(Xmas95);
    const year = Xmas95.getFullYear();

    return `${day} ${month}, ${year}`;
  } else {
    return "Invalid date";
  }
}
