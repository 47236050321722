import React from "react";
import { Outlet } from "react-router";

function Dashboard() {
  return (
    <div style={{fontFamily: 'Airbnb Cereal App'}}>
      <Outlet />
    </div>
  );
}

export default Dashboard;
