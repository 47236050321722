import React from "react";
import { MdNotifications, MdRemoveRedEye } from "react-icons/md";
import ItemsTab from "components/itemstab";
import Icon from "react-crypto-icons";
import styled from "styled-components";
import market from "assets/images/market.png";
import bback from "assets/images/bback.png";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  .just {
    display: flex;
    width: 100%;
    justify-content: end;
  }

  .f {
    flex-direction: column;
  }

  .bal {
    font-size: 1.31rem;
  }
`;

function PatientMarket() {
  return (
    <Div className="relative">
      <div
        className="fixed bottom-0 w-full"
        style={{ zIndex: "-10", bottom: "-15px" }}
      >
        <img src={bback} alt="bback" className="w-full" />
      </div>
      <div className="p-y">
        <div>
          <button className="flex justify-end w-full">
            <MdNotifications className="text-xl" />
          </button>

          <div className="flex flex-col f items-center">
            <div className="flex">
              <p className="text-sm font-normal">Total Wallet Balance</p>
              <button className="px-2">
                <MdRemoveRedEye />
              </button>
            </div>
            <p className="bal font-bold">ZAR 1890.00</p>
          </div>
        </div>
        <div className="my-4">
          <section className="grid grid-cols-6 gap-4 bg-[#E9FAF2] dark:bg-transparent p-3 mb-3 ml-3">
            <div>
              <img src={market} alt="market" />
            </div>
            <div className="col-span-5">
              <>
                <p className="font-normal text-left dark:text-[#C8F3DE] i">
                  Buy and Sell crypto currencies in our market place.
                </p>
                <p className="text-base text-[#4285F4] font-medium text-left">
                  coming soon
                </p>
              </>
            </div>
          </section>

          <section className="grid grid-cols-6 gap-4 bg-[#FFFBE5] dark:bg-transparent p-3 mb-3 ml-3">
            <div>
              <img src={market} alt="market" />
            </div>
            <div className="col-span-5">
              <>
                <p className="font-normal text-left dark:text-[#FFF4BF] i">
                  Stake against fixed, secure currencies.
                </p>
                <p className="text-base text-[#4285F4] font-medium text-left">
                  coming soon
                </p>
              </>
            </div>
          </section>

          <section className="grid grid-cols-6 gap-4 bg-[#E5F5F8] dark:bg-transparent p-3 mb-3 ml-3">
            <div>
              <img src={market} alt="market" />
            </div>
            <div className="col-span-5">
              <>
                <p className="font-normal text-left dark:text-[#BFE6EE] i">
                  Liquidate your crypto here, with updated real time prices.
                </p>
                <p className="text-base text-[#4285F4] font-medium text-left">
                  coming soon
                </p>
              </>
            </div>
          </section>

          <section className="grid grid-cols-6 gap-4 bg-[#FFEBEB] dark:bg-transparent p-3 ml-3">
            <div>
              <img src={market} alt="market" />
            </div>
            <div className="col-span-5">
              <>
                <p className="font-normal text-left dark:text-[#FECFCD] i">
                  Access all our loan features. Access all our loan features.
                </p>
                <p className="text-base text-[#4285F4] font-medium text-left">
                  coming soon
                </p>
              </>
            </div>
          </section>
        </div>
      </div>
    </Div>
  );
}

export default PatientMarket;
