import React from "react";
import styled from "styled-components";
import { MdNotifications } from "react-icons/md";

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from "@chakra-ui/react";
import GoBack from "components/GoBack";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  h1 {
    color: #4285f4;
  }
`;
const Help = () => {
  return (
    <Div className="px-4 relative">
      <div className="flex items-center">
        <GoBack />
        <button className="ml-auto">
          <MdNotifications className="text-xl" />
        </button>
      </div>

      <div className="my-6 grid gap-2">
        <Accordion allowToggle>
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Earn AFYA
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Complete tasks to earn AFYA. Tasks are given different rewards,
              and you do not have to follow them sequentially, but it’s best to
              set up your profile first.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Send Currency
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Complete tasks to earn AFYA. Tasks are given different rewards,
              and you do not have to follow them sequentially, but it’s best to
              set up your profile first.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Receive Currency
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Complete tasks to earn AFYA. Tasks are given different rewards,
              and you do not have to follow them sequentially, but it’s best to
              set up your profile first.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Buy Currency
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Complete tasks to earn AFYA. Tasks are given different rewards,
              and you do not have to follow them sequentially, but it’s best to
              set up your profile first.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Sell Currency
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Complete tasks to earn AFYA. Tasks are given different rewards,
              and you do not have to follow them sequentially, but it’s best to
              set up your profile first.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Loan Currency
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Complete tasks to earn AFYA. Tasks are given different rewards,
              and you do not have to follow them sequentially, but it’s best to
              set up your profile first.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </Div>
  );
};

export default Help;
