import React, { useState } from "react";
import { useResetPinMutation } from "generated/graphql";
import { useDisclosure } from "@chakra-ui/react";
import styled from "styled-components";
import Input from "components/input";
import StatusModal from "components/StatusModal";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function OTPReset() {
  const [pin, setpin] = useState("");
  const [confirmPin, setconfirmPin] = useState("");
  const [otp, setOtp] = useState("");

  const [pinError, setpinError] = useState("");
  const [confirmPinError, setconfirmPinError] = useState("");
  const [otpError, setotpError] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [resetPin, { loading, error }] = useResetPinMutation();

  function handleSubmit(e) {
    e.preventDefault();

    setpinError("");
    setconfirmPinError("");
    setotpError("");

    if (loading) return;

    if (pin.length !== 4 && /^\d{4}$/.test(pin)) {
      setpinError("Pin must be 4 characters long and contain only numbers");
    } else if (confirmPin !== pin) {
      setconfirmPinError("Pin don't match");
    } else if (otp.length !== 6 && /^\d{6}$/.test(otp)) {
      setotpError("OTP must be six (6) digits");
    } else {
      resetPin({
        variables: {
          input: {
            otp,
            newPin: pin,
            confirmPin,
          },
        },
      }).then(() => {
        onOpen();
      });
    }
  }

  return (
    <Div>
      <h1 className="text-gray-300 mb-2 text">Verify your account.</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Kindly enter your New PIN and OTP sent to your email to proceed.
      </p>

      {error && <p className="text-red-500 text-lg">{error.message}</p>}

      <form onSubmit={handleSubmit}>
        <Input
          hasError={Boolean(pinError)}
          message={pinError}
          value={pin}
          onChange={(e) => {
            setpin(e.target.value);
          }}
          required
          minLength={4}
          maxLength={4}
          pattern="[0-9]{4}"
          type="password"
          className="mt-5"
          label="New Pin"
          id="pin"
          autoFocus
        />

        <Input
          hasError={Boolean(confirmPinError)}
          message={confirmPinError}
          value={confirmPin}
          onChange={(e) => {
            setconfirmPin(e.target.value);
          }}
          required
          minLength={4}
          maxLength={4}
          pattern="[0-9]{4}"
          type="password"
          className="mt-5"
          label="Confirm New Pin"
          id="confirmPin"
        />
        <Input
          hasError={Boolean(otpError)}
          message={otpError}
          value={otp}
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          required
          type="number"
          className="mt-5"
          label="Input OTP"
          id="otp"
        />

        <p className="mt-14">
          Didn’t get the code?{" "}
          <button className="text-blue-500 ">Resend OTP</button>
        </p>
        <button className="bg-blue-500 font-bold outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-4 text-lg rounded-full text-white block">
          Verify
        </button>
      </form>
      <StatusModal
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        text="Your pin has been changed successfully"
        navigateTo="/auth"
      />
    </Div>
  );
}

export default OTPReset;
