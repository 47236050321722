import React from "react";
import { useResolvedPath, Link,  useMatch } from "react-router-dom";
import classNames from "classnames";
// import Icon from "react-crypto-icons";

function ButtomLink({ icon, text, to, ...props }) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });
  return (
    <Link
      to={to}
      {...props}
      className={classNames(["text-gray-300 grid text-center py-2", { "!text-blue-main": match }])}>
      {/* <Icon className="text-lg mx-auto" aria-hidden /> */}
      <span className="text-lg mx-auto" aria-hidden>{icon}</span>
      <span className="text-xs font-medium">{text}</span>
    </Link>
  );
}

export default ButtomLink;
