import React from "react";
import { Outlet } from "react-router";
import bback from "assets/images/bback.png";
import b1 from "assets/images/b1.png";
import b2 from "assets/images/b2.png";

export default function MinorAccountContainer() {
  return (
    <div className="relative">
      <div
        className="absolute top-0 w-full dark:hidden"
        style={{ zIndex: "-10" }}
      >
        <img src={b1} alt="b1" className="w-full" />
      </div>
      <div
        className="absolute top-0 w-full hidden dark:block"
        style={{ zIndex: "-10" }}
      >
        <img src={b2} alt="b1" className="w-full" />
      </div>
      <div className="fixed bottom-0 w-full" style={{ zIndex: "-10", bottom: '-15px' }}>
        <img src={bback} alt="bback" className="w-full" />
      </div>
      <div className="overflow-y-auto h-screen">
        <Outlet />
      </div>
    </div>
  );
}
