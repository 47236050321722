import React from "react";
import { Outlet } from "react-router";
import CHWBottomNav from "components/chwbottomnav";
import PatientBottomNav from "components/bottomnav";
import bback from "assets/images/bback.png";
import loggedInAsVar from "vars/loggedInAs";
import PractitionerNav from "components/practitionernav";
import MinorBottomNav from "components/minornav";

function EarnContainer() {
  return (
    <div className="relative">
      <div
        className="fixed bottom-0 w-full"
        style={{ zIndex: -10, bottom: "-15px" }}
      >
        <img src={bback} alt="bback" className="w-full" />
      </div>
      <div className="overflow-y-auto h-screen">
        <Outlet />
      </div>
      {loggedInAsVar() === "CHW" ? (
        <CHWBottomNav />
      ) : loggedInAsVar() === "PRACTITIONER" ? (
        <PractitionerNav />
      ) : loggedInAsVar() === "MINOR" ? (
        <MinorBottomNav />
      ) : (
        <PatientBottomNav />
      )}
    </div>
  );
}

export default EarnContainer;
