import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Question from "./Question";
import {
  useSubmitTaskMutation,
  useFindTaskByIdQuery,
  useGetWalletLazyQuery,
  useFindRecentActivitiesLazyQuery,
} from "generated/graphql";
import userVar from "vars/user";
import { Progress, Stack, useDisclosure } from "@chakra-ui/react";
import StatusModal from "components/StatusModal";
import styled from "styled-components";
import loggedInAsVar from "vars/loggedInAs";

const background = [
  "bg-[#E9FAF2]",
  "bg-[#FFFBE5]",
  "bg-[#FFEBEB]",
  "bg-[#E5F5F8]",
  "bg-[#FFFBE5]",
];

const Loading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 400px);
  justify-content: center;
`;

function QuestionsList() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Params = useParams();
  const [r1, { refetch: refreshWallet }] = useGetWalletLazyQuery();
  const [r2, { refetch: refreshRecentActivities }] =
    useFindRecentActivitiesLazyQuery();
  const { data, loading, error } = useFindTaskByIdQuery({
    variables: {
      id: Number(Params.id),
    },
  });

  const [submit] = useSubmitTaskMutation();

  const [myError, setMyError] = useState(null);
  const [q, setq] = useState([]);
  const [qanda, setqanda] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setMyError(null);

    let newQandA = [];

    qanda.forEach((x) => {
      newQandA.push({
        questionId: x.questionId,
        answer: JSON.stringify(x.answer),
      });
    });

    submit({
      variables: {
        input: {
          userId: userVar()?.id,
          taskId: data?.findTaskById?.id,
          qanda: newQandA,
          userRole: loggedInAsVar(),
        },
      },
    })
      .then(() => {
        refreshRecentActivities();
        refreshWallet();
        onOpen();
      })
      .catch((error) => {
        setMyError(error.message);
        onOpen();
      });
  };

  useEffect(() => {
    if (q?.length > 0) {
      const p = [];

      q.forEach((x) => {
        p.push({
          questionId: x.id,
          answer: x.type === "MULTISELECT" ? [] : "",
        });
      });

      setqanda(p);
    }
  }, [q]);

  useEffect(() => {
    data && setq(data?.findTaskById?.question);
  }, [data]);

  if (error) {
    return <div>{error.message}</div>;
  }
  if (loading) {
    return (
      <div>
        <Loading>
          <Stack>
            <Progress size="xs" isIndeterminate />
          </Stack>
        </Loading>
      </div>
    );
  }

  return (
    <>
      <h3 className="text-center text-base font-medium capitalize mb-4">
        {data?.findTaskById?.name} Questionnaire
      </h3>
      <form onSubmit={handleSubmit}>
        {qanda.length > 0 &&
          q.map((question, i) => (
            <Question
              key={question.id}
              index={i}
              id={question.id}
              text={question.text}
              type={question.type}
              options={question.options}
              required={question.required}
              qanda={qanda}
              setqanda={setqanda}
              background={background[Math.floor(Math.random() * 5)]}
              // optionalNotes={question.optionalNotes}
            />
          ))}
        <button
          className={
            "bg-blue-500 font-bold outline-none focus-visible:ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-4 text-lg rounded-full text-white block"
          }
        >
          Submit
        </button>
      </form>
      {myError ? (
        <StatusModal
          isOpen={isOpen}
          onClose={onClose}
          text={myError}
          status="Failed"
          samePageNav={true}
        />
      ) : (
        <StatusModal
          text="Your questionnaire has been recorded successfully."
          isOpen={isOpen}
          onClose={onClose}
          navigateTo="/app/earn"
        />
      )}
    </>
  );
}

export default QuestionsList;
