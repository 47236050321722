import userVar from "vars/user";
import tokenVar from "vars/token";
import loggedInAsVar from "vars/loggedInAs";
import loggedInClinicVar from "vars/loggedInClinic";

export function Logout() {
  localStorage.removeItem("cus-rib-loggedInRole");
  localStorage.removeItem("cus-rib-loggedInClinic");
  localStorage.removeItem("cus-rib-token");
  localStorage.removeItem("cus-rib-user");
  loggedInClinicVar(null);
  loggedInAsVar(null);
  tokenVar(null);
  userVar(null);
}
