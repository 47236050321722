import React, {
  useState,
  Fragment,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { countries } from "countries-list";
import Input from "components/input";
import { localeIncludes } from "locale-includes";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import SelectSearch from "react-select-search";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { useValidatePhoneLazyQuery } from "generated/graphql";
import { ImSpinner8 } from "react-icons/im";
import styled from "styled-components";

const codes = Object.keys(countries);
const countryObjects = Object.values(countries);
const newObject = countryObjects.map((country, i) => ({
  ...country,
  value: codes[i],
}));

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

const Title = styled.div`
  .t p {
    font-size: 1rem;
    line-height: 1.37rem;
    font-weight: normal;
  }
`;

function InputNumber() {
  const inputRef = useRef(null);
  const buttonRef = useRef(null);
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [finduserbyphonenumber, { data, loading, error }] =
    useValidatePhoneLazyQuery({
      fetchPolicy: "network-only",
    });

  const search = useCallback((options) => {
    return (query) => {
      return options.filter((option) =>
        localeIncludes(option.name, query, {
          usage: "search",
          sensitivity: "base",
        })
      );
    };
  }, []);

  // useEffect(() => {
  //   if (data?.validatePhone) {
  //     if (data.validatePhone.user) {
  //       navigate("login", {
  //         state: { phoneNumber: data.validatePhone.user.phoneNumber },
  //       });
  //     } else setIsModalOpen(true);
  //   }
  // }, [data, navigate]);

  function handleSubmit(e) {
    e.preventDefault();
    if (loading) return;
    if (!country) {
      document.querySelector(".input-search")?.focus();
    } else if (isValidPhoneNumber(phoneNumber, country)) {
      console.log("country", country);
      setPhoneError("");
      finduserbyphonenumber({
        variables: {
          input: { phoneNumber: parsePhoneNumber(phoneNumber, country).number },
        },
      }).then(({ data }) => {
        if (data?.validatePhone) {
          if (data.validatePhone?.user) {
            navigate("login", {
              state: { phoneNumber: data.validatePhone.user.phoneNumber },
            });
          } else setIsModalOpen(true);
        }
      });
    } else {
      setPhoneError("Invalid phone number");
      inputRef.current?.focus();
    }
  }
  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Hello there,</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Select your country and enter your phone number to continue.
      </p>
      <form className="" onSubmit={handleSubmit}>
        <div className="my-6">
          <SelectSearch
            ref={buttonRef}
            className={(options) => {
              if (options === "container") {
                return "max-h-60 overflow-y-hidden grid grid-rows-[auto,1fr]";
              }
              if (options === "select") {
                return "overflow-y-auto";
              }
              if (options === "option") {
                return "text-left block w-full";
              }
              if (options === "row") {
                return "block px-4 py-2";
              }
              if (options === "input") {
                return classNames(
                  "p-4 placeholder-gray-300 w-full outline-none bg-blue bg-opacity-10 text-gray-800 dark:text-white input-search",
                  {
                    "bg-opacity-20": country,
                  }
                );
              }
              if (options === "has-focus") {
                return "ring ring-blue-500 rounded-sm";
              }
              if (options === "is-highlighted" || options === "is-selected") {
                return "bg-blue block";
              }
              return "";
            }}
            options={newObject}
            value={country}
            onChange={(value) => setCountry(value)}
            search
            filterOptions={search}
            placeholder="Select your country"
          />
        </div>

        <Input
          hasError={Boolean(phoneError)}
          message={phoneError}
          ref={inputRef}
          required
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          type="tel"
          id="phoneNumber"
          label="Phone Number"
        />
        {/* {error && error.messae} */}

        <p className="text-red-500">{error && error.message}</p>
        <button
          className={classNames(
            "bg-blue-500 font-bold relative outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-14 text-lg rounded-full text-white block",
            { "opacity-20": loading }
          )}
        >
          Continue
          {loading && (
            <div className="absolute top-0 left-0 w-full flex justify-center items-center h-full">
              <ImSpinner8 className="animate-spin" />
            </div>
          )}
        </button>
      </form>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsModalOpen(false)}
        >
          <div className="min-h-screen px-4 text-center bg-gray-500 bg-opacity-70">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Title className="inline-block w-full max-w-md p-5 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-800"
                >
                  Verify
                </Dialog.Title>
                <div className="mt-4 t">
                  <p className="text-sm text-gray-500">
                    We will be sending a registration code to the following
                    phone number:
                  </p>
                </div>
                <div className="mt-4">
                  <p className="text-gray-800 font-bold text-lg">
                    {isModalOpen &&
                      parsePhoneNumber(
                        phoneNumber,
                        country
                      ).formatInternational()}
                  </p>
                </div>
                <div className="mt-4 t">
                  <p className="text-sm text-gray-500">
                    If this is correct, tap OK, or you can EDIT this number.
                  </p>
                </div>

                <div className="mt-4 flex items-center justify-end space-x-3">
                  <button
                    type="button"
                    className="text-blue-500 focus-visible:ring-blue-500 focus-visible:ring-2 outline-none font-bold"
                    onClick={() => setIsModalOpen(false)}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-blue-500 py-1 px-6 rounded-full text-white font-bold"
                    type="button"
                    onClick={() => {
                      navigate("signup", {
                        state: {
                          phoneNumber: parsePhoneNumber(phoneNumber, country)
                            .number,
                        },
                      });
                    }}
                  >
                    Ok
                  </button>
                </div>
              </Title>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Div>
  );
}

export default InputNumber;
