import React from "react";
import { Link } from "react-router-dom";
import { MdOutlineCircle } from "react-icons/md";
import { useGetUnCompletedTasksQuery } from "generated/graphql";
import userVar from "vars/user";
import loggedInAsVar from "vars/loggedInAs";

function ToDoList() {
  const { data, refetch } = useGetUnCompletedTasksQuery({
    variables: { input: { userId: userVar()?.id, userRole: loggedInAsVar() } },
  });
  return (
    <div className="to-cont">
      <p className="mb-1">
        <span className="todo">TO-DO-LIST - </span>
        <button className="uppercase refresh" onClick={() => refetch()}>
          Refresh
        </button>
      </p>
      <Link
        to={data ? `/app/earn/form/${data?.getUnCompletedTasks[0]?.id}` : "#"}
      >
        <div className="rounded-sm py-4 px-2 ring-1 ring-gray-300 grid gap-2 grid-cols-[auto,1fr] items-center">
          <MdOutlineCircle className="text-blue-main" />
          <p className="complete text-[#1A222F]  dark:text-[#fff]">
            Complete Lifestyle questionnaire
          </p>
        </div>
      </Link>
    </div>
  );
}

export default ToDoList;
