import React from "react";
import { Outlet } from "react-router-dom";

import { MdNotifications } from "react-icons/md";
import styled from "styled-components";

import GoBack from "components/GoBack";

const Div = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  h1 {
    color: #4285f4;
  }
`;

const EarnFormPage = () => {
  return (
    <Div className="px-4 relative">
      <div className="flex items-center">
        <GoBack />
        <button className="ml-auto">
          <MdNotifications className="text-xl" />
        </button>
      </div>
      <Outlet />
    </Div>
  );
};

export default EarnFormPage;
