import React, { useState } from "react";
import { useLocation } from "react-router";
import { MdNotifications } from "react-icons/md";
import styled from "styled-components";

import UnCompletedTasksList from "components/Earn/UnCompletedTasksList";
import CompletedTasksList from "components/Earn/CompletedTasksList";

const Div = styled.div`
  position: relative;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;

  .todo div {
    /* -moz-box-shadow: 0 2px 5px -2px #ccc;
    -webkit-box-shadow: 0 2px 5px -2px #ccc;
    box-shadow: 0 2px 5px -2px #ccc;
    margin-bottom: 0.5rem; */
  }

  input {
    margin-right: 1rem;
  }
`;

const Tab = styled.div`
  background: ${(props) => (props.active ? "#B7D0FB" : "none")};
  border-radius: ${(props) => (props.active ? "24px" : "0px")};
`;

function EarnIndexPage() {
  const location = useLocation();
  const search = location.search;
  const [state, setState] = useState({
    todo: search !== "?completed",
    completed: search === "?completed",
  });

  const handleTodo = () => {
    setState({
      todo: true,
      completed: false,
    });
  };

  const handleCompleted = () => {
    setState({
      todo: false,
      completed: true,
    });
  };

  return (
    <Div className="px-4">
      <div className="mb-4">
        <button className="flex justify-end w-full">
          <MdNotifications className="text-xl" />
        </button>
      </div>

      <div className="flex font-medium text-lg w-full">
        <Tab
          className="w-1/2 text-center py-3"
          onClick={handleTodo}
          active={state.todo}
        >
          <p>To-do</p>
        </Tab>

        <Tab
          className="w-1/2 text-center py-3"
          onClick={handleCompleted}
          active={state.completed}
        >
          <p>Completed</p>
        </Tab>
      </div>

      <div className="mt-8 todo dark:text-[#FFFBE5]">
        {state.todo && <UnCompletedTasksList />}
        {state.completed && <CompletedTasksList />}
      </div>
    </Div>
  );
}

export default EarnIndexPage;
