import { Carousel } from "react-responsive-carousel";
import One from "./one";
import Two from "./two";
import Three from "./three";
import { background, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";

const Div = styled.div`
  font-family: "Airbnb Cereal App", sans-serif;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: 0 auto;

  .carousel .control-dots {
    bottom: 35%;
    text-align: start;
    padding-left: 1.25rem;
  }
`;

const OnBoarding = () => {
  const styles = {
    background: "#B3B6BA",
    height: "4px",
    width: "4px",
    borderRadius: "3px",
    display: "inline-block",
    marginRight: "5px",
  };

  return (
    <Div>
      <Carousel
        statusFormatter={(current, total) =>
          `Current slide: ${current} / Total: ${total}`
        }
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          if (isSelected) {
            return (
              <li
                style={{ ...styles, background: "#4285f4", width: "24px" }}
                aria-label={`Selected: ${label} ${index + 1}`}
              />
            );
          }
          return (
            <li
              style={{ ...styles }}
              onKeyDown={onClickHandler}
              value={index}
              role="button"
              tabIndex={0}
              title={`${label} ${index + 1}`}
              aria-label={`${label} ${index + 1}`}
              onClick = {onClickHandler}
            ></li>
          );
        }}
      >
        <div>
          <One />
        </div>

        <div>
          <Two />
        </div>

        <div>
          <Three />
        </div>
      </Carousel>
    </Div>
  );
};

export default OnBoarding;
