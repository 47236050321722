import React, { useState } from "react";
import Input from "components/input";

function VerifyCurrentPin({ handleSteps, currentPin, setcurrentPin }) {
  const [pinError, setPinError] = useState("");

  const verify = () => {
    setPinError("");
    if (currentPin.length !== 4) {
      setPinError("Pin must be 4 characters long");
    } else {
      handleSteps();
    }
  };
  return (
    <>
      <Input
        value={currentPin}
        onChange={e => {
          setcurrentPin(e.target.value);
        }}
        hasError={Boolean(pinError)}
        message={pinError}
        required
        type="password"
        className="mt-5"
        label="Current Pin"
        id="oldpin"
        minLength={4}
        maxLength={4}
        pattern="[0-9]{4}"
        autoFocus
      />
      <div className="mt-12 flex justify-end">
        <button
          className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5"
          onClick={verify}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default VerifyCurrentPin;
