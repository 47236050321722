import classNames from "classnames";
import React from "react";

function ItemsTab({ leading, trailing, children, className, ...props }) {
  return (
    <button
      className={classNames("grid grid-cols-[auto,1fr,auto] w-full items-center gap-4 px-3 py-1 rounded-sm", className)}
      {...props}>
      <div className="">{leading}</div>
      <div className="">{children}</div>
      <div className="">{trailing}</div>
    </button>
  );
}

export default ItemsTab;
