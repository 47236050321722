import React from "react";
import { Link } from "react-router-dom";
import blood from "assets/images/blood.png";
import tb from "assets/images/tb.png";
import sent from "assets/images/sent.png";
import d1 from "assets/images/dark/d1.png";
import d2 from "assets/images/dark/d2.png";
import d3 from "assets/images/dark/d3.png";
import Doodle from "assets/images/Doodles.png";
import styled from "styled-components";
import { useFindRecentActivitiesQuery } from "../../generated/graphql";
import formatDate from "utils/formatDate";
import { useReactiveVar } from "@apollo/client";
import loggedInAsVar from "vars/loggedInAs";

const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  // height: calc(100vh - 400px);
  justify-content: center;
  align-items: center;
`;

const NoTask = () => {
  return (
    <Section>
      <img src={Doodle} alt="doodle" />
      <p className="mt-2 text-center">
        You have no recent activity. <br />
        Don't worry, all it takes is one click
      </p>
      <Link
        to={"/app/earn"}
        className="bg-[#4285F4] mt-4 text-base text-white font-bold px-6 py-2.5 rounded-full cursor-pointer"
      >
        Earn
      </Link>
    </Section>
  );
};

export default function RecentActivities() {
  const loggedInUser: string = useReactiveVar(loggedInAsVar);
  const {
    data: recentData,
    loading: recentLoading,
    error: recentError,
  } = useFindRecentActivitiesQuery({
    fetchPolicy: "cache-and-network",
    variables: { role: loggedInUser },
  });

  if (recentError) {
    return <div>{recentError.message}</div>;
  }

  if (recentLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {recentData?.findRecentActivities &&
      recentData?.findRecentActivities?.length > 0 ? (
        <div>
          <p className="mb-2">
            <span className="activity">RECENT ACTIVITIES </span>
            {/* <Link to={"/app/earn"} className="uppercase see">
              See all
            </Link> */}
          </p>

          {recentData.findRecentActivities.map((activity, i) => {
            let ui = Math.floor(Math.random() * 3);
            return (
              <div key={i}>
                {activity.type === "TASK_INTERACTION" ? (
                  <>
                    {ui === 0 ? (
                      <div
                        key={i}
                        className="py-2 relative blood mb-2 px-3 bg-[#FFFBE5] dark:bg-transparent shadow-sm grid grid-cols-[1fr,auto] items-center"
                      >
                        <div className="absolute top-0 w-full h-full">
                          <img
                            src={blood}
                            alt=""
                            className="w-full h-full dark:hidden"
                          />
                          <img
                            src={d1}
                            alt=""
                            className="w-full h-full hidden dark:block"
                          />
                        </div>
                        <div className="z-10">
                          <p className="dark:text-[#FFD100] text-base mb-1">
                            {activity?.name}
                          </p>
                          <p className="text-sm text-gray-300">
                            {formatDate(activity?.createdAt)}
                          </p>
                        </div>
                        {activity.reward && (
                          <p className="z-10 font-semibold">
                            A{activity.reward.toFixed(2)}
                          </p>
                        )}
                      </div>
                    ) : ui === 1 ? (
                      <div
                        key={i}
                        className="py-2 mb-2 relative px-3 bg-[#FFEBEB] dark:bg-transparent shadow-sm grid grid-cols-[1fr,auto] items-center"
                      >
                        <div className="absolute top-0 w-full h-full">
                          <img
                            src={tb}
                            alt=""
                            className="w-full h-full dark:hidden"
                          />
                          <img
                            src={d2}
                            alt=""
                            className="w-full h-full hidden dark:block"
                          />
                        </div>
                        <div className="z-10">
                          <p className="dark:text-[#F9423A] text-base mb-1">
                            {activity?.name}
                          </p>
                          <p className="text-sm text-gray-300">
                            {formatDate(activity?.createdAt)}
                          </p>
                        </div>
                        <p className="z-10 font-semibold">
                          {/* A{parseFloat(task?.reward).toFixed(2)} */}A 5.00
                        </p>
                      </div>
                    ) : (
                      <div
                        key={i}
                        className="py-2 mb-2 relative px-3 bg-[#E9FAF2] dark:bg-transparent shadow-sm grid grid-cols-[1fr,auto] items-center"
                      >
                        <div className="absolute top-0 w-full h-full">
                          <img
                            src={sent}
                            alt=""
                            className="w-full h-full dark:hidden"
                          />
                          <img
                            src={d3}
                            alt=""
                            className="w-full h-full hidden dark:block"
                          />
                        </div>
                        <div className="z-10">
                          <p className="dark:text-[#F9423A] text-base mb-1">
                            {activity?.name}
                          </p>
                          <p className="text-sm text-gray-300">
                            {formatDate(activity?.createdAt)}
                          </p>
                        </div>
                        <p className="z-10 font-semibold">
                          A 5.00
                          {/* A{parseFloat(task?.reward).toFixed(2)} */}
                        </p>
                      </div>
                    )}
                  </>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : (
        <div>
          <NoTask />
        </div>
      )}
    </>
  );
}

// {
//   data.getCompletedTasks.map((task, id) => {
//     let ui = Math.floor(Math.random() * 3);
//     return (
//       <div key={task.id}>
//         {ui === 0 ? (
//           <div
//             key={task.id}
//             className="py-2 relative blood mb-2 px-3 bg-[#FFFBE5] dark:bg-transparent shadow-sm grid grid-cols-[1fr,auto] items-center"
//           >
//             <div className="absolute top-0 w-full h-full">
//               <img
//                 src={blood}
//                 alt="blood"
//                 className="w-full h-full dark:hidden"
//               />
//               <img
//                 src={d1}
//                 alt="blood"
//                 className="w-full h-full hidden dark:block"
//               />
//             </div>
//             <div className="z-10">
//               <p className="dark:text-[#FFD100] text-base mb-1">{task?.name}</p>
//               <p className="text-sm text-gray-300">
//                 {formatDate(task?.createdAt)}
//               </p>
//             </div>
//             <p className="z-10 font-semibold">
//               A{parseFloat(task?.reward).toFixed(2)}
//             </p>
//           </div>
//         ) : ui === 1 ? (
//           <div className="py-2 mb-2 relative px-3 bg-[#FFEBEB] dark:bg-transparent shadow-sm grid grid-cols-[1fr,auto] items-center">
//             <div className="absolute top-0 w-full h-full">
//               <img src={tb} alt="tb" className="w-full h-full dark:hidden" />
//               <img
//                 src={d2}
//                 alt="tb"
//                 className="w-full h-full hidden dark:block"
//               />
//             </div>
//             <div className="z-10">
//               <p className="dark:text-[#F9423A] text-base mb-1">{task?.name}</p>
//               <p className="text-sm text-gray-300">
//                 {formatDate(task?.createdAt)}
//               </p>
//             </div>
//             <p className="z-10 font-semibold">
//               A{parseFloat(task?.reward).toFixed(2)}
//             </p>
//           </div>
//         ) : (
//           <div className="py-2 mb-2 relative px-3 bg-[#E9FAF2] dark:bg-transparent shadow-sm grid grid-cols-[1fr,auto] items-center">
//             <div className="absolute top-0 w-full h-full">
//               <img
//                 src={sent}
//                 alt="sent"
//                 className="w-full h-full dark:hidden"
//               />
//               <img
//                 src={d3}
//                 alt="sent"
//                 className="w-full h-full hidden dark:block"
//               />
//             </div>
//             <div className="z-10">
//               <p className="dark:text-[#F9423A] text-base mb-1">{task?.name}</p>
//               <p className="text-sm text-gray-300">
//                 {formatDate(task?.createdAt)}
//               </p>
//             </div>
//             <p className="z-10 font-semibold">
//               A{parseFloat(task?.reward).toFixed(2)}
//             </p>
//           </div>
//         )}
//       </div>
//     );
//   });
// }
