import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Input from "components/input";
import styled from "styled-components";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }
`;

function SignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  useEffect(() => {
    console.log(location.state);
    if (!location.state) navigate("/auth");
  }, [location.state, navigate]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  function validate() {
    if (!firstName.trim()) {
      firstNameRef.current?.focus();
      return false;
    }
    if (!lastName.trim()) {
      lastNameRef.current?.focus();
      return false;
    }
    return true;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validate()) {
      navigate("../email", {
        state: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          ...location.state,
        },
      });
    }
  }
  return (
    <Div>
      <h1 className="text-gray-300 text mb-2">Let’s know you,</h1>
      <p className="text-gray-800 dark:text-gray-200 sub-text">
        Enter your first and last name so we know what to call you.
      </p>
      <form onSubmit={handleSubmit}>
        <Input
          ref={firstNameRef}
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          required
          type="text"
          className="mt-5"
          label="First name"
          id="firstname"
        />
        <Input
          ref={lastNameRef}
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          required
          type="text"
          className="mt-5"
          label="Last name"
          id="lastname"
        />
        <button className="bg-blue-500 font-bold outline-none ring-1 ring-blue-500 focus-visible:bg-white focus-visible:text-blue-500 py-2 px-6 mt-14 text-lg rounded-full text-white block">
          Next
        </button>
      </form>
    </Div>
  );
}

export default SignUp;
