import React from "react";
import { NavLink } from "react-router-dom";
import Newact from "assets/images/newact.png";
import EditAct from "assets/images/editact.png";
import styled from "styled-components";

const Div = styled.div`
  .text {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: normal;
  }

  .cont {
    height: 186px;
    width: 150px;
  }
`;

function RecordActivity() {
  return (
    <Div>
      <h1 className="text-gray-300 font-bold text mb-14">
        Record New Activity
      </h1>
      <form>
        <div className="flex justify-between items-center text-center">
          <NavLink to="/app/chw/record/new">
            <div className="bg-[#B7D0FB] rounded flex flex-col justify-center cont">
              <div className="flex justify-center mb-3">
                <img src={Newact} alt="avatar" />
              </div>
              <div>
                <div className="text-white text-sm">New activity</div>
              </div>
            </div>
          </NavLink>

          <NavLink to="/app/chw/record/edit">
            <div className="bg-[#B7D0FB] rounded flex flex-col justify-center cont">
              <div className="flex justify-center mb-3">
                <img src={EditAct} alt="avatar" />
              </div>
              <div className="text-white text-sm">Request edit</div>
            </div>
          </NavLink>
        </div>
      </form>
    </Div>
  );
}

export default RecordActivity;
