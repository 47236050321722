import React, { useState, useRef, useCallback } from "react";
import { countries } from "countries-list";
import Input from "components/input";
import { localeIncludes } from "locale-includes";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import userVal from "vars/user";
import SelectSearch from "react-select-search";
import classNames from "classnames";

const codes = Object.keys(countries);
const countryObjects = Object.values(countries);
const newObject = countryObjects.map((country, i) => ({
  ...country,
  value: codes[i],
}));

function VerifyNewNumber({
  handleSteps,
  country,
  newPhoneNumber,
  confirmPhoneNumber,
  setCountry,
  setNewPhoneNumber,
  setConfirmPhoneNumber,
}) {
  const inputRef = useRef(null);
  const inputRef2 = useRef(null);
  const buttonRef = useRef(null);
  const [phoneError, setPhoneError] = useState("");
  const [confirmPhoneError, setConfirmPhoneError] = useState("");

  const verify = () => {
    setPhoneError("");
    setConfirmPhoneError("");
    if (!country) {
      document.querySelector(".input-search")?.focus();
    } else if (isValidPhoneNumber(newPhoneNumber, country)) {
      if (confirmPhoneNumber !== newPhoneNumber) {
        setConfirmPhoneError("Phone numbers don't match");
        document.querySelector("#newPhoneNumber")?.focus();
      } else {
        setNewPhoneNumber(parsePhoneNumber(newPhoneNumber, country).number);
        setConfirmPhoneNumber(
          parsePhoneNumber(confirmPhoneNumber, country).number
        );
        handleSteps();
      }
    } else {
      console.log(country.code);
      setPhoneError("Invalid phone number");
      document.querySelector("#confirmPhoneNumber")?.focus();
    }
  };

  const search = useCallback((options) => {
    return (query) => {
      return options.filter((option) =>
        localeIncludes(option.name, query, {
          usage: "search",
          sensitivity: "base",
        })
      );
    };
  }, []);

  return (
    <>
      <div className="my-6">
        <SelectSearch
          ref={buttonRef}
          className={(options) => {
            if (options === "container") {
              return "max-h-60 overflow-y-hidden grid grid-rows-[auto,1fr]";
            }
            if (options === "select") {
              return "overflow-y-auto";
            }
            if (options === "option") {
              return "text-left block w-full";
            }
            if (options === "row") {
              return "block px-4 py-2";
            }
            if (options === "input") {
              return classNames(
                "p-4 placeholder-gray-300 w-full outline-none bg-blue bg-opacity-10 text-gray-800 dark:text-white input-search",
                {
                  "bg-opacity-20": country,
                }
              );
            }
            if (options === "has-focus") {
              return "ring ring-blue-500 rounded-sm";
            }
            if (options === "is-highlighted" || options === "is-selected") {
              return "bg-blue block";
            }
            return "";
          }}
          options={newObject}
          value={country}
          onChange={(value) => {
            setCountry(value);
          }}
          search
          filterOptions={search}
          placeholder="Select your country"
        />
      </div>

      <Input
        hasError={Boolean(phoneError)}
        message={phoneError}
        ref={inputRef}
        required
        value={newPhoneNumber}
        onChange={(e) => {
          setNewPhoneNumber(e.target.value);
        }}
        type="tel"
        id="newPhoneNumber"
        label="Phone Number"
      />

      <Input
        hasError={Boolean(confirmPhoneError)}
        message={confirmPhoneError}
        ref={inputRef2}
        required
        value={confirmPhoneNumber}
        onChange={(e) => {
          setConfirmPhoneNumber(e.target.value);
        }}
        type="tel"
        className="mt-5"
        label="Confirm new phone number"
        id="confirmPhoneNumber"
      />
      <div className="mt-12 flex justify-end">
        <button
          className="bg-[#4285F4] text-base text-white font-bold px-6 py-2.5"
          onClick={verify}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default VerifyNewNumber;
