import React from "react";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export default function GoBack() {
  const navigate = useNavigate();
  return (
    <h1
      className="flex items-center font-medium cursor-pointer text-[#4285f4]"
      onClick={() => navigate(-1)}
    >
      <MdOutlineArrowBack size={24} /> <p>Go Back</p>
    </h1>
  );
}
