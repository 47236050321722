import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import GoBack from "components/GoBack";
import Empty from "components/Empty";

import {
  useFindInteractionByChwByIdQuery,
  useFindAllActivityByIdLazyQuery,
  useFindAllPrescriptionsByIdLazyQuery,
} from "generated/graphql";
import userVar from "vars/user";

const PatientDetails = () => {
  const location = useLocation();
  const id = location.state?.id;
  const { data, loading, error } = useFindInteractionByChwByIdQuery({
    variables: {
      interactionId: id,
      chwId: userVar().id,
    },
  });
  const [
    findActivities,
    { data: activities, loading: activitiesLoading, error: activitiesError },
  ] = useFindAllActivityByIdLazyQuery();
  const [
    findPrescriptions,
    {
      data: prescriptions,
      loading: prescriptionsLoading,
      error: prescriptionsError,
    },
  ] = useFindAllPrescriptionsByIdLazyQuery();

  useEffect(() => {
    if (data?.findInteractionByCHWById) {
      findActivities({
        variables: {
          input: data.findInteractionByCHWById.activityId,
        },
      });
    }
  }, [data?.findInteractionByCHWById, findActivities]);

  useEffect(() => {
    if (data?.findInteractionByCHWById) {
      findPrescriptions({
        variables: {
          input: data.findInteractionByCHWById.prescriptionId,
        },
      });
    }
  }, [data?.findInteractionByCHWById, findPrescriptions]);

  return (
    <div className="p-4">
      <GoBack />

      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <Empty text={error.message} />
      ) : !data?.findInteractionByCHWById ? (
        <Empty text="No Interaction Found" />
      ) : (
        <>
          <section className="my-3">
            <p className="text-sm font-medium mt-4">Practitioner details</p>
            <div className="font-normal ml-3">
              <p>
                {data?.findInteractionByCHWById?.practitioner?.firstName ??
                  "Unknown"}{" "}
                {data?.findInteractionByCHWById?.practitioner?.lastName ??
                  "Unknown"}
              </p>
              <p>No-123C</p>
              <div className="flex justify-between">
                <p>954-Ghyui</p>
                <p className="font-medium">7th MAY, 2021</p>
              </div>
            </div>
          </section>

          <hr />

          <section className="my-3">
            <p className="text-sm font-medium mt-4">Patient details</p>
            <div className="font-normal ml-3">
              <p>
                {data?.findInteractionByCHWById?.patient?.firstName ??
                  "Unknown"}{" "}
                {data?.findInteractionByCHWById?.patient?.lastName ?? "Unknown"}
              </p>
              <p>747-klopl</p>
            </div>
          </section>

          <hr />

          <section className="my-3">
            <p className="text-sm font-medium mt-4">Activity</p>
            {activitiesLoading ? (
              <div>Loading...</div>
            ) : activitiesError ? (
              <div>{activitiesError}</div>
            ) : (
              <>
                {Array.isArray(activities?.findAllActivityById) &&
                activities?.findAllActivityById.length > 0
                  ? activities?.findAllActivityById.map((act) => (
                      <div className="font-normal ml-3" key={act.id}>
                        {act.title}
                      </div>
                    ))
                  : "No Activity Found"}
              </>
            )}
          </section>

          <hr />

          <section className="my-3">
            <p className="text-sm font-medium mt-4">Prescriptions</p>
            {prescriptionsLoading ? (
              <div>Loading...</div>
            ) : prescriptionsError ? (
              <div>{prescriptionsError}</div>
            ) : (
              <>
                {Array.isArray(prescriptions?.findAllPrescriptionsById) &&
                prescriptions?.findAllPrescriptionsById.length > 0
                  ? prescriptions?.findAllPrescriptionsById.map((pres) => (
                      <div className="font-normal ml-3" key={pres.id}>
                        {pres.title}
                      </div>
                    ))
                  : "No Prescription Found"}
              </>
            )}
          </section>

          <hr />

          <section className="my-3">
            <p className="text-sm font-medium mt-4">Prescription number</p>
            <div className="font-normal ml-3">
              {data?.findInteractionByCHWById?.prescriptionNumber
                ? data?.findInteractionByCHWById?.prescriptionNumber
                : "No Prescription Number"}
            </div>
          </section>

          <hr />

          <section className="my-3">
            <p className="text-sm font-medium mt-4">Additional notes</p>
            <div className="font-normal ml-3">
              {data?.findInteractionByCHWById?.additionalNotes
                ? data?.findInteractionByCHWById?.additionalNotes
                : "No Additional Note"}
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default PatientDetails;
