import { makeVar } from "@apollo/client";
import { Clinic } from "generated/graphql";

const item = localStorage.getItem("cus-rib-loggedInClinic");

const data: Clinic | null = item && JSON.parse(item);

const loggedInClinicVar = makeVar(data);

export default loggedInClinicVar;
